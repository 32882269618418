import { ROOT_URL, LOGGING_ENDPOINT } from '../routes';
import { getTenantInfo } from './cookieReader';

export const logToServer = async (log, code) => {
  const { healthSystemName } = getTenantInfo();

  await fetch(`${ROOT_URL}${LOGGING_ENDPOINT}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      TenantName: healthSystemName,
      LogData: log,
      Code: code ?? null,
    }),
  });
};
