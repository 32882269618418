import classNames from 'classnames/bind';
import React, { useState } from 'react';
import MultiSelect from 'terra-form-select/lib/MultiSelect';
import SearchSelect from 'terra-form-select/lib/SearchSelect';
import { debounce } from 'lodash';
import styles from './CodeLookupField.module.scss';
import { POCA_AUTH_OBJECT_IDENTIFIER } from '../useCerner';

const cx = classNames.bind(styles);
const ERROR_SEARCHING = 'error searching. bad luck';
const CodeLookupField = ({
  input,
  isRequired,
  meta,
  dropdownType,
  label,
  labelExtra,
  placeholder = 'Start typing and select an entry',
  multiple,
  ...rest
}) => {
  const [codes, setCodes] = useState(null);
  const [previousSearch, setPreviousSearch] = useState(null);
  const [currentSearch, setCurrentSearch] = useState(null);
  const [searching, setSearching] = useState(null);
  const onSearch = (value) => {
    if (!value) {
      setPreviousSearch(null);
    }
    const { access_token: accessToken } =
      JSON.parse(localStorage.getItem(POCA_AUTH_OBJECT_IDENTIFIER)) || {};
    if (value.length < 3 || (previousSearch && value.includes(previousSearch)))
      return;
    setPreviousSearch(value);
    setSearching(value);
    fetch(
      `${process.env.REACT_APP_API_URL}/api/dropdown/${dropdownType}?query=${value}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    )
      .then((response) => response.json())
      .then((json) => {
        setSearching(null);
        setCodes([
          ...new Set( // remove duplicates
            json.map(({ code, description }) => `${code} - ${description}`)
          ),
        ]);
      })
      .catch(() => {
        setSearching(null);
        setCurrentSearch(ERROR_SEARCHING);
      });
  };
  const onDebouncedSearch = debounce(onSearch, 500);

  const emptyText = () => {
    if (!currentSearch || currentSearch.length < 3)
      return 'Enter at least 3 characters.';
    if (searching) return `Searching for ${currentSearch}...`;
    if (currentSearch === ERROR_SEARCHING)
      return `We ran into an issue while looking that up.`;
    return `No results found for ${currentSearch}.`;
  };
  const CustomSelect = multiple ? MultiSelect : SearchSelect;
  let formValue = input.value;
  if (!formValue) {
    formValue = multiple ? [] : '';
  }
  return (
    <>
      {isRequired && <span className={cx('required')}>*</span>}
      <strong>{label}</strong>
      <span> {labelExtra}</span>
      <CustomSelect
        className={cx('placeholder')}
        placeholder={placeholder}
        onSearch={(value) => {
          setCurrentSearch(value);
          onDebouncedSearch(value);
        }}
        value={formValue}
        onChange={input.onChange}
        isInvalid={meta.submitFailed && !meta.valid}
        noResultContent={emptyText()}
        maxHeight={250}
        {...rest}
      >
        {codes?.map((codeDescription) => (
          <CustomSelect.Option
            key={codeDescription}
            value={codeDescription}
            display={codeDescription}
          />
        ))}
      </CustomSelect>
      {meta.submitFailed && meta.error && (
        <div className={cx('danger')}>{meta.error}</div>
      )}
    </>
  );
};

export default CodeLookupField;
