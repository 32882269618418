import React from 'react';
import Spacer from 'terra-spacer';
import Text from 'terra-text';

const Disclaimer = ({ title, disclaimers }) => {
  if (!disclaimers?.length) return null;
  return (
    <Spacer margin="medium none">
      <Text isItalic>
        <Text weight={700}>{title}: </Text>
        {disclaimers.map((disclaimer) => disclaimer)}
      </Text>
    </Spacer>
  );
};

export default Disclaimer;
