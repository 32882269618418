import { useContext, useState } from 'react';
import useSWR from 'swr';
import { isEqual } from 'lodash';
import fetcher from '../../Fetcher';
import { GlobalStore } from '../../ContextWrapper';
import useEligibility from '../Eligibility/useEligibility';
import { REFERRAL_SEARCH_ENDPOINT } from '../../routes';

const hardCodedData = {
  radius: 20,
  diagnosisCode: '',
  clientID: 'ClientID',
  clientGroup: 'EMR',
  clientBusiness: 'RxRevu',
};
const useReferral = () => {
  const [referralData, setReferralData] = useState(null);
  const { patient, user } = useContext(GlobalStore);
  const { data: eligibility } = useEligibility();
  const { transactionId, coverageDetails } = eligibility || {};
  const { lineOfBusiness } = coverageDetails || {};

  const { data, error, mutate } = useSWR(
    referralData && eligibility?.state
      ? () => [
          REFERRAL_SEARCH_ENDPOINT,
          JSON.stringify(patient),
          JSON.stringify(user),
          JSON.stringify(referralData),
        ]
      : null,
    (url) =>
      fetcher(url, {
        ...hardCodedData,
        patient,
        user,
        ...referralData,
        planIssueState: eligibility.state,
        transactionId,
        lineOfBusiness,
      })
  );

  const makeRequest = (requestData) => {
    if (isEqual(referralData, requestData)) {
      mutate();
    } else {
      setReferralData(requestData);
    }
  };

  return {
    makeRequest,
    data,
    isLoading: !error && !data && referralData != null,
    isError: error,
    mutate,
  };
};

export default useReferral;
