import React, { useMemo, useState } from 'react';
import Card from 'terra-card';
import List, { Item } from 'terra-list';
import Grid from 'terra-grid';
import ButtonGroup from 'terra-button-group';
import Button from 'terra-button';
import Spacer from 'terra-spacer';

import ScrollableContainer from '../ScrollableContainer';
import usePatientOverview from './usePatientOverview';
import LoadingContainer from '../LoadingContainer';

const { Column, Row } = Grid;

const HealthRecord = () => {
  const { data, isLoading, isError } = usePatientOverview();
  const [selectedFilters, setSelectedFilters] = useState([
    'medication',
    'condition',
    'immunization',
    'procedure history',
    'visit',
    'care team',
    'observation',
  ]);

  const handleSelection = (_, newFilter) => {
    setSelectedFilters(
      ButtonGroup.Utils.handleMultiSelectedKeys(selectedFilters, newFilter)
    );
  };

  const filteredList = useMemo(
    () => data?.filter((entry) => selectedFilters.includes(entry.recordType)),
    [data, selectedFilters]
  );

  return (
    <>
      <Card>
        <Card.Body>
          <div>Filters:</div>
          <ButtonGroup
            selectedKeys={selectedFilters}
            onChange={handleSelection}
          >
            <ButtonGroup.Button text="Medication" key="medication" />
            <ButtonGroup.Button text="Condition" key="condition" />
            <ButtonGroup.Button text="Immunization" key="immunization" />
            <ButtonGroup.Button
              text="Procedure History"
              key="procedure history"
            />
            <ButtonGroup.Button text="Visit" key="visit" />
            <ButtonGroup.Button text="Care Team" key="care team" />
            <ButtonGroup.Button text="Observation" key="observation" />
          </ButtonGroup>
          <Spacer isInlineBlock marginLeft="small">
            <Button
              text="Clear Filters"
              onClick={() => setSelectedFilters([])}
            />
          </Spacer>
        </Card.Body>
      </Card>
      <ScrollableContainer>
        <List paddingStyle="standard" dividerStyle="standard">
          <LoadingContainer
            isData={!!data}
            isLoading={isLoading}
            isError={isError}
            errorMessage="Payor Health Record Not Available"
          />
          {filteredList?.map(
            ({
              id,
              recordType,
              description,
              status,
              state,
              lastUpdateDate,
            }) => (
              <Item key={id}>
                <Row>
                  <Column tiny={1}>
                    <strong>Type:</strong>
                  </Column>
                  <Column tiny={2}>
                    <span>{recordType}</span>
                  </Column>
                  <Column tiny={2}>
                    <strong>Description:</strong>
                  </Column>
                  <Column tiny={5}>
                    <span>{description}</span>
                  </Column>
                  <Column tiny={1}>
                    <strong>State:</strong>
                  </Column>
                  <Column tiny={1}>
                    <span>{state}</span>
                  </Column>
                </Row>
                <Row>
                  <Column tiny={1}>
                    <strong>Date:</strong>
                  </Column>
                  <Column tiny={2}>
                    <span>{lastUpdateDate?.split('T')?.[0]}</span>
                  </Column>
                  <Column tiny={2}>
                    <strong>Status:</strong>
                  </Column>
                  <Column tiny={2}>
                    <span>{status}</span>
                  </Column>
                </Row>
              </Item>
            )
          )}
        </List>
      </ScrollableContainer>
    </>
  );
};

export default HealthRecord;
