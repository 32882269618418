import { useContext } from 'react';
import useSWR from 'swr';
import fetcher from '../../Fetcher';
import { GlobalStore } from '../../ContextWrapper';
import { PATIENT_OVERVIEW_ENDPOINT } from '../../routes';
import useEligibility from '../Eligibility/useEligibility';

const hardCodedData = {
  searchId: '',
  groupNumber: '',
  chId: '',
  requestId: 'string',
};
const usePatientOverview = () => {
  const { patient, user } = useContext(GlobalStore);
  const { data: eligibility } = useEligibility();
  const { transactionId } = eligibility || {};
  const { subscriberId: searchId, groupNumber } =
    eligibility?.coverageDetails || {};

  const { data, error } = useSWR(
    () => [
      PATIENT_OVERVIEW_ENDPOINT,
      JSON.stringify(patient),
      JSON.stringify(user),
    ],
    (url) =>
      fetcher(url, {
        patient,
        user,
        ...hardCodedData,
        searchId,
        groupNumber,
        transactionId,
      })
  );

  return {
    data,
    isLoading: !error && !data,
    isError: error,
  };
};

export default usePatientOverview;
