import { useContext } from 'react';
import useSWR from 'swr';
import fetcher from '../../Fetcher';
import { GlobalStore } from '../../ContextWrapper';
import useEligibility from '../Eligibility/useEligibility';
import { GAPS_IN_CARE_CHECK_ENDPOINT } from '../../routes';

const useGapsInCare = () => {
  const { patient, user } = useContext(GlobalStore);
  const { data: eligibility } = useEligibility();
  const { transactionId } = eligibility || {};
  const { insuranceTypeCode } = eligibility?.coverageDetails || {};

  const { data, error } = useSWR(
    [
      GAPS_IN_CARE_CHECK_ENDPOINT,
      JSON.stringify(patient),
      JSON.stringify(user),
    ],
    (url) => fetcher(url, { patient, user, insuranceTypeCode, transactionId })
  );

  return {
    data,
    isLoading: !error && !data,
    isError: error,
  };
};

export default useGapsInCare;
