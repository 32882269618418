/* eslint-disable no-console */
import fetcher from '../Fetcher';

import {
  LAB_SELECTED_ENDPOINT,
  CE_PROVIDER_SELECTED_ENDPOINT,
  CE_LAB_SELECTED_ENDPOINT,
} from '../routes';

const hardcoded = {
  clientID: 'ClientID',
  clientGroup: 'EMR',
  clientBusiness: 'RxRevu',
};

export const sendLabSelected = (patient, user, outcomeData) => {
  const url = LAB_SELECTED_ENDPOINT;
  fetcher(url, { patient, user, ...outcomeData, ...hardcoded }).catch((err) =>
    console.error(err)
  );
};

export const sendProviderSelected = (patient, user, outcomeData) => {
  const url = CE_PROVIDER_SELECTED_ENDPOINT;
  fetcher(url, { patient, user, ...outcomeData, ...hardcoded }).catch((err) =>
    console.error(err)
  );
};

export const sendFacilitySelected = (patient, user, outcomeData) => {
  const url = CE_LAB_SELECTED_ENDPOINT;
  fetcher(url, { patient, user, ...outcomeData, ...hardcoded }).catch((err) =>
    console.error(err)
  );
};
