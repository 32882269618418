export const placesOfService = [
  { code: '1', location: 'Pharmacy' },
  { code: '2', location: 'Telehealth' },
  { code: '3', location: 'School' },
  { code: '4', location: 'Homeless Shelter' },
  { code: '5', location: 'Indian Health Service (Outpatient)' },
  { code: '6', location: 'Indian Health Service' },
  { code: '7', location: 'Tribal 638 (Free standing facility)' },
  { code: '8', location: 'Tribal 638 (Hospital based facility)' },
  { code: '9', location: 'Prison/ Correctional Facility' },
  { code: '11', location: 'Office' },
  { code: '12', location: 'Home' },
  { code: '13', location: 'Assisted Living Facility' },
  { code: '14', location: 'Group Home' },
  { code: '15', location: 'Mobile Unit' },
  { code: '16', location: 'Temporary Lodging' },
  { code: '17', location: 'Walk-in Retail Health Clinic' },
  { code: '18', location: 'Place of Employment-Worksite' },
  { code: '19', location: 'Off Campus-Outpatient Hospital' },
  { code: '20', location: 'Urgent Care Facility' },
  { code: '21', location: 'Inpatient Hospital' },
  { code: '22', location: 'On Campus-Outpatient Hospital' },
  { code: '23', location: 'Emergency Room – Hospital' },
  { code: '24', location: 'Ambulatory Surgical Center' },
  { code: '25', location: 'Birthing Center' },
  { code: '26', location: 'Military Treatment Facility' },
  { code: '31', location: 'Skilled Nursing Facility' },
  { code: '32', location: 'Nursing Facility' },
  { code: '33', location: 'Custodial Care Facility' },
  { code: '34', location: 'Hospice' },
  { code: '41', location: 'Ambulance - Land' },
  { code: '42', location: 'Ambulance – Air or Water' },
  { code: '49', location: 'Independent Clinic' },
  { code: '50', location: 'Federally Qualified Health Center' },
  { code: '51', location: 'Inpatient Psychiatric Facility' },
  { code: '52', location: 'Psychiatric Facility-Partial Hospitalization' },
  { code: '53', location: 'Community Mental Health Center' },
  {
    code: '54',
    location:
      'Intermediate Care Facility/ Individuals with Intellectual Disabilities',
  },
  { code: '55', location: 'Residential Substance Abuse Treatment Facility' },
  { code: '56', location: 'Psychiatric Residential Treatment Center' },
  {
    code: '57',
    location: 'Non-residential Substance Abuse Treatment Facility',
  },
  { code: '60', location: 'Mass Immunization Center' },
  { code: '61', location: 'Comprehensive Inpatient Rehabilitation Facility' },
  { code: '62', location: 'Comprehensive Outpatient Rehabilitation Facility' },
  { code: '65', location: 'End-Stage Renal Disease Treatment Facility' },
  { code: '71', location: 'Public Health Clinic' },
  { code: '72', location: 'Rural Health Clinic' },
  { code: '81', location: 'Independent Laboratory' },
];
