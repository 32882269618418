export const taxonomyCodes = [
  {
    code: '171100000X',
    classification: 'ACUPUNCTURIST',
    codeClassification: '171100000X-ACUPUNCTURIST',
    placeOfService: null,
  },
  {
    code: '2083A0300X',
    classification: 'ADDICTION MEDICINE',
    codeClassification: '2083A0300X-ADDICTION MEDICINE',
    placeOfService: null,
  },
  {
    code: '311ZA0620X',
    classification: 'ADULT CARE HOME',
    codeClassification: '311ZA0620X-ADULT CARE HOME',
    placeOfService: null,
  },
  {
    code: '261QM0850X',
    classification: 'ADULT MENTAL HEALTH CLINIC',
    codeClassification: '261QM0850X-ADULT MENTAL HEALTH CLINIC',
    placeOfService: null,
  },
  {
    code: '207RA0001X',
    classification: 'ADV HEART FAILURE ANDTRANS CARD',
    codeClassification: '207RA0001X-ADV HEART FAILURE ANDTRANS CARD',
    placeOfService: null,
  },
  {
    code: '251300000X',
    classification: 'AGENCIESLOCAL EDUCATION',
    codeClassification: '251300000X-AGENCIESLOCAL EDUCATION',
    placeOfService: null,
  },
  {
    code: '251B00000X',
    classification: 'AGENCIES CASE MANAGEMENT',
    codeClassification: '251B00000X-AGENCIES CASE MANAGEMENT',
    placeOfService: null,
  },
  {
    code: '251C00000X',
    classification: 'AGENCIES DAY TRAINING DEV',
    codeClassification: '251C00000X-AGENCIES DAY TRAINING DEV',
    placeOfService: null,
  },
  {
    code: '251E00000X',
    classification: 'AGENCIES HOME HEALTH',
    codeClassification: '251E00000X-AGENCIES HOME HEALTH',
    placeOfService: null,
  },
  {
    code: '251F00000X',
    classification: 'AGENCIES HOME INFUSION',
    codeClassification: '251F00000X-AGENCIES HOME INFUSION',
    placeOfService: null,
  },
  {
    code: '251G00000X',
    classification: 'AGENCIES HOSPICE CARE COM',
    codeClassification: '251G00000X-AGENCIES HOSPICE CARE COM',
    placeOfService: null,
  },
  {
    code: '253Z00000X',
    classification: 'AGENCIES IN HOME SUPPORTI',
    codeClassification: '253Z00000X-AGENCIES IN HOME SUPPORTI',
    placeOfService: null,
  },
  {
    code: '251J00000X',
    classification: 'AGENCIES NURSING CARE',
    codeClassification: '251J00000X-AGENCIES NURSING CARE',
    placeOfService: null,
  },
  {
    code: '251X00000X',
    classification: 'AGENCIES SUPPORTS BROKERA',
    codeClassification: '251X00000X-AGENCIES SUPPORTS BROKERA',
    placeOfService: null,
  },
  {
    code: '251T00000X',
    classification: 'AGENCYPACE PROV ORG',
    codeClassification: '251T00000X-AGENCYPACE PROV ORG',
    placeOfService: null,
  },
  {
    code: '207KA0200X',
    classification: 'ALLERGY',
    codeClassification: '207KA0200X-ALLERGY',
    placeOfService: null,
  },
  {
    code: '207K00000X',
    classification: 'ALLERGY AND IMMUNOLOGY',
    codeClassification: '207K00000X-ALLERGY AND IMMUNOLOGY',
    placeOfService: null,
  },
  {
    code: '311500000X',
    classification: 'ALZHEIMER CENTER DEMENTIA',
    codeClassification: '311500000X-ALZHEIMER CENTER DEMENTIA',
    placeOfService: null,
  },
  {
    code: '341600000X',
    classification: 'AMBULANCE',
    codeClassification: '341600000X-AMBULANCE',
    placeOfService: null,
  },
  {
    code: '261QA0005X',
    classification: 'AMBULATORY FAMILY PLAN',
    codeClassification: '261QA0005X-AMBULATORY FAMILY PLAN',
    placeOfService: null,
  },
  {
    code: '261QA0005X',
    classification: 'AMBULATORY FAMILY PLANNING FACILITY',
    codeClassification: '261QA0005X-AMBULATORY FAMILY PLANNING FACILITY',
    placeOfService: null,
  },
  {
    code: '261Q00000X',
    classification: 'AMBULATORY HEALTH CARE CL',
    codeClassification: '261Q00000X-AMBULATORY HEALTH CARE CL',
    placeOfService: '22, 24',
  },
  {
    code: '261QB0400X',
    classification: 'AMBULATORY HEALTH CARE CL',
    codeClassification: '261QB0400X-AMBULATORY HEALTH CARE CL',
    placeOfService: null,
  },
  {
    code: '261QG0250X',
    classification: 'AMBULATORY HEALTH CARE CL',
    codeClassification: '261QG0250X-AMBULATORY HEALTH CARE CL',
    placeOfService: null,
  },
  {
    code: '261QL0400X',
    classification: 'AMBULATORY HEALTH CARE CL',
    codeClassification: '261QL0400X-AMBULATORY HEALTH CARE CL',
    placeOfService: '22,24',
    isProcedureCodeRequired: true,
  },
  {
    code: '261QM1200X',
    classification: 'AMBULATORY HEALTH CARE CL',
    codeClassification: '261QM1200X-AMBULATORY HEALTH CARE CL',
    placeOfService: '22,24,49',
    isProcedureCodeRequired: true,
  },
  {
    code: '261QR0400X',
    classification: 'AMBULATORY HEALTH CARE CL',
    codeClassification: '261QR0400X-AMBULATORY HEALTH CARE CL',
    placeOfService: null,
  },
  {
    code: '261QS1200X',
    classification: 'AMBULATORY HEALTH CARE CL',
    codeClassification: '261QS1200X-AMBULATORY HEALTH CARE CL',
    placeOfService: null,
  },
  {
    code: '261QA1903X',
    classification: 'AMBULATORY HEALTH CARE FA',
    codeClassification: '261QA1903X-AMBULATORY HEALTH CARE FA',
    placeOfService: '22, 24',
    isProcedureCodeRequired: true,
  },
  {
    code: '207LA0401X',
    classification: 'ANESTHESIA ADDICTION ME',
    codeClassification: '207LA0401X-ANESTHESIA ADDICTION ME',
    placeOfService: null,
  },
  {
    code: '207LC0200X',
    classification: 'ANESTHESIA CRITICAL CARE',
    codeClassification: '207LC0200X-ANESTHESIA CRITICAL CARE',
    placeOfService: null,
  },
  {
    code: '207LH0002X',
    classification: 'ANESTHESIA HOSPPAL MED',
    codeClassification: '207LH0002X-ANESTHESIA HOSPPAL MED',
    placeOfService: null,
  },
  {
    code: '367H00000X',
    classification: 'ANESTHESIOLOGIST ASSISTANT',
    codeClassification: '367H00000X-ANESTHESIOLOGIST ASSISTANT',
    placeOfService: null,
  },
  {
    code: '207L00000X',
    classification: 'ANESTHESIOLOGY',
    codeClassification: '207L00000X-ANESTHESIOLOGY',
    placeOfService: null,
  },
  {
    code: '106E00000X',
    classification: 'ASSISTANT BEHAVIOR ANALYST',
    codeClassification: '106E00000X-ASSISTANT BEHAVIOR ANALYST',
    placeOfService: null,
  },
  {
    code: '3104A0630X',
    classification: 'ASSISTED LIVING BEHAVIORA',
    codeClassification: '3104A0630X-ASSISTED LIVING BEHAVIORA',
    placeOfService: null,
  },
  {
    code: '310400000X',
    classification: 'ASSISTED LIVING FACILITY',
    codeClassification: '310400000X-ASSISTED LIVING FACILITY',
    placeOfService: null,
  },
  {
    code: '3104A0625X',
    classification: 'ASSISTED LIVING MENTAL IL',
    codeClassification: '3104A0625X-ASSISTED LIVING MENTAL IL',
    placeOfService: null,
  },
  {
    code: '2255A2300X',
    classification: 'ATHLETIC TRAINER',
    codeClassification: '2255A2300X-ATHLETIC TRAINER',
    placeOfService: null,
  },
  {
    code: '231H00000X',
    classification: 'AUDIOLGIST',
    codeClassification: '231H00000X-AUDIOLGIST',
    placeOfService: null,
  },
  {
    code: '237600000X',
    classification: 'AUDIOLOGIST HEARING AID F',
    codeClassification: '237600000X-AUDIOLOGIST HEARING AID F',
    placeOfService: null,
  },
  {
    code: '2355A2700X',
    classification: 'AUDIOLOGY ASSISTANT',
    codeClassification: '2355A2700X-AUDIOLOGY ASSISTANT',
    placeOfService: null,
  },
  {
    code: '103K00000X',
    classification: 'BEHAVIORAL ANALYST',
    codeClassification: '103K00000X-BEHAVIORAL ANALYST',
    placeOfService: null,
  },
  {
    code: '103G00000X',
    classification: 'BEHAVIORAL HEALTH AND SOC',
    codeClassification: '103G00000X-BEHAVIORAL HEALTH AND SOC',
    placeOfService: null,
  },
  {
    code: '103TA0400X',
    classification: 'BEHAVIORAL HEALTH PSYCHOL',
    codeClassification: '103TA0400X-BEHAVIORAL HEALTH PSYCHOL',
    placeOfService: null,
  },
  {
    code: '2084B0040X',
    classification: 'BEHAVIORAL NEURO NEUROPSYCHIATRY',
    codeClassification: '2084B0040X-BEHAVIORAL NEURO NEUROPSYCHIATRY',
    placeOfService: null,
  },
  {
    code: '102X00000X',
    classification: 'BEHAVIORAL POETRY THERAPI',
    codeClassification: '102X00000X-BEHAVIORAL POETRY THERAPI',
    placeOfService: null,
  },
  {
    code: '106S00000X',
    classification: 'BEHAVIORAL TECHNICIAN',
    codeClassification: '106S00000X-BEHAVIORAL TECHNICIAN',
    placeOfService: null,
  },
  {
    code: '207ZB0001X',
    classification: 'BLOOD BANKING AND TRANSFUSION MEDIC',
    codeClassification: '207ZB0001X-BLOOD BANKING AND TRANSFUSION MEDIC',
    placeOfService: null,
  },
  {
    code: '2081P0301X',
    classification: 'BRAIN INJURY MEDICINE',
    codeClassification: '2081P0301X-BRAIN INJURY MEDICINE',
    placeOfService: null,
  },
  {
    code: '207RC0000X',
    classification: 'CARDIOVASCULAR DISEASE',
    codeClassification: '207RC0000X-CARDIOVASCULAR DISEASE',
    placeOfService: null,
  },
  {
    code: '171M00000X',
    classification: 'CARE MANAGER',
    codeClassification: '171M00000X-CARE MANAGER',
    placeOfService: null,
  },
  {
    code: '251B00000X',
    classification: 'CASE MANAGEMENT',
    codeClassification: '251B00000X-CASE MANAGEMENT',
    placeOfService: null,
  },
  {
    code: '171M00000X',
    classification: 'CASE MGR CARE COORDINATOR',
    codeClassification: '171M00000X-CASE MGR CARE COORDINATOR',
    placeOfService: null,
  },
  {
    code: '261QM0855X',
    classification: 'CHILD MENTAL HEALTH CLINIC',
    codeClassification: '261QM0855X-CHILD MENTAL HEALTH CLINIC',
    placeOfService: null,
  },
  {
    code: '2084N0402X',
    classification: 'CHILD NEUROLOGY',
    codeClassification: '2084N0402X-CHILD NEUROLOGY',
    placeOfService: null,
  },
  {
    code: '111NI0013X',
    classification: 'CHIRO IND MEDICAL EXAMINE',
    codeClassification: '111NI0013X-CHIRO IND MEDICAL EXAMINE',
    placeOfService: null,
  },
  {
    code: '111N00000X',
    classification: 'CHIROPRACTOR',
    codeClassification: '111N00000X-CHIROPRACTOR',
    placeOfService: null,
  },
  {
    code: '111NI0900X',
    classification: 'CHIROPRACTOR INTERNIST',
    codeClassification: '111NI0900X-CHIROPRACTOR INTERNIST',
    placeOfService: null,
  },
  {
    code: '111NN0400X',
    classification: 'CHIROPRACTOR NEUROLOGY',
    codeClassification: '111NN0400X-CHIROPRACTOR NEUROLOGY',
    placeOfService: null,
  },
  {
    code: '111NN1001X',
    classification: 'CHIROPRACTOR NUTRITION',
    codeClassification: '111NN1001X-CHIROPRACTOR NUTRITION',
    placeOfService: null,
  },
  {
    code: '111NX0100X',
    classification: 'CHIROPRACTOR OCCUPATIONAL',
    codeClassification: '111NX0100X-CHIROPRACTOR OCCUPATIONAL',
    placeOfService: null,
  },
  {
    code: '111NX0800X',
    classification: 'CHIROPRACTOR ORTHOPEDIC',
    codeClassification: '111NX0800X-CHIROPRACTOR ORTHOPEDIC',
    placeOfService: null,
  },
  {
    code: '111NR0200X',
    classification: 'CHIROPRACTOR RADIOLOGY',
    codeClassification: '111NR0200X-CHIROPRACTOR RADIOLOGY',
    placeOfService: null,
  },
  {
    code: '111NR0400X',
    classification: 'CHIROPRACTOR REHABILITATI',
    codeClassification: '111NR0400X-CHIROPRACTOR REHABILITATI',
    placeOfService: null,
  },
  {
    code: '111NS0005X',
    classification: 'CHIROPRACTOR SPORTS PHYSI',
    codeClassification: '111NS0005X-CHIROPRACTOR SPORTS PHYSI',
    placeOfService: null,
  },
  {
    code: '111NT0100X',
    classification: 'CHIROPRACTOR THERMOGRAPHY',
    codeClassification: '111NT0100X-CHIROPRACTOR THERMOGRAPHY',
    placeOfService: null,
  },
  {
    code: '364SA2100X',
    classification: 'CL NRS SP AC CARE',
    codeClassification: '364SA2100X-CL NRS SP AC CARE',
    placeOfService: null,
  },
  {
    code: '364SG0600X',
    classification: 'CL NRS SP GERI',
    codeClassification: '364SG0600X-CL NRS SP GERI',
    placeOfService: null,
  },
  {
    code: '364SM0705X',
    classification: 'CL NRS SP MED SURG',
    codeClassification: '364SM0705X-CL NRS SP MED SURG',
    placeOfService: null,
  },
  {
    code: '364SN0000X',
    classification: 'CL NRS SP NEONATE',
    codeClassification: '364SN0000X-CL NRS SP NEONATE',
    placeOfService: null,
  },
  {
    code: '364SN0800X',
    classification: 'CL NRS SP NEUROSC',
    codeClassification: '364SN0800X-CL NRS SP NEUROSC',
    placeOfService: null,
  },
  {
    code: '364SX0106X',
    classification: 'CL NRS SP OCCUPAT',
    codeClassification: '364SX0106X-CL NRS SP OCCUPAT',
    placeOfService: null,
  },
  {
    code: '364SX0200X',
    classification: 'CL NRS SP ONCOL',
    codeClassification: '364SX0200X-CL NRS SP ONCOL',
    placeOfService: null,
  },
  {
    code: '364SX0204X',
    classification: 'CL NRS SP ONCOL PEDS',
    codeClassification: '364SX0204X-CL NRS SP ONCOL PEDS',
    placeOfService: null,
  },
  {
    code: '364SP0200X',
    classification: 'CL NRS SP PEDS',
    codeClassification: '364SP0200X-CL NRS SP PEDS',
    placeOfService: null,
  },
  {
    code: '364SP1700X',
    classification: 'CL NRS SP PERINATAL',
    codeClassification: '364SP1700X-CL NRS SP PERINATAL',
    placeOfService: null,
  },
  {
    code: '364SP2800X',
    classification: 'CL NRS SP PERIOP',
    codeClassification: '364SP2800X-CL NRS SP PERIOP',
    placeOfService: null,
  },
  {
    code: '364SR0400X',
    classification: 'CL NRS SP REHAB',
    codeClassification: '364SR0400X-CL NRS SP REHAB',
    placeOfService: null,
  },
  {
    code: '364SS0200X',
    classification: 'CL NRS SP SCHOOL',
    codeClassification: '364SS0200X-CL NRS SP SCHOOL',
    placeOfService: null,
  },
  {
    code: '364ST0500X',
    classification: 'CL NRS SP TRANSPLNT',
    codeClassification: '364ST0500X-CL NRS SP TRANSPLNT',
    placeOfService: null,
  },
  {
    code: '364SW0102X',
    classification: 'CL NRS SP WOMENS HTH',
    codeClassification: '364SW0102X-CL NRS SP WOMENS HTH',
    placeOfService: null,
  },
  {
    code: '261Q00000X',
    classification: 'CLINIC CENTER',
    codeClassification: '261Q00000X-CLINIC CENTER',
    placeOfService: null,
  },
  {
    code: '261QM0855X',
    classification: 'CLINIC CENTER ADOLESCENT',
    codeClassification: '261QM0855X-CLINIC CENTER ADOLESCENT',
    placeOfService: null,
  },
  {
    code: '261QA0600X',
    classification: 'CLINIC CENTER ADULT',
    codeClassification: '261QA0600X-CLINIC CENTER ADULT',
    placeOfService: null,
  },
  {
    code: '261QA3000X',
    classification: 'CLINIC CENTER AUGUMENTATI',
    codeClassification: '261QA3000X-CLINIC CENTER AUGUMENTATI',
    placeOfService: null,
  },
  {
    code: '261QD1600X',
    classification: 'CLINIC CENTER DEV DISB',
    codeClassification: '261QD1600X-CLINIC CENTER DEV DISB',
    placeOfService: null,
  },
  {
    code: '261QE0002X',
    classification: 'CLINIC CENTER EMERGENCY',
    codeClassification: '261QE0002X-CLINIC CENTER EMERGENCY',
    placeOfService: null,
  },
  {
    code: '261QF0050X',
    classification: 'CLINIC CENTER FAMILY PLAN',
    codeClassification: '261QF0050X-CLINIC CENTER FAMILY PLAN',
    placeOfService: null,
  },
  {
    code: '261QF0400X',
    classification: 'CLINIC CENTER FED QUALIFI',
    codeClassification: '261QF0400X-CLINIC CENTER FED QUALIFI',
    placeOfService: null,
  },
  {
    code: '261QM3000X',
    classification: 'CLINIC CENTER FRAG INFANT',
    codeClassification: '261QM3000X-CLINIC CENTER FRAG INFANT',
    placeOfService: null,
  },
  {
    code: '261QM3000X',
    classification: 'CLINIC CENTER MEDICALLY F',
    codeClassification: '261QM3000X-CLINIC CENTER MEDICALLY F',
    placeOfService: null,
  },
  {
    code: '261QM1101X',
    classification: 'CLINIC CENTER MILITARY AM',
    codeClassification: '261QM1101X-CLINIC CENTER MILITARY AM',
    placeOfService: null,
  },
  {
    code: '261QM1100X',
    classification: 'CLINIC CENTER MILITARY OP',
    codeClassification: '261QM1100X-CLINIC CENTER MILITARY OP',
    placeOfService: null,
  },
  {
    code: '261QR0207X',
    classification: 'CLINIC CENTER MOBILE MAMM',
    codeClassification: '261QR0207X-CLINIC CENTER MOBILE MAMM',
    placeOfService: null,
    isProcedureCodeRequired: true,
  },
  {
    code: '261QX0200X',
    classification: 'CLINIC CENTER ONCOLOGY',
    codeClassification: '261QX0200X-CLINIC CENTER ONCOLOGY',
    placeOfService: null,
  },
  {
    code: '261QX0203X',
    classification: 'CLINIC CENTER ONCOLOGY RA',
    codeClassification: '261QX0203X-CLINIC CENTER ONCOLOGY RA',
    placeOfService: null,
  },
  {
    code: '261QP2400X',
    classification: 'CLINIC CENTER PRISON HEAL',
    codeClassification: '261QP2400X-CLINIC CENTER PRISON HEAL',
    placeOfService: null,
  },
  {
    code: '261QP0904X',
    classification: 'CLINIC CENTER PUB HLTH FE',
    codeClassification: '261QP0904X-CLINIC CENTER PUB HLTH FE',
    placeOfService: null,
  },
  {
    code: '261QP0905X',
    classification: 'CLINIC CENTER PUB HLTH ST',
    codeClassification: '261QP0905X-CLINIC CENTER PUB HLTH ST',
    placeOfService: null,
  },
  {
    code: '261QR0206X',
    classification: 'CLINIC CENTER RAD MAMM',
    codeClassification: '261QR0206X-CLINIC CENTER RAD MAMM',
    placeOfService: null,
    isProcedureCodeRequired: true,
  },
  {
    code: '261QR0208X',
    classification: 'CLINIC CENTER RAD MOBILE',
    codeClassification: '261QR0208X-CLINIC CENTER RAD MOBILE',
    placeOfService: null,
    isProcedureCodeRequired: true,
  },
  {
    code: '261QR0200X',
    classification: 'CLINIC CENTER RADIOLOGY',
    codeClassification: '261QR0200X-CLINIC CENTER RADIOLOGY',
    placeOfService: null,
    isProcedureCodeRequired: true,
  },
  {
    code: '261QR0404X',
    classification: 'CLINIC CENTER REHAB CARDI',
    codeClassification: '261QR0404X-CLINIC CENTER REHAB CARDI',
    placeOfService: '22,24,49',
  },
  {
    code: '261QR0404X',
    classification: 'AMBULATORY HEALTH CARE CL',
    codeClassification: '261QR0404X-AMBULATORY HEALTH CARE CL',
    placeOfService: '22,24,49',
    isProcedureCodeRequired: true,
  },
  {
    code: '261QR1300X',
    classification: 'CLINIC CENTER RURAL HEALT',
    codeClassification: '261QR1300X-CLINIC CENTER RURAL HEALT',
    placeOfService: null,
  },
  {
    code: '261QS1000X',
    classification: 'CLINIC CENTER STUDENT HEA',
    codeClassification: '261QS1000X-CLINIC CENTER STUDENT HEA',
    placeOfService: null,
  },
  {
    code: '261QM2800X',
    classification: 'CLINIC METHADONE',
    codeClassification: '261QM2800X-CLINIC METHADONE',
    placeOfService: null,
  },
  {
    code: '261QM1300X',
    classification: 'CLINIC MULTISPECIALTY',
    codeClassification: '261QM1300X-CLINIC MULTISPECIALTY',
    placeOfService: null,
  },
  {
    code: '207NI0002X',
    classification: 'CLINICAL AND LAB DERMATOL',
    codeClassification: '207NI0002X-CLINICAL AND LAB DERMATOL',
    placeOfService: null,
  },
  {
    code: '207NI0002X',
    classification: 'CLINICAL AND LAB DERMATOLOGICAL',
    codeClassification: '207NI0002X-CLINICAL AND LAB DERMATOLOGICAL',
    placeOfService: null,
  },
  {
    code: '207KI0005X',
    classification: 'CLINICAL AND LABORATORY I',
    codeClassification: '207KI0005X-CLINICAL AND LABORATORY I',
    placeOfService: null,
  },
  {
    code: '207SG0202X',
    classification: 'CLINICAL BIOCHEMICAL GENE',
    codeClassification: '207SG0202X-CLINICAL BIOCHEMICAL GENE',
    placeOfService: null,
  },
  {
    code: '207SC0300X',
    classification: 'CLINICAL CYTOGENETICS',
    codeClassification: '207SC0300X-CLINICAL CYTOGENETICS',
    placeOfService: null,
  },
  {
    code: '224Y00000X',
    classification: 'CLINICAL EXERCISE PHYSIOLOGIST',
    codeClassification: '224Y00000X-CLINICAL EXERCISE PHYSIOLOGIST',
    placeOfService: null,
  },
  {
    code: '207SG0201X',
    classification: 'CLINICAL GENETICSMD',
    codeClassification: '207SG0201X-CLINICAL GENETICSMD',
    placeOfService: null,
  },
  {
    code: '2083C0008X',
    classification: 'CLINICAL INFORMATICS',
    codeClassification: '2083C0008X-CLINICAL INFORMATICS',
    placeOfService: null,
  },
  {
    code: '291U00000X',
    classification: 'CLINICAL MEDICAL LAB',
    codeClassification: '291U00000X-CLINICAL MEDICAL LAB',
    placeOfService: null,
  },
  {
    code: '207SG0203X',
    classification: 'CLINICAL MOLECULAR GENETI',
    codeClassification: '207SG0203X-CLINICAL MOLECULAR GENETI',
    placeOfService: null,
  },
  {
    code: '364S00000X',
    classification: 'CLINICAL NURSE SPECIALIST',
    codeClassification: '364S00000X-CLINICAL NURSE SPECIALIST',
    placeOfService: null,
  },
  {
    code: '208U00000X',
    classification: 'CLINICAL PHARMACOLOGY',
    codeClassification: '208U00000X-CLINICAL PHARMACOLOGY',
    placeOfService: null,
  },
  {
    code: '208C00000X',
    classification: 'COLON AND RECTAL SURGERY',
    codeClassification: '208C00000X-COLON AND RECTAL SURGERY',
    placeOfService: null,
  },
  {
    code: '251S00000X',
    classification: 'COMMUNITY BEHAVIORAL HLTH AGY',
    codeClassification: '251S00000X-COMMUNITY BEHAVIORAL HLTH AGY',
    placeOfService: null,
  },
  {
    code: '261QC1500X',
    classification: 'COMMUNITY HEALTH CLINIC',
    codeClassification: '261QC1500X-COMMUNITY HEALTH CLINIC',
    placeOfService: null,
  },
  {
    code: '101Y00000X',
    classification: 'COUNSELOR',
    codeClassification: '101Y00000X-COUNSELOR',
    placeOfService: null,
  },
  {
    code: '101YA0400X',
    classification: 'COUNSELOR ADDICTIONSUBS',
    codeClassification: '101YA0400X-COUNSELOR ADDICTIONSUBS',
    placeOfService: null,
  },
  {
    code: '101YA0400X',
    classification: 'COUNSELOR ADDICTION SUBST',
    codeClassification: '101YA0400X-COUNSELOR ADDICTION SUBST',
    placeOfService: null,
  },
  {
    code: '101YM0800X',
    classification: 'COUNSELOR MENTAL HEALTH',
    codeClassification: '101YM0800X-COUNSELOR MENTAL HEALTH',
    placeOfService: null,
  },
  {
    code: '101YP1600X',
    classification: 'COUNSELOR PASTORAL',
    codeClassification: '101YP1600X-COUNSELOR PASTORAL',
    placeOfService: null,
  },
  {
    code: '101YP2500X',
    classification: 'COUNSELOR PROFESSIONAL',
    codeClassification: '101YP2500X-COUNSELOR PROFESSIONAL',
    placeOfService: null,
  },
  {
    code: '101YS0200X',
    classification: 'COUNSELOR SCHOOL',
    codeClassification: '101YS0200X-COUNSELOR SCHOOL',
    placeOfService: null,
  },
  {
    code: '261QC0050X',
    classification: 'CRITICAL ACCESS HOSPITAL',
    codeClassification: '261QC0050X-CRITICAL ACCESS HOSPITAL',
    placeOfService: null,
  },
  {
    code: '311Z00000X',
    classification: 'CUSTODIAL CARE FACILITY',
    codeClassification: '311Z00000X-CUSTODIAL CARE FACILITY',
    placeOfService: null,
  },
  {
    code: '124Q00000X',
    classification: 'DENTAL HYGIENIST',
    codeClassification: '124Q00000X-DENTAL HYGIENIST',
    placeOfService: null,
  },
  {
    code: '126900000X',
    classification: 'DENTAL LABORATORY TECHNIC',
    codeClassification: '126900000X-DENTAL LABORATORY TECHNIC',
    placeOfService: null,
  },
  {
    code: '1223P0106X',
    classification: 'DENTAL ORAL AND MAX PATHO',
    codeClassification: '1223P0106X-DENTAL ORAL AND MAX PATHO',
    placeOfService: null,
  },
  {
    code: '1223D0001X',
    classification: 'DENTAL PROVIDERS DENTIST',
    codeClassification: '1223D0001X-DENTAL PROVIDERS DENTIST',
    placeOfService: null,
  },
  {
    code: '1223E0200X',
    classification: 'DENTAL PROVIDERS DENTIST',
    codeClassification: '1223E0200X-DENTAL PROVIDERS DENTIST',
    placeOfService: null,
  },
  {
    code: '1223G0001X',
    classification: 'DENTAL PROVIDERS DENTIST',
    codeClassification: '1223G0001X-DENTAL PROVIDERS DENTIST',
    placeOfService: null,
  },
  {
    code: '1223P0221X',
    classification: 'DENTAL PROVIDERS DENTIST',
    codeClassification: '1223P0221X-DENTAL PROVIDERS DENTIST',
    placeOfService: null,
  },
  {
    code: '1223X0008X',
    classification: 'DENTAL PROVIDERS DENTIST',
    codeClassification: '1223X0008X-DENTAL PROVIDERS DENTIST',
    placeOfService: null,
  },
  {
    code: '122300000X',
    classification: 'DENTIST',
    codeClassification: '122300000X-DENTIST',
    placeOfService: null,
  },
  {
    code: '1223D0004X',
    classification: 'DENTIST ANESTHESIOLOGIST',
    codeClassification: '1223D0004X-DENTIST ANESTHESIOLOGIST',
    placeOfService: null,
  },
  {
    code: '1223D0001X',
    classification: 'DENTIST DENTAL PUBLIC HEA',
    codeClassification: '1223D0001X-DENTIST DENTAL PUBLIC HEA',
    placeOfService: null,
  },
  {
    code: '1223E0200X',
    classification: 'DENTIST ENDODONTICS',
    codeClassification: '1223E0200X-DENTIST ENDODONTICS',
    placeOfService: null,
  },
  {
    code: '1223G0001X',
    classification: 'DENTIST GENERAL PRACTICE',
    codeClassification: '1223G0001X-DENTIST GENERAL PRACTICE',
    placeOfService: null,
  },
  {
    code: '1223X0400X',
    classification: 'DENTIST ORTHODONTICS AND',
    codeClassification: '1223X0400X-DENTIST ORTHODONTICS AND',
    placeOfService: null,
  },
  {
    code: '1223P0221X',
    classification: 'DENTIST PEDIATRIC DENTIST',
    codeClassification: '1223P0221X-DENTIST PEDIATRIC DENTIST',
    placeOfService: null,
  },
  {
    code: '1223P0300X',
    classification: 'DENTIST PERIODONTICS',
    codeClassification: '1223P0300X-DENTIST PERIODONTICS',
    placeOfService: null,
  },
  {
    code: '1223P0700X',
    classification: 'DENTIST PROSTHODONTICS',
    codeClassification: '1223P0700X-DENTIST PROSTHODONTICS',
    placeOfService: null,
  },
  {
    code: '122400000X',
    classification: 'DENTURIST',
    codeClassification: '122400000X-DENTURIST',
    placeOfService: null,
  },
  {
    code: '207NS0135X',
    classification: 'DERMATOLOGICAL SURGERY',
    codeClassification: '207NS0135X-DERMATOLOGICAL SURGERY',
    placeOfService: null,
  },
  {
    code: '207N00000X',
    classification: 'DERMATOLOGY',
    codeClassification: '207N00000X-DERMATOLOGY',
    placeOfService: null,
  },
  {
    code: '207ND0900X',
    classification: 'DERMATOPATHOLGY',
    codeClassification: '207ND0900X-DERMATOPATHOLGY',
    placeOfService: null,
  },
  {
    code: '207ZD0900X',
    classification: 'DERMATOPATHOLOGY',
    codeClassification: '207ZD0900X-DERMATOPATHOLOGY',
    placeOfService: null,
  },
  {
    code: '163WD0400X',
    classification: 'DIABETES EDUCATOR',
    codeClassification: '163WD0400X-DIABETES EDUCATOR',
    placeOfService: null,
  },
  {
    code: '207KI0005X',
    classification: 'DIAGNOSTIC LAB IMMUNOLOGY',
    codeClassification: '207KI0005X-DIAGNOSTIC LAB IMMUNOLOGY',
    placeOfService: null,
  },
  {
    code: '2085R0202X',
    classification: 'DIAGNOSTIC RADIOLOGY',
    codeClassification: '2085R0202X-DIAGNOSTIC RADIOLOGY',
    placeOfService: null,
    isProcedureCodeRequired: true,
  },
  {
    code: '132700000X',
    classification: 'DIETARY MANAGER',
    codeClassification: '132700000X-DIETARY MANAGER',
    placeOfService: null,
  },
  {
    code: '133VN1004X',
    classification: 'DIETICIANREGISTERED N',
    codeClassification: '133VN1004X-DIETICIANREGISTERED N',
    placeOfService: null,
  },
  {
    code: '133V00000X',
    classification: 'DIETICIAN REGISTERED',
    codeClassification: '133V00000X-DIETICIAN REGISTERED',
    placeOfService: null,
  },
  {
    code: '224L00000X',
    classification: 'DME SUPPLIER PEDORTHICS',
    codeClassification: '224L00000X-DME SUPPLIER PEDORTHICS',
    placeOfService: null,
  },
  {
    code: '252Y00000X',
    classification: 'EARLY INTERVENTION PROVIDER AGENCY',
    codeClassification: '252Y00000X-EARLY INTERVENTION PROVIDER AGENCY',
    placeOfService: null,
  },
  {
    code: '204R00000X',
    classification: 'ELECTRODIAGNOSTIC MEDICIN',
    codeClassification: '204R00000X-ELECTRODIAGNOSTIC MEDICIN',
    placeOfService: null,
  },
  {
    code: '207PE0004X',
    classification: 'EMERGENCY MEDICAL SERVICE',
    codeClassification: '207PE0004X-EMERGENCY MEDICAL SERVICE',
    placeOfService: null,
  },
  {
    code: '207P00000X',
    classification: 'EMERGENCY MEDICINE',
    codeClassification: '207P00000X-EMERGENCY MEDICINE',
    placeOfService: null,
  },
  {
    code: '207PT0002X',
    classification: 'EMERGENCY MEDICINE MEDICA',
    codeClassification: '207PT0002X-EMERGENCY MEDICINE MEDICA',
    placeOfService: null,
  },
  {
    code: '207PP0204X',
    classification: 'EMERGENCY MEDICINE PEDIAT',
    codeClassification: '207PP0204X-EMERGENCY MEDICINE PEDIAT',
    placeOfService: null,
  },
  {
    code: '333300000X',
    classification: 'EMERGENCY RESPONSE SYSTEM',
    codeClassification: '333300000X-EMERGENCY RESPONSE SYSTEM',
    placeOfService: null,
  },
  {
    code: '207PS0010X',
    classification: 'EMERGENCY SPORTS MEDICINE',
    codeClassification: '207PS0010X-EMERGENCY SPORTS MEDICINE',
    placeOfService: null,
  },
  {
    code: '101YM0800X',
    classification: 'EMPLOYEE ASSISTANCE PROGRAM COUNSEL',
    codeClassification: '101YM0800X-EMPLOYEE ASSISTANCE PROGRAM COUNSEL',
    placeOfService: null,
  },
  {
    code: '261QE0700X',
    classification: 'END STAGE RENAL DISEASE TREATMENT',
    codeClassification: '261QE0700X-END STAGE RENAL DISEASE TREATMENT',
    placeOfService: null,
  },
  {
    code: '207RE0101X',
    classification: 'ENDOCRINOLOGY DIABETES AND METABOLI',
    codeClassification: '207RE0101X-ENDOCRINOLOGY DIABETES AND METABOLI',
    placeOfService: null,
  },
  {
    code: '207PH0002X',
    classification: 'ER HOSP AND PAL MED',
    codeClassification: '207PH0002X-ER HOSP AND PAL MED',
    placeOfService: null,
  },
  {
    code: '207PE0005X',
    classification: 'ER UNDERSEA AND HYPERBARI',
    codeClassification: '207PE0005X-ER UNDERSEA AND HYPERBARI',
    placeOfService: null,
  },
  {
    code: '332G00000X',
    classification: 'EYE BANK',
    codeClassification: '332G00000X-EYE BANK',
    placeOfService: null,
  },
  {
    code: '207YS0123X',
    classification: 'FACIAL PLASTIC SURGERY',
    codeClassification: '207YS0123X-FACIAL PLASTIC SURGERY',
    placeOfService: null,
  },
  {
    code: '207QB0002X',
    classification: 'FAMILY OBESITY MEDICINE',
    codeClassification: '207QB0002X-FAMILY OBESITY MEDICINE',
    placeOfService: null,
  },
  {
    code: '207V00000X',
    classification: 'FAMILY PLANNING',
    codeClassification: '207V00000X-FAMILY PLANNING',
    placeOfService: null,
  },
  {
    code: '261QF0050X',
    classification: 'FAMILY PLANNING NON SURGICAL',
    codeClassification: '261QF0050X-FAMILY PLANNING NON SURGICAL',
    placeOfService: null,
  },
  {
    code: '207Q00000X',
    classification: 'FAMILY PRACTICE',
    codeClassification: '207Q00000X-FAMILY PRACTICE',
    placeOfService: null,
  },
  {
    code: '207QA0401X',
    classification: 'FAMILY PRACTICE ADDICTION',
    codeClassification: '207QA0401X-FAMILY PRACTICE ADDICTION',
    placeOfService: null,
  },
  {
    code: '207QA0000X',
    classification: 'FAMILY PRACTICE ADOLESCEN',
    codeClassification: '207QA0000X-FAMILY PRACTICE ADOLESCEN',
    placeOfService: null,
  },
  {
    code: '207QA0505X',
    classification: 'FAMILY PRACTICE ADULT MED',
    codeClassification: '207QA0505X-FAMILY PRACTICE ADULT MED',
    placeOfService: null,
  },
  {
    code: '207QG0300X',
    classification: 'FAMILY PRACTICE GERIATRIC',
    codeClassification: '207QG0300X-FAMILY PRACTICE GERIATRIC',
    placeOfService: null,
  },
  {
    code: '207QH0002X',
    classification: 'FAMILY PRACTICE HOSPP',
    codeClassification: '207QH0002X-FAMILY PRACTICE HOSPP',
    placeOfService: null,
  },
  {
    code: '207QS1201X',
    classification: 'FAMILY PRACTICE SLEEP MED',
    codeClassification: '207QS1201X-FAMILY PRACTICE SLEEP MED',
    placeOfService: null,
  },
  {
    code: '207QS0010X',
    classification: 'FAMILY PRACTICE SPORTS ME',
    codeClassification: '207QS0010X-FAMILY PRACTICE SPORTS ME',
    placeOfService: null,
  },
  {
    code: '253J00000X',
    classification: 'FOSTER CARE AGENCY',
    codeClassification: '253J00000X-FOSTER CARE AGENCY',
    placeOfService: null,
  },
  {
    code: '207RG0100X',
    classification: 'GASTROENTEROLOGY',
    codeClassification: '207RG0100X-GASTROENTEROLOGY',
    placeOfService: null,
  },
  {
    code: '208D00000X',
    classification: 'GENERAL PRACTICE',
    codeClassification: '208D00000X-GENERAL PRACTICE',
    placeOfService: null,
  },
  {
    code: '170300000X',
    classification: 'GENETIC COUNSELOR',
    codeClassification: '170300000X-GENETIC COUNSELOR',
    placeOfService: null,
  },
  {
    code: '207VX0201X',
    classification: 'GYNECOLOGIC ONCOLOGY',
    codeClassification: '207VX0201X-GYNECOLOGIC ONCOLOGY',
    placeOfService: null,
  },
  {
    code: '207VG0400X',
    classification: 'GYNECOLOGY',
    codeClassification: '207VG0400X-GYNECOLOGY',
    placeOfService: null,
  },
  {
    code: '207XS0106X',
    classification: 'HAND SURGERY',
    codeClassification: '207XS0106X-HAND SURGERY',
    placeOfService: null,
  },
  {
    code: '174H00000X',
    classification: 'HEALTH EDUCATOR',
    codeClassification: '174H00000X-HEALTH EDUCATOR',
    placeOfService: null,
  },
  {
    code: '237700000X',
    classification: 'HEARING INST SPEC GROUP',
    codeClassification: '237700000X-HEARING INST SPEC GROUP',
    placeOfService: null,
  },
  {
    code: '237700000X',
    classification: 'HEARING INSTRUMENT SPECIA',
    codeClassification: '237700000X-HEARING INSTRUMENT SPECIA',
    placeOfService: null,
  },
  {
    code: '171WH0202X',
    classification: 'HOME MODIFICATION CONTRACTOR',
    codeClassification: '171WH0202X-HOME MODIFICATION CONTRACTOR',
    placeOfService: null,
  },
  {
    code: '273100000X',
    classification: 'HOSPITAL UNIT EPILEPSY U',
    codeClassification: '273100000X-HOSPITAL UNIT EPILEPSY U',
    placeOfService: '21, 22',
    isProcedureCodeRequired: true,
  },
  {
    code: '273R00000X',
    classification: 'HOSPITAL UNITS PSYCHIATRI',
    codeClassification: '273R00000X-HOSPITAL UNITS PSYCHIATRI',
    placeOfService: null,
  },
  {
    code: '276400000X',
    classification: 'HOSPITAL UNITS REHAB SUBS',
    codeClassification: '276400000X-HOSPITAL UNITS REHAB SUBS',
    placeOfService: null,
  },
  {
    code: '208M00000X',
    classification: 'HOSPITALIST',
    codeClassification: '208M00000X-HOSPITALIST',
    placeOfService: null,
  },
  {
    code: '281P00000X',
    classification: 'HOSPITALS CHRONIC DISEASE',
    codeClassification: '281P00000X-HOSPITALS CHRONIC DISEASE',
    placeOfService: null,
  },
  {
    code: '282N00000X',
    classification: 'HOSPITALS GENERAL ACUTE C',
    codeClassification: '282N00000X-HOSPITALS GENERAL ACUTE C',
    placeOfService: '21, 22',
    isProcedureCodeRequired: true,
  },
  {
    code: '282NC0060X',
    classification: 'HOSPITALS GENERAL ACUTE C',
    codeClassification: '282NC0060X-HOSPITALS GENERAL ACUTE C',
    placeOfService: '21, 22',
    isProcedureCodeRequired: true,
  },
  {
    code: '282NC2000X',
    classification: 'HOSPITALS GENERAL ACUTE C',
    codeClassification: '282NC2000X-HOSPITALS GENERAL ACUTE C',
    placeOfService: null,
  },
  {
    code: '282NR1301X',
    classification: 'HOSPITALS GENERAL ACUTE C',
    codeClassification: '282NR1301X-HOSPITALS GENERAL ACUTE C',
    placeOfService: '21, 22',
    isProcedureCodeRequired: true,
  },
  {
    code: '2865M2000X',
    classification: 'HOSPITALS MILITARY GENERA',
    codeClassification: '2865M2000X-HOSPITALS MILITARY GENERA',
    placeOfService: null,
  },
  {
    code: '286500000X',
    classification: 'HOSPITALS MILITARY HOSPIT',
    codeClassification: '286500000X-HOSPITALS MILITARY HOSPIT',
    placeOfService: null,
  },
  {
    code: '284300000X',
    classification: 'HOSPITALS SPECIAL HOSPITA',
    codeClassification: '284300000X-HOSPITALS SPECIAL HOSPITA',
    placeOfService: '21, 22',
    isProcedureCodeRequired: true,
  },
  {
    code: '207RH0005X',
    classification: 'HYPERTENSION SPECIALIST',
    codeClassification: '207RH0005X-HYPERTENSION SPECIALIST',
    placeOfService: null,
  },
  {
    code: '315D00000X',
    classification: 'IN PATIENT HOSPICE',
    codeClassification: '315D00000X-IN PATIENT HOSPICE',
    placeOfService: null,
  },
  {
    code: '332800000X',
    classification: 'IND HEALTH SERV PHARM',
    codeClassification: '332800000X-IND HEALTH SERV PHARM',
    placeOfService: null,
  },
  {
    code: '202C00000X',
    classification: 'INDEPENDENT MEDICAL EXAMI',
    codeClassification: '202C00000X-INDEPENDENT MEDICAL EXAMI',
    placeOfService: null,
  },
  {
    code: '310500000X',
    classification: 'INTERMEDIATE CARE FAC MEN',
    codeClassification: '310500000X-INTERMEDIATE CARE FAC MEN',
    placeOfService: null,
  },
  {
    code: '315P00000X',
    classification: 'INTERMEDIATE CARE FAC MEN',
    codeClassification: '315P00000X-INTERMEDIATE CARE FAC MEN',
    placeOfService: null,
  },
  {
    code: '207R00000X',
    classification: 'INTERNAL MED HEADACHE TRM',
    codeClassification: '207R00000X-INTERNAL MED HEADACHE TRM',
    placeOfService: null,
  },
  {
    code: '207RB0002X',
    classification: 'INTERNAL MED OBESITY MED',
    codeClassification: '207RB0002X-INTERNAL MED OBESITY MED',
    placeOfService: null,
  },
  {
    code: '207RP1001X',
    classification: 'INTERNAL MED PULMONARY DISEASE',
    codeClassification: '207RP1001X-INTERNAL MED PULMONARY DISEASE',
    placeOfService: null,
  },
  {
    code: '207RS0012X',
    classification: 'INTERNAL MED SLEEP MEDICI',
    codeClassification: '207RS0012X-INTERNAL MED SLEEP MEDICI',
    placeOfService: null,
  },
  {
    code: '207RT0003X',
    classification: 'INTERNAL MED TRANSPLANT H',
    codeClassification: '207RT0003X-INTERNAL MED TRANSPLANT H',
    placeOfService: null,
  },
  {
    code: '207R00000X',
    classification: 'INTERNAL MEDICINE',
    codeClassification: '207R00000X-INTERNAL MEDICINE',
    placeOfService: null,
  },
  {
    code: '207RA0000X',
    classification: 'INTERNAL MEDICINE',
    codeClassification: '207RA0000X-INTERNAL MEDICINE',
    placeOfService: null,
  },
  {
    code: '207RA0401X',
    classification: 'INTERNAL MEDICINE ADDICTI',
    codeClassification: '207RA0401X-INTERNAL MEDICINE ADDICTI',
    placeOfService: null,
  },
  {
    code: '207RA0000X',
    classification: 'INTERNAL MEDICINE ADOLESC',
    codeClassification: '207RA0000X-INTERNAL MEDICINE ADOLESC',
    placeOfService: null,
  },
  {
    code: '207RA0201X',
    classification: 'INTERNAL MEDICINE ALLERGY',
    codeClassification: '207RA0201X-INTERNAL MEDICINE ALLERGY',
    placeOfService: null,
  },
  {
    code: '207RC0000X',
    classification: 'INTERNAL MEDICINE CARDIOV',
    codeClassification: '207RC0000X-INTERNAL MEDICINE CARDIOV',
    placeOfService: null,
  },
  {
    code: '207RC0001X',
    classification: 'INTERNAL MEDICINE CLINICA',
    codeClassification: '207RC0001X-INTERNAL MEDICINE CLINICA',
    placeOfService: null,
  },
  {
    code: '207RI0001X',
    classification: 'INTERNAL MEDICINE CLINICA',
    codeClassification: '207RI0001X-INTERNAL MEDICINE CLINICA',
    placeOfService: null,
  },
  {
    code: '207RC0200X',
    classification: 'INTERNAL MEDICINE CRITICA',
    codeClassification: '207RC0200X-INTERNAL MEDICINE CRITICA',
    placeOfService: null,
  },
  {
    code: '207RE0101X',
    classification: 'INTERNAL MEDICINE ENDOCRI',
    codeClassification: '207RE0101X-INTERNAL MEDICINE ENDOCRI',
    placeOfService: null,
  },
  {
    code: '207RG0100X',
    classification: 'INTERNAL MEDICINE GASTROE',
    codeClassification: '207RG0100X-INTERNAL MEDICINE GASTROE',
    placeOfService: null,
  },
  {
    code: '207RG0300X',
    classification: 'INTERNAL MEDICINE GERIATR',
    codeClassification: '207RG0300X-INTERNAL MEDICINE GERIATR',
    placeOfService: null,
  },
  {
    code: '207RH0000X',
    classification: 'INTERNAL MEDICINE HEMATOL',
    codeClassification: '207RH0000X-INTERNAL MEDICINE HEMATOL',
    placeOfService: null,
  },
  {
    code: '207RH0003X',
    classification: 'INTERNAL MEDICINE HEMATOL',
    codeClassification: '207RH0003X-INTERNAL MEDICINE HEMATOL',
    placeOfService: null,
  },
  {
    code: '207RH0002X',
    classification: 'INTERNAL MEDICINE HOSP',
    codeClassification: '207RH0002X-INTERNAL MEDICINE HOSP',
    placeOfService: null,
  },
  {
    code: '207RI0200X',
    classification: 'INTERNAL MEDICINE INFECTI',
    codeClassification: '207RI0200X-INTERNAL MEDICINE INFECTI',
    placeOfService: null,
  },
  {
    code: '207RI0011X',
    classification: 'INTERNAL MEDICINE INTERVE',
    codeClassification: '207RI0011X-INTERNAL MEDICINE INTERVE',
    placeOfService: null,
  },
  {
    code: '207RM1200X',
    classification: 'INTERNAL MEDICINE MAGNETI',
    codeClassification: '207RM1200X-INTERNAL MEDICINE MAGNETI',
    placeOfService: null,
  },
  {
    code: '207RX0202X',
    classification: 'INTERNAL MEDICINE MEDICAL',
    codeClassification: '207RX0202X-INTERNAL MEDICINE MEDICAL',
    placeOfService: null,
  },
  {
    code: '207RN0300X',
    classification: 'INTERNAL MEDICINE NEPHROL',
    codeClassification: '207RN0300X-INTERNAL MEDICINE NEPHROL',
    placeOfService: null,
  },
  {
    code: '207RP1001X',
    classification: 'INTERNAL MEDICINE PULMONA',
    codeClassification: '207RP1001X-INTERNAL MEDICINE PULMONA',
    placeOfService: null,
  },
  {
    code: '207RR0500X',
    classification: 'INTERNAL MEDICINE RHEUMAT',
    codeClassification: '207RR0500X-INTERNAL MEDICINE RHEUMAT',
    placeOfService: null,
  },
  {
    code: '207RS0010X',
    classification: 'INTERNAL MEDICINE SPORTS',
    codeClassification: '207RS0010X-INTERNAL MEDICINE SPORTS',
    placeOfService: null,
  },
  {
    code: '208VP0014X',
    classification: 'INTERVENTIONAL PAIN MEDICINE',
    codeClassification: '208VP0014X-INTERVENTIONAL PAIN MEDICINE',
    placeOfService: null,
  },
  {
    code: '291U00000X',
    classification: 'LABORATORIES CLINICAL MED',
    codeClassification: '291U00000X-LABORATORIES CLINICAL MED',
    placeOfService: null,
  },
  {
    code: '292200000X',
    classification: 'LABORATORIES DENTAL LABOR',
    codeClassification: '292200000X-LABORATORIES DENTAL LABOR',
    placeOfService: null,
  },
  {
    code: '293D00000X',
    classification: 'LABORATORIES PHYSIOLOGICA',
    codeClassification: '293D00000X-LABORATORIES PHYSIOLOGICA',
    placeOfService: null,
    isProcedureCodeRequired: true,
  },
  {
    code: '291900000X',
    classification: 'LABORATORY',
    codeClassification: '291900000X-LABORATORY',
    placeOfService: null,
  },
  {
    code: '209800000X',
    classification: 'LEGAL MEDICINE',
    codeClassification: '209800000X-LEGAL MEDICINE',
    placeOfService: null,
  },
  {
    code: '164W00000X',
    classification: 'LICENSED PRACTICAL NURSE',
    codeClassification: '164W00000X-LICENSED PRACTICAL NURSE',
    placeOfService: null,
  },
  {
    code: '164X00000X',
    classification: 'LICENSED VOCATIONAL NURSE',
    codeClassification: '164X00000X-LICENSED VOCATIONAL NURSE',
    placeOfService: null,
  },
  {
    code: '282E00000X',
    classification: 'LONG TERM ACTE HOS',
    codeClassification: '282E00000X-LONG TERM ACTE HOS',
    placeOfService: null,
  },
  {
    code: '273Y00000X',
    classification: 'LONG TERM CARE HOSP',
    codeClassification: '273Y00000X-LONG TERM CARE HOSP',
    placeOfService: null,
  },
  {
    code: '282E00000X',
    classification: 'LONG TERM CARE HOSPITAL',
    codeClassification: '282E00000X-LONG TERM CARE HOSPITAL',
    placeOfService: null,
  },
  {
    code: '302F00000X',
    classification: 'MANAGED CARE ORG EPO',
    codeClassification: '302F00000X-MANAGED CARE ORG EPO',
    placeOfService: null,
  },
  {
    code: '302R00000X',
    classification: 'MANAGED CARE ORG HMO',
    codeClassification: '302R00000X-MANAGED CARE ORG HMO',
    placeOfService: null,
  },
  {
    code: '305S00000X',
    classification: 'MANAGED CARE ORG POS',
    codeClassification: '305S00000X-MANAGED CARE ORG POS',
    placeOfService: null,
  },
  {
    code: '106H00000X',
    classification: 'MARRIAGE AND FAMILY THERA',
    codeClassification: '106H00000X-MARRIAGE AND FAMILY THERA',
    placeOfService: null,
  },
  {
    code: '225700000X',
    classification: 'MASSAGE THERAPIST',
    codeClassification: '225700000X-MASSAGE THERAPIST',
    placeOfService: null,
  },
  {
    code: '207VM0101X',
    classification: 'MATERNAL AND FETAL MEDICI',
    codeClassification: '207VM0101X-MATERNAL AND FETAL MEDICI',
    placeOfService: null,
  },
  {
    code: '207SG0203X',
    classification: 'MED GEN BIOCHEM MOLECULAR',
    codeClassification: '207SG0203X-MED GEN BIOCHEM MOLECULAR',
    placeOfService: null,
  },
  {
    code: '170100000X',
    classification: 'MEDICAL GENETICS',
    codeClassification: '170100000X-MEDICAL GENETICS',
    placeOfService: null,
  },
  {
    code: '207SG0205X',
    classification: 'MEDICAL GENETICS',
    codeClassification: '207SG0205X-MEDICAL GENETICS',
    placeOfService: null,
  },
  {
    code: '207SM0001X',
    classification: 'MEDICAL GENETICS MOLECU',
    codeClassification: '207SM0001X-MEDICAL GENETICS MOLECU',
    placeOfService: null,
  },
  {
    code: '207SM0001X',
    classification: 'MEDICAL GENETICS MOLECULA',
    codeClassification: '207SM0001X-MEDICAL GENETICS MOLECULA',
    placeOfService: null,
  },
  {
    code: '207RX0202X',
    classification: 'MEDICAL ONCOLOGY',
    codeClassification: '207RX0202X-MEDICAL ONCOLOGY',
    placeOfService: null,
  },
  {
    code: '275N00000X',
    classification: 'MEDICARE DEFINED SWING BE',
    codeClassification: '275N00000X-MEDICARE DEFINED SWING BE',
    placeOfService: null,
  },
  {
    code: '261QM0801X',
    classification: 'MENTAL HEALTH CLINIC',
    codeClassification: '261QM0801X-MENTAL HEALTH CLINIC',
    placeOfService: null,
  },
  {
    code: '176B00000X',
    classification: 'MIDWIFE CERTIFIED',
    codeClassification: '176B00000X-MIDWIFE CERTIFIED',
    placeOfService: null,
  },
  {
    code: '367A00000X',
    classification: 'MIDWIFE CERTIFIED NURSE',
    codeClassification: '367A00000X-MIDWIFE CERTIFIED NURSE',
    placeOfService: null,
  },
  {
    code: '175M00000X',
    classification: 'MIDWIFE LAY',
    codeClassification: '175M00000X-MIDWIFE LAY',
    placeOfService: null,
  },
  {
    code: '332000000X',
    classification: 'MILITARY US COAST GUARD',
    codeClassification: '332000000X-MILITARY US COAST GUARD',
    placeOfService: null,
  },
  {
    code: '193200000X',
    classification: 'MULTI SPECIALTY GROUP',
    codeClassification: '193200000X-MULTI SPECIALTY GROUP',
    placeOfService: null,
  },
  {
    code: '172P00000X',
    classification: 'NAPRAPATH',
    codeClassification: '172P00000X-NAPRAPATH',
    placeOfService: null,
  },
  {
    code: '175F00000X',
    classification: 'NATUROPATH',
    codeClassification: '175F00000X-NATUROPATH',
    placeOfService: null,
  },
  {
    code: '2084A2900X',
    classification: 'NEUROCRITICAL CARE',
    codeClassification: '2084A2900X-NEUROCRITICAL CARE',
    placeOfService: null,
  },
  {
    code: '2084A2900X',
    classification: 'NEUROCRITICAL CARE GROUP',
    codeClassification: '2084A2900X-NEUROCRITICAL CARE GROUP',
    placeOfService: null,
  },
  {
    code: '207T00000X',
    classification: 'NEUROLOGICAL SURGERY',
    codeClassification: '207T00000X-NEUROLOGICAL SURGERY',
    placeOfService: null,
  },
  {
    code: '204C00000X',
    classification: 'NEUROMUSCULOSKELETAL MEDI',
    codeClassification: '204C00000X-NEUROMUSCULOSKELETAL MEDI',
    placeOfService: null,
  },
  {
    code: '204D00000X',
    classification: 'NEUROMUSCULOSKELETAL OMM',
    codeClassification: '204D00000X-NEUROMUSCULOSKELETAL OMM',
    placeOfService: null,
  },
  {
    code: '103G00000X',
    classification: 'NEUROPSYCHOLOGIST',
    codeClassification: '103G00000X-NEUROPSYCHOLOGIST',
    placeOfService: null,
  },
  {
    code: '363LA2100X',
    classification: 'NNRSE PRACTITIONERACUTE',
    codeClassification: '363LA2100X-NNRSE PRACTITIONERACUTE',
    placeOfService: null,
  },
  {
    code: '207UN0901X',
    classification: 'NUCLEAR CARDIOLOGY',
    codeClassification: '207UN0901X-NUCLEAR CARDIOLOGY',
    placeOfService: null,
  },
  {
    code: '207UN0902X',
    classification: 'NUCLEAR IMAGING AND THERAPY',
    codeClassification: '207UN0902X-NUCLEAR IMAGING AND THERAPY',
    placeOfService: null,
  },
  {
    code: '207U00000X',
    classification: 'NUCLEAR MEDICINE',
    codeClassification: '207U00000X-NUCLEAR MEDICINE',
    placeOfService: null,
  },
  {
    code: '207UN0903X',
    classification: 'NUCLEAR MEDICINE IN VIVO',
    codeClassification: '207UN0903X-NUCLEAR MEDICINE IN VIVO',
    placeOfService: null,
  },
  {
    code: '2085N0904X',
    classification: 'NUCLEAR RADIOLOGY',
    codeClassification: '2085N0904X-NUCLEAR RADIOLOGY',
    placeOfService: null,
  },
  {
    code: '367500000X',
    classification: 'NURSE ANESTHETIST CERTIF',
    codeClassification: '367500000X-NURSE ANESTHETIST CERTIF',
    placeOfService: null,
  },
  {
    code: '363LC0200X',
    classification: 'NURSE PRACT CRITICAL CARE',
    codeClassification: '363LC0200X-NURSE PRACT CRITICAL CARE',
    placeOfService: null,
  },
  {
    code: '363LP0200X',
    classification: 'NURSE PRACTIONER PEDIATRI',
    codeClassification: '363LP0200X-NURSE PRACTIONER PEDIATRI',
    placeOfService: null,
  },
  {
    code: '363L00000X',
    classification: 'NURSE PRACTITIONER',
    codeClassification: '363L00000X-NURSE PRACTITIONER',
    placeOfService: null,
  },
  {
    code: '363LP1700X',
    classification: 'NURSE PRACTITIONER',
    codeClassification: '363LP1700X-NURSE PRACTITIONER',
    placeOfService: null,
  },
  {
    code: '363LX0106X',
    classification: 'NURSE PRACTITIONER',
    codeClassification: '363LX0106X-NURSE PRACTITIONER',
    placeOfService: null,
  },
  {
    code: '363LA2200X',
    classification: 'NURSE PRACTITIONER',
    codeClassification: '363LA2200X-NURSE PRACTITIONER',
    placeOfService: null,
  },
  {
    code: '363LW0102X',
    classification: 'NURSE PRACTITIONERWOMEN',
    codeClassification: '363LW0102X-NURSE PRACTITIONERWOMEN',
    placeOfService: null,
  },
  {
    code: '363LA2100X',
    classification: 'NURSE PRACTITIONER ACUTE',
    codeClassification: '363LA2100X-NURSE PRACTITIONER ACUTE',
    placeOfService: null,
  },
  {
    code: '363LA2200X',
    classification: 'NURSE PRACTITIONER ADULT',
    codeClassification: '363LA2200X-NURSE PRACTITIONER ADULT',
    placeOfService: null,
  },
  {
    code: '363LC1500X',
    classification: 'NURSE PRACTITIONER COMMUN',
    codeClassification: '363LC1500X-NURSE PRACTITIONER COMMUN',
    placeOfService: null,
  },
  {
    code: '363LC0200X',
    classification: 'NURSE PRACTITIONER CRITIC',
    codeClassification: '363LC0200X-NURSE PRACTITIONER CRITIC',
    placeOfService: null,
  },
  {
    code: '363LF0000X',
    classification: 'NURSE PRACTITIONER FAMILY',
    codeClassification: '363LF0000X-NURSE PRACTITIONER FAMILY',
    placeOfService: null,
  },
  {
    code: '363LG0600X',
    classification: 'NURSE PRACTITIONER GERONT',
    codeClassification: '363LG0600X-NURSE PRACTITIONER GERONT',
    placeOfService: null,
  },
  {
    code: '363LN0005X',
    classification: 'NURSE PRACTITIONER NEO CRIT',
    codeClassification: '363LN0005X-NURSE PRACTITIONER NEO CRIT',
    placeOfService: null,
  },
  {
    code: '363LN0000X',
    classification: 'NURSE PRACTITIONER NEONAT',
    codeClassification: '363LN0000X-NURSE PRACTITIONER NEONAT',
    placeOfService: null,
  },
  {
    code: '363LX0001X',
    classification: 'NURSE PRACTITIONER OBSTET',
    codeClassification: '363LX0001X-NURSE PRACTITIONER OBSTET',
    placeOfService: null,
  },
  {
    code: '363LX0106X',
    classification: 'NURSE PRACTITIONER OCCUPA',
    codeClassification: '363LX0106X-NURSE PRACTITIONER OCCUPA',
    placeOfService: null,
  },
  {
    code: '363LP0200X',
    classification: 'NURSE PRACTITIONER PEDIATRI',
    codeClassification: '363LP0200X-NURSE PRACTITIONER PEDIATRI',
    placeOfService: null,
  },
  {
    code: '363LP1700X',
    classification: 'NURSE PRACTITIONER PERINATA',
    codeClassification: '363LP1700X-NURSE PRACTITIONER PERINATA',
    placeOfService: null,
  },
  {
    code: '363LP2300X',
    classification: 'NURSE PRACTITIONER PRIMAR',
    codeClassification: '363LP2300X-NURSE PRACTITIONER PRIMAR',
    placeOfService: null,
  },
  {
    code: '363LP0808X',
    classification: 'NURSE PRACTITIONER PSYCHI',
    codeClassification: '363LP0808X-NURSE PRACTITIONER PSYCHI',
    placeOfService: null,
  },
  {
    code: '363LS0200X',
    classification: 'NURSE PRACTITIONER SCHOOL',
    codeClassification: '363LS0200X-NURSE PRACTITIONER SCHOOL',
    placeOfService: null,
  },
  {
    code: '363LW0102X',
    classification: 'NURSE PRACTITIONER WOMEN',
    codeClassification: '363LW0102X-NURSE PRACTITIONER WOMEN',
    placeOfService: null,
  },
  {
    code: '310400000X',
    classification: 'NURSING AND CUSTODIAL CAR',
    codeClassification: '310400000X-NURSING AND CUSTODIAL CAR',
    placeOfService: null,
  },
  {
    code: '3140N1450X',
    classification: 'NURSING CARE PEDIATRIC',
    codeClassification: '3140N1450X-NURSING CARE PEDIATRIC',
    placeOfService: null,
  },
  {
    code: '313M00000X',
    classification: 'NURSING FACILITY INTERMED',
    codeClassification: '313M00000X-NURSING FACILITY INTERMED',
    placeOfService: null,
  },
  {
    code: '372600000X',
    classification: 'NURSING RELATED ADULT COMPANION',
    codeClassification: '372600000X-NURSING RELATED ADULT COMPANION',
    placeOfService: null,
  },
  {
    code: '372500000X',
    classification: 'NURSING RELATED CHORE PROVIDER',
    codeClassification: '372500000X-NURSING RELATED CHORE PROVIDER',
    placeOfService: null,
  },
  {
    code: '373H00000X',
    classification: 'NURSING RELATED DAY TRAINING',
    codeClassification: '373H00000X-NURSING RELATED DAY TRAINING',
    placeOfService: null,
  },
  {
    code: '374U00000X',
    classification: 'NURSING RELATED HOME HEALTH AIDE',
    codeClassification: '374U00000X-NURSING RELATED HOME HEALTH AIDE',
    placeOfService: null,
  },
  {
    code: '374T00000X',
    classification: 'NURSING RELATED RELIGIOUS',
    codeClassification: '374T00000X-NURSING RELATED RELIGIOUS',
    placeOfService: null,
  },
  {
    code: '374700000X',
    classification: 'NURSING RELATED TECHNICIA',
    codeClassification: '374700000X-NURSING RELATED TECHNICIA',
    placeOfService: null,
  },
  {
    code: '163WH0200X',
    classification: 'NURSING SERVICE PROVIDERS',
    codeClassification: '163WH0200X-NURSING SERVICE PROVIDERS',
    placeOfService: null,
  },
  {
    code: '163WL0100X',
    classification: 'NURSING SERVICE PROVIDERS',
    codeClassification: '163WL0100X-NURSING SERVICE PROVIDERS',
    placeOfService: null,
  },
  {
    code: '163WP0808X',
    classification: 'NURSING SERVICE PROVIDERS',
    codeClassification: '163WP0808X-NURSING SERVICE PROVIDERS',
    placeOfService: null,
  },
  {
    code: '164W00000X',
    classification: 'NURSING SERVICE PROVIDERS',
    codeClassification: '164W00000X-NURSING SERVICE PROVIDERS',
    placeOfService: null,
  },
  {
    code: '133N00000X',
    classification: 'NUTRITIONIST',
    codeClassification: '133N00000X-NUTRITIONIST',
    placeOfService: null,
  },
  {
    code: '133NN1002X',
    classification: 'NUTRITIONIST NUTRITIONE',
    codeClassification: '133NN1002X-NUTRITIONIST NUTRITIONE',
    placeOfService: null,
  },
  {
    code: '207VH0002X',
    classification: 'OB GYN HOSPI PAL MED',
    codeClassification: '207VH0002X-OB GYN HOSPI PAL MED',
    placeOfService: null,
  },
  {
    code: '207VB0002X',
    classification: 'OB GYN OBESITY MEDICINE',
    codeClassification: '207VB0002X-OB GYN OBESITY MEDICINE',
    placeOfService: null,
  },
  {
    code: '207VX0000X',
    classification: 'OBSTETRICS',
    codeClassification: '207VX0000X-OBSTETRICS',
    placeOfService: null,
  },
  {
    code: '207VC0200X',
    classification: 'OBSTETRICS AND GYNECOLOGY',
    codeClassification: '207VC0200X-OBSTETRICS AND GYNECOLOGY',
    placeOfService: null,
  },
  {
    code: '224ZR0403X',
    classification: 'OCCU THERAPY DRIVING AND',
    codeClassification: '224ZR0403X-OCCU THERAPY DRIVING AND',
    placeOfService: null,
  },
  {
    code: '224ZE0001X',
    classification: 'OCCU THERAPY ENVIRONMENTA',
    codeClassification: '224ZE0001X-OCCU THERAPY ENVIRONMENTA',
    placeOfService: null,
  },
  {
    code: '225XE0001X',
    classification: 'OCCU THERAPY ENVIRONMENTA',
    codeClassification: '225XE0001X-OCCU THERAPY ENVIRONMENTA',
    placeOfService: null,
  },
  {
    code: '224ZF0002X',
    classification: 'OCCU THERAPY FEED EAT',
    codeClassification: '224ZF0002X-OCCU THERAPY FEED EAT',
    placeOfService: null,
  },
  {
    code: '225XF0002X',
    classification: 'OCCU THERAPY FEED EAT',
    codeClassification: '225XF0002X-OCCU THERAPY FEED EAT',
    placeOfService: null,
  },
  {
    code: '225XG0600X',
    classification: 'OCCU THERAPY GERONTOLOGY',
    codeClassification: '225XG0600X-OCCU THERAPY GERONTOLOGY',
    placeOfService: null,
  },
  {
    code: '224ZL0004X',
    classification: 'OCCU THERAPY LOW VISION',
    codeClassification: '224ZL0004X-OCCU THERAPY LOW VISION',
    placeOfService: null,
  },
  {
    code: '225XL0004X',
    classification: 'OCCU THERAPY LOW VISION',
    codeClassification: '225XL0004X-OCCU THERAPY LOW VISION',
    placeOfService: null,
  },
  {
    code: '225XM0800X',
    classification: 'OCCU THERAPY MENTAL HEALT',
    codeClassification: '225XM0800X-OCCU THERAPY MENTAL HEALT',
    placeOfService: null,
  },
  {
    code: '225XP0019X',
    classification: 'OCCU THERAPY PHYSICAL REH',
    codeClassification: '225XP0019X-OCCU THERAPY PHYSICAL REH',
    placeOfService: null,
  },
  {
    code: '225X00000X',
    classification: 'OCCUPATIONAL THERAPIST',
    codeClassification: '225X00000X-OCCUPATIONAL THERAPIST',
    placeOfService: null,
  },
  {
    code: '225XE1200X',
    classification: 'OCCUPATIONAL THERAPY ERGO',
    codeClassification: '225XE1200X-OCCUPATIONAL THERAPY ERGO',
    placeOfService: null,
  },
  {
    code: '225XH1200X',
    classification: 'OCCUPATIONAL THERAPY HAND',
    codeClassification: '225XH1200X-OCCUPATIONAL THERAPY HAND',
    placeOfService: null,
  },
  {
    code: '225XH1300X',
    classification: 'OCCUPATIONAL THERAPY HUMA',
    codeClassification: '225XH1300X-OCCUPATIONAL THERAPY HUMA',
    placeOfService: null,
  },
  {
    code: '225XN1300X',
    classification: 'OCCUPATIONAL THERAPY NEUR',
    codeClassification: '225XN1300X-OCCUPATIONAL THERAPY NEUR',
    placeOfService: null,
  },
  {
    code: '224Z00000X',
    classification: 'OCCUPATIONAL THERAPY OCCU',
    codeClassification: '224Z00000X-OCCUPATIONAL THERAPY OCCU',
    placeOfService: null,
  },
  {
    code: '225XP0200X',
    classification: 'OCCUPATIONAL THERAPY PEDI',
    codeClassification: '225XP0200X-OCCUPATIONAL THERAPY PEDI',
    placeOfService: null,
  },
  {
    code: '225XR0403X',
    classification: 'OCCUPATIONAL THERAPY REHA',
    codeClassification: '225XR0403X-OCCUPATIONAL THERAPY REHA',
    placeOfService: null,
  },
  {
    code: '156FX1700X',
    classification: 'OCULARIST',
    codeClassification: '156FX1700X-OCULARIST',
    placeOfService: null,
  },
  {
    code: '156FX1101X',
    classification: 'OPHTHALMIC ASSISTANT',
    codeClassification: '156FX1101X-OPHTHALMIC ASSISTANT',
    placeOfService: null,
  },
  {
    code: '156FX1100X',
    classification: 'OPHTHALMIC TECHNOLOGIST',
    codeClassification: '156FX1100X-OPHTHALMIC TECHNOLOGIST',
    placeOfService: null,
  },
  {
    code: '207W00000X',
    classification: 'OPHTHALMOLOGY',
    codeClassification: '207W00000X-OPHTHALMOLOGY',
    placeOfService: null,
  },
  {
    code: '156FX1800X',
    classification: 'OPTICIAN',
    codeClassification: '156FX1800X-OPTICIAN',
    placeOfService: null,
  },
  {
    code: '156FX1201X',
    classification: 'OPTOMETRIC ASSISTANT',
    codeClassification: '156FX1201X-OPTOMETRIC ASSISTANT',
    placeOfService: null,
  },
  {
    code: '156FX1202X',
    classification: 'OPTOMETRIC TECHNICIAN',
    codeClassification: '156FX1202X-OPTOMETRIC TECHNICIAN',
    placeOfService: null,
  },
  {
    code: '152W00000X',
    classification: 'OPTOMETRIST',
    codeClassification: '152W00000X-OPTOMETRIST',
    placeOfService: null,
  },
  {
    code: '152WC0802X',
    classification: 'OPTOMETRIST CORNEAL AND C',
    codeClassification: '152WC0802X-OPTOMETRIST CORNEAL AND C',
    placeOfService: null,
  },
  {
    code: '152WL0500X',
    classification: 'OPTOMETRIST LOW VISION RE',
    codeClassification: '152WL0500X-OPTOMETRIST LOW VISION RE',
    placeOfService: null,
  },
  {
    code: '152WX0102X',
    classification: 'OPTOMETRIST OCCUPATIONAL',
    codeClassification: '152WX0102X-OPTOMETRIST OCCUPATIONAL',
    placeOfService: null,
  },
  {
    code: '152WP0200X',
    classification: 'OPTOMETRIST PEDIATRICS',
    codeClassification: '152WP0200X-OPTOMETRIST PEDIATRICS',
    placeOfService: null,
  },
  {
    code: '152WS0006X',
    classification: 'OPTOMETRIST SPORTS VISION',
    codeClassification: '152WS0006X-OPTOMETRIST SPORTS VISION',
    placeOfService: null,
  },
  {
    code: '152WV0400X',
    classification: 'OPTOMETRIST VISION THERAP',
    codeClassification: '152WV0400X-OPTOMETRIST VISION THERAP',
    placeOfService: null,
  },
  {
    code: '204E00000X',
    classification: 'ORAL AND MAXILLOFACIAL SU',
    codeClassification: '204E00000X-ORAL AND MAXILLOFACIAL SU',
    placeOfService: null,
  },
  {
    code: '1223S0112X',
    classification: 'ORAL AND MAXILLOFACIAL SURGERY',
    codeClassification: '1223S0112X-ORAL AND MAXILLOFACIAL SURGERY',
    placeOfService: null,
  },
  {
    code: '246ZX2200X',
    classification: 'ORTHO ASST',
    codeClassification: '246ZX2200X-ORTHO ASST',
    placeOfService: null,
  },
  {
    code: '207XP3100X',
    classification: 'ORTHO HAND SURGERY',
    codeClassification: '207XP3100X-ORTHO HAND SURGERY',
    placeOfService: null,
  },
  {
    code: '207X00000X',
    classification: 'ORTHOPAEDIC SURGERY',
    codeClassification: '207X00000X-ORTHOPAEDIC SURGERY',
    placeOfService: null,
  },
  {
    code: '207XS0114X',
    classification: 'ORTHOPAEDIC SURGERY ADULT',
    codeClassification: '207XS0114X-ORTHOPAEDIC SURGERY ADULT',
    placeOfService: null,
  },
  {
    code: '207XX0004X',
    classification: 'ORTHOPAEDIC SURGERY FOOT',
    codeClassification: '207XX0004X-ORTHOPAEDIC SURGERY FOOT',
    placeOfService: null,
  },
  {
    code: '207XS0117X',
    classification: 'ORTHOPAEDIC SURGERY ORTHO',
    codeClassification: '207XS0117X-ORTHOPAEDIC SURGERY ORTHO',
    placeOfService: null,
  },
  {
    code: '207XX0801X',
    classification: 'ORTHOPAEDIC SURGERY ORTHO',
    codeClassification: '207XX0801X-ORTHOPAEDIC SURGERY ORTHO',
    placeOfService: null,
  },
  {
    code: '207XX0005X',
    classification: 'ORTHOPAEDIC SURGERY SPORT',
    codeClassification: '207XX0005X-ORTHOPAEDIC SURGERY SPORT',
    placeOfService: null,
  },
  {
    code: '156FX1900X',
    classification: 'ORTHOPTIST',
    codeClassification: '156FX1900X-ORTHOPTIST',
    placeOfService: null,
  },
  {
    code: '207XX0005X',
    classification: 'ORTHOSURG SPORTS MED',
    codeClassification: '207XX0005X-ORTHOSURG SPORTS MED',
    placeOfService: null,
  },
  {
    code: '171100000X',
    classification: 'OTHER SERVICE PROVIDERS A',
    codeClassification: '171100000X-OTHER SERVICE PROVIDERS A',
    placeOfService: null,
  },
  {
    code: '175L00000X',
    classification: 'OTHER SERVICE PROVIDERS H',
    codeClassification: '175L00000X-OTHER SERVICE PROVIDERS H',
    placeOfService: null,
  },
  {
    code: '173000000X',
    classification: 'OTHER SERVICE PROVIDERS L',
    codeClassification: '173000000X-OTHER SERVICE PROVIDERS L',
    placeOfService: null,
  },
  {
    code: '176B00000X',
    classification: 'OTHER SERVICE PROVIDERS M',
    codeClassification: '176B00000X-OTHER SERVICE PROVIDERS M',
    placeOfService: null,
  },
  {
    code: '171W00000X',
    classification: 'OTHER SVC CONTRACTOR',
    codeClassification: '171W00000X-OTHER SVC CONTRACTOR',
    placeOfService: null,
  },
  {
    code: '174N00000X',
    classification: 'OTHER SVCLACTATION NONRN',
    codeClassification: '174N00000X-OTHER SVCLACTATION NONRN',
    placeOfService: null,
  },
  {
    code: '207Y00000X',
    classification: 'OTOLARYNGOLOGY',
    codeClassification: '207Y00000X-OTOLARYNGOLOGY',
    placeOfService: null,
  },
  {
    code: '207YS0123X',
    classification: 'OTOLARYNGOLOGY FACIAL PLA',
    codeClassification: '207YS0123X-OTOLARYNGOLOGY FACIAL PLA',
    placeOfService: null,
  },
  {
    code: '207YX0602X',
    classification: 'OTOLARYNGOLOGY OTOLARYNGI',
    codeClassification: '207YX0602X-OTOLARYNGOLOGY OTOLARYNGI',
    placeOfService: null,
  },
  {
    code: '207YP0228X',
    classification: 'OTOLARYNGOLOGY PEDIATRIC',
    codeClassification: '207YP0228X-OTOLARYNGOLOGY PEDIATRIC',
    placeOfService: null,
  },
  {
    code: '207YX0007X',
    classification: 'OTOLARYNGOLOGY PLASTIC SU',
    codeClassification: '207YX0007X-OTOLARYNGOLOGY PLASTIC SU',
    placeOfService: null,
  },
  {
    code: '207YS0012X',
    classification: 'OTOLARYNGOLOGY SLEEP MED',
    codeClassification: '207YS0012X-OTOLARYNGOLOGY SLEEP MED',
    placeOfService: null,
  },
  {
    code: '207YX0901X',
    classification: 'OTOLOGY AND NEUROTOLOGY',
    codeClassification: '207YX0901X-OTOLOGY AND NEUROTOLOGY',
    placeOfService: null,
  },
  {
    code: '207LP2900X',
    classification: 'PAIN MEDICINE',
    codeClassification: '207LP2900X-PAIN MEDICINE',
    placeOfService: null,
  },
  {
    code: '2081P2900X',
    classification: 'PAIN MEDICINE',
    codeClassification: '2081P2900X-PAIN MEDICINE',
    placeOfService: null,
  },
  {
    code: '2084P2900X',
    classification: 'PAIN MEDICINE',
    codeClassification: '2084P2900X-PAIN MEDICINE',
    placeOfService: null,
  },
  {
    code: '208VP0000X',
    classification: 'PAIN MEDICINE',
    codeClassification: '208VP0000X-PAIN MEDICINE',
    placeOfService: null,
  },
  {
    code: '332BP3500X',
    classification: 'PARENTERAL ENTERAL NUTRITION',
    codeClassification: '332BP3500X-PARENTERAL ENTERAL NUTRITION',
    placeOfService: null,
  },
  {
    code: '207ZP0105X',
    classification: 'PATH CLINICAL PATH LAB M',
    codeClassification: '207ZP0105X-PATH CLINICAL PATH LAB M',
    placeOfService: null,
  },
  {
    code: '207ZF0201X',
    classification: 'PATHOLOGY FORENSIC PATH',
    codeClassification: '207ZF0201X-PATHOLOGY FORENSIC PATH',
    placeOfService: null,
  },
  {
    code: '207ZP0101X',
    classification: 'PATHOLOGY ANATOMIC PATHOL',
    codeClassification: '207ZP0101X-PATHOLOGY ANATOMIC PATHOL',
    placeOfService: null,
  },
  {
    code: '207ZP0102X',
    classification: 'PATHOLOGY ANATOMIC PATHOL',
    codeClassification: '207ZP0102X-PATHOLOGY ANATOMIC PATHOL',
    placeOfService: null,
  },
  {
    code: '207ZP0104X',
    classification: 'PATHOLOGY CHEMICAL PATHOL',
    codeClassification: '207ZP0104X-PATHOLOGY CHEMICAL PATHOL',
    placeOfService: null,
  },
  {
    code: '207ZC0006X',
    classification: 'PATHOLOGY CLINICAL PATH',
    codeClassification: '207ZC0006X-PATHOLOGY CLINICAL PATH',
    placeOfService: null,
  },
  {
    code: '207ZC0500X',
    classification: 'PATHOLOGY CYTOPATHOLOGY',
    codeClassification: '207ZC0500X-PATHOLOGY CYTOPATHOLOGY',
    placeOfService: null,
  },
  {
    code: '207ZH0000X',
    classification: 'PATHOLOGY HEMATOLOGY',
    codeClassification: '207ZH0000X-PATHOLOGY HEMATOLOGY',
    placeOfService: null,
  },
  {
    code: '207ZI0100X',
    classification: 'PATHOLOGY IMMUNOPATHOLOGY',
    codeClassification: '207ZI0100X-PATHOLOGY IMMUNOPATHOLOGY',
    placeOfService: null,
  },
  {
    code: '207ZM0300X',
    classification: 'PATHOLOGY MEDICAL MICROBI',
    codeClassification: '207ZM0300X-PATHOLOGY MEDICAL MICROBI',
    placeOfService: null,
  },
  {
    code: '207ZP0007X',
    classification: 'PATHOLOGY MOLECULAR GENET',
    codeClassification: '207ZP0007X-PATHOLOGY MOLECULAR GENET',
    placeOfService: null,
  },
  {
    code: '207ZN0500X',
    classification: 'PATHOLOGY NEUROPATHOLOGY',
    codeClassification: '207ZN0500X-PATHOLOGY NEUROPATHOLOGY',
    placeOfService: null,
  },
  {
    code: '207ZP0213X',
    classification: 'PATHOLOGY PEDIATRIC PATHO',
    codeClassification: '207ZP0213X-PATHOLOGY PEDIATRIC PATHO',
    placeOfService: null,
  },
  {
    code: '207LP3000X',
    classification: 'PED ANESTHESIOLOGY',
    codeClassification: '207LP3000X-PED ANESTHESIOLOGY',
    placeOfService: null,
  },
  {
    code: '2080T0004X',
    classification: 'PED TRANSPLANT HEPATOLOGY',
    codeClassification: '2080T0004X-PED TRANSPLANT HEPATOLOGY',
    placeOfService: null,
  },
  {
    code: '2080C0008X',
    classification: 'PEDIATRICCHILD ABUSE',
    codeClassification: '2080C0008X-PEDIATRICCHILD ABUSE',
    placeOfService: null,
  },
  {
    code: '2080P0201X',
    classification: 'PEDIATRIC ALLERGYIMMU',
    codeClassification: '2080P0201X-PEDIATRIC ALLERGYIMMU',
    placeOfService: null,
  },
  {
    code: '111NP0017X',
    classification: 'PEDIATRIC CHIROPRACTOR',
    codeClassification: '111NP0017X-PEDIATRIC CHIROPRACTOR',
    placeOfService: null,
  },
  {
    code: '207NP0225X',
    classification: 'PEDIATRIC DERMATOLOGY',
    codeClassification: '207NP0225X-PEDIATRIC DERMATOLOGY',
    placeOfService: null,
  },
  {
    code: '2080H0002X',
    classification: 'PEDIATRIC HOSPPAL MED',
    codeClassification: '2080H0002X-PEDIATRIC HOSPPAL MED',
    placeOfService: null,
  },
  {
    code: '2080B0002X',
    classification: 'PEDIATRIC OBESITY MEDICINE',
    codeClassification: '2080B0002X-PEDIATRIC OBESITY MEDICINE',
    placeOfService: null,
  },
  {
    code: '2080P0208X',
    classification: 'PEDIATRIC PEDIATRIC INFEC',
    codeClassification: '2080P0208X-PEDIATRIC PEDIATRIC INFEC',
    placeOfService: null,
  },
  {
    code: '2080P0210X',
    classification: 'PEDIATRIC PEDIATRIC NEPHR',
    codeClassification: '2080P0210X-PEDIATRIC PEDIATRIC NEPHR',
    placeOfService: null,
  },
  {
    code: '2080P0214X',
    classification: 'PEDIATRIC PEDIATRIC PULMO',
    codeClassification: '2080P0214X-PEDIATRIC PEDIATRIC PULMO',
    placeOfService: null,
  },
  {
    code: '2080S0012X',
    classification: 'PEDIATRIC SLEEP MEDICINE',
    codeClassification: '2080S0012X-PEDIATRIC SLEEP MEDICINE',
    placeOfService: null,
  },
  {
    code: '3140N1450X',
    classification: 'PEDIATRIC SNF',
    codeClassification: '3140N1450X-PEDIATRIC SNF',
    placeOfService: null,
  },
  {
    code: '208000000X',
    classification: 'PEDIATRICS',
    codeClassification: '208000000X-PEDIATRICS',
    placeOfService: null,
  },
  {
    code: '2080P0008X',
    classification: 'PEDIATRICS NEURODEVELOP',
    codeClassification: '2080P0008X-PEDIATRICS NEURODEVELOP',
    placeOfService: null,
  },
  {
    code: '2080A0000X',
    classification: 'PEDIATRICS ADOLESCENT MED',
    codeClassification: '2080A0000X-PEDIATRICS ADOLESCENT MED',
    placeOfService: null,
  },
  {
    code: '2080I0007X',
    classification: 'PEDIATRICS CLINICAL AND L',
    codeClassification: '2080I0007X-PEDIATRICS CLINICAL AND L',
    placeOfService: null,
  },
  {
    code: '2080P0006X',
    classification: 'PEDIATRICS DEVELOPMENTAL',
    codeClassification: '2080P0006X-PEDIATRICS DEVELOPMENTAL',
    placeOfService: null,
  },
  {
    code: '2080T0002X',
    classification: 'PEDIATRICS MEDICAL TOXICO',
    codeClassification: '2080T0002X-PEDIATRICS MEDICAL TOXICO',
    placeOfService: null,
  },
  {
    code: '2080N0001X',
    classification: 'PEDIATRICS NEONATAL PERIN',
    codeClassification: '2080N0001X-PEDIATRICS NEONATAL PERIN',
    placeOfService: null,
  },
  {
    code: '2080P0008X',
    classification: 'PEDIATRICS NEURODEVELOPME',
    codeClassification: '2080P0008X-PEDIATRICS NEURODEVELOPME',
    placeOfService: null,
  },
  {
    code: '2080P0202X',
    classification: 'PEDIATRICS PEDIATRIC CARD',
    codeClassification: '2080P0202X-PEDIATRICS PEDIATRIC CARD',
    placeOfService: null,
  },
  {
    code: '2080P0204X',
    classification: 'PEDIATRICS PEDIATRIC EMER',
    codeClassification: '2080P0204X-PEDIATRICS PEDIATRIC EMER',
    placeOfService: null,
  },
  {
    code: '2080P0205X',
    classification: 'PEDIATRICS PEDIATRIC ENDO',
    codeClassification: '2080P0205X-PEDIATRICS PEDIATRIC ENDO',
    placeOfService: null,
  },
  {
    code: '2080P0206X',
    classification: 'PEDIATRICS PEDIATRIC GAST',
    codeClassification: '2080P0206X-PEDIATRICS PEDIATRIC GAST',
    placeOfService: null,
  },
  {
    code: '2080P0207X',
    classification: 'PEDIATRICS PEDIATRIC HEMA',
    codeClassification: '2080P0207X-PEDIATRICS PEDIATRIC HEMA',
    placeOfService: null,
  },
  {
    code: '2080P0216X',
    classification: 'PEDIATRICS PEDIATRIC RHEU',
    codeClassification: '2080P0216X-PEDIATRICS PEDIATRIC RHEU',
    placeOfService: null,
  },
  {
    code: '2080S0010X',
    classification: 'PEDIATRICS SPORTS MEDICIN',
    codeClassification: '2080S0010X-PEDIATRICS SPORTS MEDICIN',
    placeOfService: null,
  },
  {
    code: '175T00000X',
    classification: 'PEER SPECIALIST',
    codeClassification: '175T00000X-PEER SPECIALIST',
    placeOfService: null,
  },
  {
    code: '183500000X',
    classification: 'PHARMACIST',
    codeClassification: '183500000X-PHARMACIST',
    placeOfService: null,
  },
  {
    code: '1835P2201X',
    classification: 'PHARMACIST AMBULATORY CARE',
    codeClassification: '1835P2201X-PHARMACIST AMBULATORY CARE',
    placeOfService: null,
  },
  {
    code: '1835P0018X',
    classification: 'PHARMACIST CLINICIAN',
    codeClassification: '1835P0018X-PHARMACIST CLINICIAN',
    placeOfService: null,
  },
  {
    code: '1835C0205X',
    classification: 'PHARMACIST CRITICAL CARE',
    codeClassification: '1835C0205X-PHARMACIST CRITICAL CARE',
    placeOfService: null,
  },
  {
    code: '1835G0303X',
    classification: 'PHARMACIST GERIATRIC',
    codeClassification: '1835G0303X-PHARMACIST GERIATRIC',
    placeOfService: null,
  },
  {
    code: '1835N0905X',
    classification: 'PHARMACIST NUCLEAR',
    codeClassification: '1835N0905X-PHARMACIST NUCLEAR',
    placeOfService: null,
  },
  {
    code: '1835N1003X',
    classification: 'PHARMACIST NUTRITION SUPP',
    codeClassification: '1835N1003X-PHARMACIST NUTRITION SUPP',
    placeOfService: null,
  },
  {
    code: '1835X0200X',
    classification: 'PHARMACIST ONCOLOGY',
    codeClassification: '1835X0200X-PHARMACIST ONCOLOGY',
    placeOfService: null,
  },
  {
    code: '1835P0200X',
    classification: 'PHARMACIST PEDIATRICS',
    codeClassification: '1835P0200X-PHARMACIST PEDIATRICS',
    placeOfService: null,
  },
  {
    code: '1835P1300X',
    classification: 'PHARMACIST PSYCHIATRIC',
    codeClassification: '1835P1300X-PHARMACIST PSYCHIATRIC',
    placeOfService: null,
  },
  {
    code: '1835P1200X',
    classification: 'PHARMACOTHERAPY',
    codeClassification: '1835P1200X-PHARMACOTHERAPY',
    placeOfService: null,
  },
  {
    code: '183700000X',
    classification: 'PHARMACY TECHNICIAN',
    codeClassification: '183700000X-PHARMACY TECHNICIAN',
    placeOfService: null,
  },
  {
    code: '202K00000X',
    classification: 'PHLEBOLOGY',
    codeClassification: '202K00000X-PHLEBOLOGY',
    placeOfService: null,
  },
  {
    code: '363AM0700X',
    classification: 'PHYISCIAN ASSISTANT',
    codeClassification: '363AM0700X-PHYISCIAN ASSISTANT',
    placeOfService: null,
  },
  {
    code: '2081H0002X',
    classification: 'PHYSICAL MEDREHAB HOS',
    codeClassification: '2081H0002X-PHYSICAL MEDREHAB HOS',
    placeOfService: null,
  },
  {
    code: '2081N0008X',
    classification: 'PHYSICAL MEDREHAB NEU',
    codeClassification: '2081N0008X-PHYSICAL MEDREHAB NEU',
    placeOfService: null,
  },
  {
    code: '208100000X',
    classification: 'PHYSICAL MEDICINE AND REH',
    codeClassification: '208100000X-PHYSICAL MEDICINE AND REH',
    placeOfService: null,
  },
  {
    code: '2081P0004X',
    classification: 'PHYSICAL MEDICINE AND REH',
    codeClassification: '2081P0004X-PHYSICAL MEDICINE AND REH',
    placeOfService: null,
  },
  {
    code: '2081S0010X',
    classification: 'PHYSICAL MEDICINE AND REH',
    codeClassification: '2081S0010X-PHYSICAL MEDICINE AND REH',
    placeOfService: null,
  },
  {
    code: '2251C2600X',
    classification: 'PHYSICAL THERAPIST CARDIO',
    codeClassification: '2251C2600X-PHYSICAL THERAPIST CARDIO',
    placeOfService: null,
  },
  {
    code: '2251E1300X',
    classification: 'PHYSICAL THERAPIST ELECTR',
    codeClassification: '2251E1300X-PHYSICAL THERAPIST ELECTR',
    placeOfService: null,
  },
  {
    code: '2251E1200X',
    classification: 'PHYSICAL THERAPIST ERGONO',
    codeClassification: '2251E1200X-PHYSICAL THERAPIST ERGONO',
    placeOfService: null,
  },
  {
    code: '2251G0304X',
    classification: 'PHYSICAL THERAPIST GERIAT',
    codeClassification: '2251G0304X-PHYSICAL THERAPIST GERIAT',
    placeOfService: null,
  },
  {
    code: '2251H1300X',
    classification: 'PHYSICAL THERAPIST HUMAN',
    codeClassification: '2251H1300X-PHYSICAL THERAPIST HUMAN',
    placeOfService: null,
  },
  {
    code: '2251N0400X',
    classification: 'PHYSICAL THERAPIST NEUROL',
    codeClassification: '2251N0400X-PHYSICAL THERAPIST NEUROL',
    placeOfService: null,
  },
  {
    code: '2251P0200X',
    classification: 'PHYSICAL THERAPIST PEDIAT',
    codeClassification: '2251P0200X-PHYSICAL THERAPIST PEDIAT',
    placeOfService: null,
  },
  {
    code: '225200000X',
    classification: 'PHYSICAL THERAPY ASSISTAN',
    codeClassification: '225200000X-PHYSICAL THERAPY ASSISTAN',
    placeOfService: null,
  },
  {
    code: '363AM0700X',
    classification: 'PHYSICIAN ASSISTANT MEDIC',
    codeClassification: '363AM0700X-PHYSICIAN ASSISTANT MEDIC',
    placeOfService: null,
  },
  {
    code: '363AS0400X',
    classification: 'PHYSICIAN ASSISTANT SURGI',
    codeClassification: '363AS0400X-PHYSICIAN ASSISTANT SURGI',
    placeOfService: null,
  },
  {
    code: '363A00000X',
    classification: 'PHYSICIAN ASST BEHAVIORAL',
    codeClassification: '363A00000X-PHYSICIAN ASST BEHAVIORAL',
    placeOfService: null,
  },
  {
    code: '293D00000X',
    classification: 'PHYSIOLOGICAL LABORATORY',
    codeClassification: '293D00000X-PHYSIOLOGICAL LABORATORY',
    placeOfService: null,
    isProcedureCodeRequired: true,
  },
  {
    code: '208200000X',
    classification: 'PLASTIC SURGERY',
    codeClassification: '208200000X-PLASTIC SURGERY',
    placeOfService: null,
  },
  {
    code: '2082S0099X',
    classification: 'PLASTIC SURGERY PLASTIC S',
    codeClassification: '2082S0099X-PLASTIC SURGERY PLASTIC S',
    placeOfService: null,
  },
  {
    code: '2082S0105X',
    classification: 'PLASTIC SURGERY SURGERY O',
    codeClassification: '2082S0105X-PLASTIC SURGERY SURGERY O',
    placeOfService: null,
  },
  {
    code: '207YX0007X',
    classification: 'PLASTIC SURGERY WITHIN THE HEAD AND',
    codeClassification: '207YX0007X-PLASTIC SURGERY WITHIN THE HEAD AND',
    placeOfService: null,
  },
  {
    code: '213E00000X',
    classification: 'PODIATRIST',
    codeClassification: '213E00000X-PODIATRIST',
    placeOfService: null,
  },
  {
    code: '211D00000X',
    classification: 'PODIATRIST ASSISTANT PODI',
    codeClassification: '211D00000X-PODIATRIST ASSISTANT PODI',
    placeOfService: null,
  },
  {
    code: '213ES0103X',
    classification: 'PODIATRIST FOOT AND ANKLE',
    codeClassification: '213ES0103X-PODIATRIST FOOT AND ANKLE',
    placeOfService: null,
  },
  {
    code: '213ES0131X',
    classification: 'PODIATRIST FOOT SURGERY',
    codeClassification: '213ES0131X-PODIATRIST FOOT SURGERY',
    placeOfService: null,
  },
  {
    code: '213EP1101X',
    classification: 'PODIATRIST PRIMARY PODIAT',
    codeClassification: '213EP1101X-PODIATRIST PRIMARY PODIAT',
    placeOfService: null,
  },
  {
    code: '213EP0504X',
    classification: 'PODIATRIST PUBLIC MEDICIN',
    codeClassification: '213EP0504X-PODIATRIST PUBLIC MEDICIN',
    placeOfService: null,
  },
  {
    code: '213ER0200X',
    classification: 'PODIATRIST RADIOLOGY',
    codeClassification: '213ER0200X-PODIATRIST RADIOLOGY',
    placeOfService: null,
  },
  {
    code: '213ES0000X',
    classification: 'PODIATRIST SPORTS MEDICIN',
    codeClassification: '213ES0000X-PODIATRIST SPORTS MEDICIN',
    placeOfService: null,
  },
  {
    code: '2083B0002X',
    classification: 'PREVENTIVE MED OBESITY',
    codeClassification: '2083B0002X-PREVENTIVE MED OBESITY',
    placeOfService: null,
  },
  {
    code: '2084B0002X',
    classification: 'PREVENTIVE MED OBESITY',
    codeClassification: '2084B0002X-PREVENTIVE MED OBESITY',
    placeOfService: null,
  },
  {
    code: '2083P0901X',
    classification: 'PREVENTIVE MEDICINEPUBL',
    codeClassification: '2083P0901X-PREVENTIVE MEDICINEPUBL',
    placeOfService: null,
  },
  {
    code: '2083A0100X',
    classification: 'PREVENTIVE MEDICINE AEROS',
    codeClassification: '2083A0100X-PREVENTIVE MEDICINE AEROS',
    placeOfService: null,
  },
  {
    code: '2083T0002X',
    classification: 'PREVENTIVE MEDICINE MEDIC',
    codeClassification: '2083T0002X-PREVENTIVE MEDICINE MEDIC',
    placeOfService: null,
  },
  {
    code: '2083X0100X',
    classification: 'PREVENTIVE MEDICINE OCCUP',
    codeClassification: '2083X0100X-PREVENTIVE MEDICINE OCCUP',
    placeOfService: null,
  },
  {
    code: '2083P0500X',
    classification: 'PREVENTIVE MEDICINE PREVE',
    codeClassification: '2083P0500X-PREVENTIVE MEDICINE PREVE',
    placeOfService: null,
  },
  {
    code: '2083S0010X',
    classification: 'PREVENTIVE MEDICINE SPORT',
    codeClassification: '2083S0010X-PREVENTIVE MEDICINE SPORT',
    placeOfService: null,
  },
  {
    code: '2083P0011X',
    classification: 'PREVENTIVE MEDICINE UNDER',
    codeClassification: '2083P0011X-PREVENTIVE MEDICINE UNDER',
    placeOfService: null,
  },
  {
    code: '224P00000X',
    classification: 'PROSTHETIST',
    codeClassification: '224P00000X-PROSTHETIST',
    placeOfService: null,
  },
  {
    code: '2084D0003X',
    classification: 'PSYCHNEURO DIAG NEURO',
    codeClassification: '2084D0003X-PSYCHNEURO DIAG NEURO',
    placeOfService: null,
  },
  {
    code: '2084H0002X',
    classification: 'PSYCHNEURO HOSPPA',
    codeClassification: '2084H0002X-PSYCHNEURO HOSPPA',
    placeOfService: null,
  },
  {
    code: '2084N0008X',
    classification: 'PSYCHNEURO NEUROMUSCU',
    codeClassification: '2084N0008X-PSYCHNEURO NEUROMUSCU',
    placeOfService: null,
  },
  {
    code: '2084S0012X',
    classification: 'PSYCH NEURO SLEEP MEDICIN',
    codeClassification: '2084S0012X-PSYCH NEURO SLEEP MEDICIN',
    placeOfService: null,
  },
  {
    code: '283Q00000X',
    classification: 'PSYCHIATRIC HOSPTIAL',
    codeClassification: '283Q00000X-PSYCHIATRIC HOSPTIAL',
    placeOfService: null,
  },
  {
    code: '2084A0401X',
    classification: 'PSYCHIATRY AND NEUROLOGY',
    codeClassification: '2084A0401X-PSYCHIATRY AND NEUROLOGY',
    placeOfService: null,
  },
  {
    code: '2084F0202X',
    classification: 'PSYCHIATRY AND NEUROLOGY',
    codeClassification: '2084F0202X-PSYCHIATRY AND NEUROLOGY',
    placeOfService: null,
  },
  {
    code: '2084N0400X',
    classification: 'PSYCHIATRY AND NEUROLOGY',
    codeClassification: '2084N0400X-PSYCHIATRY AND NEUROLOGY',
    placeOfService: null,
  },
  {
    code: '2084P0800X',
    classification: 'PSYCHIATRY AND NEUROLOGY',
    codeClassification: '2084P0800X-PSYCHIATRY AND NEUROLOGY',
    placeOfService: null,
  },
  {
    code: '2084S0010X',
    classification: 'PSYCHIATRY AND NEUROLOGY',
    codeClassification: '2084S0010X-PSYCHIATRY AND NEUROLOGY',
    placeOfService: null,
  },
  {
    code: '102L00000X',
    classification: 'PSYCHOANALYST',
    codeClassification: '102L00000X-PSYCHOANALYST',
    placeOfService: null,
  },
  {
    code: '103T00000X',
    classification: 'PSYCHOLOGIST',
    codeClassification: '103T00000X-PSYCHOLOGIST',
    placeOfService: null,
  },
  {
    code: '103TA0400X',
    classification: 'PSYCHOLOGIST ADDICTIONS',
    codeClassification: '103TA0400X-PSYCHOLOGIST ADDICTIONS',
    placeOfService: null,
  },
  {
    code: '103TA0700X',
    classification: 'PSYCHOLOGIST ADULT DEVELO',
    codeClassification: '103TA0700X-PSYCHOLOGIST ADULT DEVELO',
    placeOfService: null,
  },
  {
    code: '103TB0200X',
    classification: 'PSYCHOLOGIST BEHAVIORAL',
    codeClassification: '103TB0200X-PSYCHOLOGIST BEHAVIORAL',
    placeOfService: null,
  },
  {
    code: '103TC2200X',
    classification: 'PSYCHOLOGIST CHILD YOUTH',
    codeClassification: '103TC2200X-PSYCHOLOGIST CHILD YOUTH',
    placeOfService: null,
  },
  {
    code: '103TC0700X',
    classification: 'PSYCHOLOGIST CLINICAL',
    codeClassification: '103TC0700X-PSYCHOLOGIST CLINICAL',
    placeOfService: null,
  },
  {
    code: '103TC1900X',
    classification: 'PSYCHOLOGIST COUNSELING',
    codeClassification: '103TC1900X-PSYCHOLOGIST COUNSELING',
    placeOfService: null,
  },
  {
    code: '103TE1100X',
    classification: 'PSYCHOLOGIST EXERCISE AND',
    codeClassification: '103TE1100X-PSYCHOLOGIST EXERCISE AND',
    placeOfService: null,
  },
  {
    code: '103TF0000X',
    classification: 'PSYCHOLOGIST FAMILY',
    codeClassification: '103TF0000X-PSYCHOLOGIST FAMILY',
    placeOfService: null,
  },
  {
    code: '103TF0200X',
    classification: 'PSYCHOLOGIST FORENSIC',
    codeClassification: '103TF0200X-PSYCHOLOGIST FORENSIC',
    placeOfService: null,
  },
  {
    code: '103TH0004X',
    classification: 'PSYCHOLOGIST HEALTH',
    codeClassification: '103TH0004X-PSYCHOLOGIST HEALTH',
    placeOfService: null,
  },
  {
    code: '103TH0100X',
    classification: 'PSYCHOLOGIST HEALTH SVC',
    codeClassification: '103TH0100X-PSYCHOLOGIST HEALTH SVC',
    placeOfService: null,
  },
  {
    code: '103TM1800X',
    classification: 'PSYCHOLOGIST MENTAL RETAR',
    codeClassification: '103TM1800X-PSYCHOLOGIST MENTAL RETAR',
    placeOfService: null,
  },
  {
    code: '103TP0016X',
    classification: 'PSYCHOLOGIST PRESCRIBING',
    codeClassification: '103TP0016X-PSYCHOLOGIST PRESCRIBING',
    placeOfService: null,
  },
  {
    code: '103TP0814X',
    classification: 'PSYCHOLOGIST PSYCHOANALYS',
    codeClassification: '103TP0814X-PSYCHOLOGIST PSYCHOANALYS',
    placeOfService: null,
  },
  {
    code: '103TP2701X',
    classification: 'PSYCHOLOGIST PSYCHOTHERAP',
    codeClassification: '103TP2701X-PSYCHOLOGIST PSYCHOTHERAP',
    placeOfService: null,
  },
  {
    code: '103TR0400X',
    classification: 'PSYCHOLOGIST REHABILITATI',
    codeClassification: '103TR0400X-PSYCHOLOGIST REHABILITATI',
    placeOfService: null,
  },
  {
    code: '103TS0200X',
    classification: 'PSYCHOLOGIST SCHOOL',
    codeClassification: '103TS0200X-PSYCHOLOGIST SCHOOL',
    placeOfService: null,
  },
  {
    code: '261QP0905X',
    classification: 'PUBLIC HEALTH ST OR LOCAL CLINIC',
    codeClassification: '261QP0905X-PUBLIC HEALTH ST OR LOCAL CLINIC',
    placeOfService: null,
  },
  {
    code: '2085B0100X',
    classification: 'RADIOLOGY BODY IMAGING',
    codeClassification: '2085B0100X-RADIOLOGY BODY IMAGING',
    placeOfService: null,
    isProcedureCodeRequired: true,
  },
  {
    code: '2085D0003X',
    classification: 'RADIOLOGY DIAGNOSTIC NEUR',
    codeClassification: '2085D0003X-RADIOLOGY DIAGNOSTIC NEUR',
    placeOfService: null,
    isProcedureCodeRequired: true,
  },
  {
    code: '2085U0001X',
    classification: 'RADIOLOGY DIAGNOSTIC ULTR',
    codeClassification: '2085U0001X-RADIOLOGY DIAGNOSTIC ULTR',
    placeOfService: null,
    isProcedureCodeRequired: true,
  },
  {
    code: '2085H0002X',
    classification: 'RADIOLOGY HOSPPAL MED',
    codeClassification: '2085H0002X-RADIOLOGY HOSPPAL MED',
    placeOfService: null,
    isProcedureCodeRequired: true,
  },
  {
    code: '2085N0700X',
    classification: 'RADIOLOGY NEURORADIOLOGY',
    codeClassification: '2085N0700X-RADIOLOGY NEURORADIOLOGY',
    placeOfService: null,
    isProcedureCodeRequired: true,
  },
  {
    code: '2085N0904X',
    classification: 'RADIOLOGY NUCLEAR RADIOLO',
    codeClassification: '2085N0904X-RADIOLOGY NUCLEAR RADIOLO',
    placeOfService: null,
    isProcedureCodeRequired: true,
  },
  {
    code: '2085P0229X',
    classification: 'RADIOLOGY PEDIATRIC RADIO',
    codeClassification: '2085P0229X-RADIOLOGY PEDIATRIC RADIO',
    placeOfService: null,
    isProcedureCodeRequired: true,
  },
  {
    code: '2085R0001X',
    classification: 'RADIOLOGY RADIATION ONCOL',
    codeClassification: '2085R0001X-RADIOLOGY RADIATION ONCOL',
    placeOfService: null,
    isProcedureCodeRequired: true,
  },
  {
    code: '2085R0205X',
    classification: 'RADIOLOGY RADIOLOGICAL PH',
    codeClassification: '2085R0205X-RADIOLOGY RADIOLOGICAL PH',
    placeOfService: null,
    isProcedureCodeRequired: true,
  },
  {
    code: '2085R0203X',
    classification: 'RADIOLOGY THERAPEUTIC RAD',
    codeClassification: '2085R0203X-RADIOLOGY THERAPEUTIC RAD',
    placeOfService: null,
    isProcedureCodeRequired: true,
  },
  {
    code: '2085R0204X',
    classification: 'RADIOLOGY VASCULAR AND IN',
    codeClassification: '2085R0204X-RADIOLOGY VASCULAR AND IN',
    placeOfService: null,
    isProcedureCodeRequired: true,
  },
  {
    code: '163W00000X',
    classification: 'REGISTERED NURSE',
    codeClassification: '163W00000X-REGISTERED NURSE',
    placeOfService: null,
  },
  {
    code: '163WP0807X',
    classification: 'REGISTERED NURSE PSYCHI',
    codeClassification: '163WP0807X-REGISTERED NURSE PSYCHI',
    placeOfService: null,
  },
  {
    code: '163WR0400X',
    classification: 'REGISTERED NURSE REHABI',
    codeClassification: '163WR0400X-REGISTERED NURSE REHABI',
    placeOfService: null,
  },
  {
    code: '163WR1000X',
    classification: 'REGISTERED NURSE REPROD',
    codeClassification: '163WR1000X-REGISTERED NURSE REPROD',
    placeOfService: null,
  },
  {
    code: '163WS0200X',
    classification: 'REGISTERED NURSE SCHOOL',
    codeClassification: '163WS0200X-REGISTERED NURSE SCHOOL',
    placeOfService: null,
  },
  {
    code: '163WU0100X',
    classification: 'REGISTERED NURSE UROLOG',
    codeClassification: '163WU0100X-REGISTERED NURSE UROLOG',
    placeOfService: null,
  },
  {
    code: '163WW0101X',
    classification: 'REGISTERED NURSE WOMEN',
    codeClassification: '163WW0101X-REGISTERED NURSE WOMEN',
    placeOfService: null,
  },
  {
    code: '163WW0000X',
    classification: 'REGISTERED NURSE WOUND',
    codeClassification: '163WW0000X-REGISTERED NURSE WOUND',
    placeOfService: null,
  },
  {
    code: '163WA0400X',
    classification: 'REGISTERED NURSE ADDICTIO',
    codeClassification: '163WA0400X-REGISTERED NURSE ADDICTIO',
    placeOfService: null,
  },
  {
    code: '163WA2000X',
    classification: 'REGISTERED NURSE ADMINIST',
    codeClassification: '163WA2000X-REGISTERED NURSE ADMINIST',
    placeOfService: null,
  },
  {
    code: '163WP2201X',
    classification: 'REGISTERED NURSE AMBULATO',
    codeClassification: '163WP2201X-REGISTERED NURSE AMBULATO',
    placeOfService: null,
  },
  {
    code: '163WC3500X',
    classification: 'REGISTERED NURSE CARDIAC',
    codeClassification: '163WC3500X-REGISTERED NURSE CARDIAC',
    placeOfService: null,
  },
  {
    code: '163WC0400X',
    classification: 'REGISTERED NURSE CASE MAN',
    codeClassification: '163WC0400X-REGISTERED NURSE CASE MAN',
    placeOfService: null,
  },
  {
    code: '163WC1400X',
    classification: 'REGISTERED NURSE COLLEGE',
    codeClassification: '163WC1400X-REGISTERED NURSE COLLEGE',
    placeOfService: null,
  },
  {
    code: '163WC1500X',
    classification: 'REGISTERED NURSE COMMUNIT',
    codeClassification: '163WC1500X-REGISTERED NURSE COMMUNIT',
    placeOfService: null,
  },
  {
    code: '163WC2100X',
    classification: 'REGISTERED NURSE CONTINEN',
    codeClassification: '163WC2100X-REGISTERED NURSE CONTINEN',
    placeOfService: null,
  },
  {
    code: '163WC1600X',
    classification: 'REGISTERED NURSE CONTINUI',
    codeClassification: '163WC1600X-REGISTERED NURSE CONTINUI',
    placeOfService: null,
  },
  {
    code: '163WD1100X',
    classification: 'REGISTERED NURSE DIALYSIS',
    codeClassification: '163WD1100X-REGISTERED NURSE DIALYSIS',
    placeOfService: null,
  },
  {
    code: '163WE0003X',
    classification: 'REGISTERED NURSE EMERGENC',
    codeClassification: '163WE0003X-REGISTERED NURSE EMERGENC',
    placeOfService: null,
  },
  {
    code: '163WE0900X',
    classification: 'REGISTERED NURSE ENTEROST',
    codeClassification: '163WE0900X-REGISTERED NURSE ENTEROST',
    placeOfService: null,
  },
  {
    code: '163WR0006X',
    classification: 'REGISTERED NURSE FIRST AS',
    codeClassification: '163WR0006X-REGISTERED NURSE FIRST AS',
    placeOfService: null,
  },
  {
    code: '163WG0100X',
    classification: 'REGISTERED NURSE GASTROEN',
    codeClassification: '163WG0100X-REGISTERED NURSE GASTROEN',
    placeOfService: null,
  },
  {
    code: '163WG0000X',
    classification: 'REGISTERED NURSE GENERAL',
    codeClassification: '163WG0000X-REGISTERED NURSE GENERAL',
    placeOfService: null,
  },
  {
    code: '163WG0600X',
    classification: 'REGISTERED NURSE GERONTOL',
    codeClassification: '163WG0600X-REGISTERED NURSE GERONTOL',
    placeOfService: null,
  },
  {
    code: '163WH0500X',
    classification: 'REGISTERED NURSE HEMODIAL',
    codeClassification: '163WH0500X-REGISTERED NURSE HEMODIAL',
    placeOfService: null,
  },
  {
    code: '163WH0200X',
    classification: 'REGISTERED NURSE HOME HEA',
    codeClassification: '163WH0200X-REGISTERED NURSE HOME HEA',
    placeOfService: null,
  },
  {
    code: '163WH1000X',
    classification: 'REGISTERED NURSE HOSPICE',
    codeClassification: '163WH1000X-REGISTERED NURSE HOSPICE',
    placeOfService: null,
  },
  {
    code: '163WI0600X',
    classification: 'REGISTERED NURSE INFECTIO',
    codeClassification: '163WI0600X-REGISTERED NURSE INFECTIO',
    placeOfService: null,
  },
  {
    code: '163WI0500X',
    classification: 'REGISTERED NURSE INFUSION',
    codeClassification: '163WI0500X-REGISTERED NURSE INFUSION',
    placeOfService: null,
  },
  {
    code: '163WL0100X',
    classification: 'REGISTERED NURSE LACTATIO',
    codeClassification: '163WL0100X-REGISTERED NURSE LACTATIO',
    placeOfService: null,
  },
  {
    code: '163WM0102X',
    classification: 'REGISTERED NURSE MATERNAL',
    codeClassification: '163WM0102X-REGISTERED NURSE MATERNAL',
    placeOfService: null,
  },
  {
    code: '163WM0705X',
    classification: 'REGISTERED NURSE MEDICAL',
    codeClassification: '163WM0705X-REGISTERED NURSE MEDICAL',
    placeOfService: null,
  },
  {
    code: '163WN0003X',
    classification: 'REGISTERED NURSE NEONATAL',
    codeClassification: '163WN0003X-REGISTERED NURSE NEONATAL',
    placeOfService: null,
  },
  {
    code: '163WN0300X',
    classification: 'REGISTERED NURSE NEPHROLO',
    codeClassification: '163WN0300X-REGISTERED NURSE NEPHROLO',
    placeOfService: null,
  },
  {
    code: '163WM1400X',
    classification: 'REGISTERED NURSE NURSE MA',
    codeClassification: '163WM1400X-REGISTERED NURSE NURSE MA',
    placeOfService: null,
  },
  {
    code: '163WP1700X',
    classification: 'REGISTERED NURSE PERINATA',
    codeClassification: '163WP1700X-REGISTERED NURSE PERINATA',
    placeOfService: null,
  },
  {
    code: '163WS0121X',
    classification: 'REGISTERED NURSE PLASTIC',
    codeClassification: '163WS0121X-REGISTERED NURSE PLASTIC',
    placeOfService: null,
  },
  {
    code: '163WP0809X',
    classification: 'REGISTERED NURSE PSYCHIAT',
    codeClassification: '163WP0809X-REGISTERED NURSE PSYCHIAT',
    placeOfService: null,
  },
  {
    code: '163WC0200X',
    classification: 'REGISTERED NURSESCRICTI',
    codeClassification: '163WC0200X-REGISTERED NURSESCRICTI',
    placeOfService: null,
  },
  {
    code: '163WN1003X',
    classification: 'REGISTERED NURSESNUTRIT',
    codeClassification: '163WN1003X-REGISTERED NURSESNUTRIT',
    placeOfService: null,
  },
  {
    code: '163WF0300X',
    classification: 'REGISTERED NURSES FLIGHT',
    codeClassification: '163WF0300X-REGISTERED NURSES FLIGHT',
    placeOfService: null,
  },
  {
    code: '163WN0800X',
    classification: 'REGISTERED NURSES NEUROSC',
    codeClassification: '163WN0800X-REGISTERED NURSES NEUROSC',
    placeOfService: null,
  },
  {
    code: '163WX0002X',
    classification: 'REGISTERED NURSES OBSTETR',
    codeClassification: '163WX0002X-REGISTERED NURSES OBSTETR',
    placeOfService: null,
  },
  {
    code: '163WX0106X',
    classification: 'REGISTERED NURSES OCCUPAT',
    codeClassification: '163WX0106X-REGISTERED NURSES OCCUPAT',
    placeOfService: null,
  },
  {
    code: '163WX0200X',
    classification: 'REGISTERED NURSES ONCOLOG',
    codeClassification: '163WX0200X-REGISTERED NURSES ONCOLOG',
    placeOfService: null,
  },
  {
    code: '163WX1100X',
    classification: 'REGISTERED NURSES OPTHALM',
    codeClassification: '163WX1100X-REGISTERED NURSES OPTHALM',
    placeOfService: null,
  },
  {
    code: '163WX0800X',
    classification: 'REGISTERED NURSES ORTHOPE',
    codeClassification: '163WX0800X-REGISTERED NURSES ORTHOPE',
    placeOfService: null,
  },
  {
    code: '163WX1500X',
    classification: 'REGISTERED NURSES OSTOMY',
    codeClassification: '163WX1500X-REGISTERED NURSES OSTOMY',
    placeOfService: null,
  },
  {
    code: '163WX0601X',
    classification: 'REGISTERED NURSES OTORHIN',
    codeClassification: '163WX0601X-REGISTERED NURSES OTORHIN',
    placeOfService: null,
  },
  {
    code: '163WP0000X',
    classification: 'REGISTERED NURSES PAIN MA',
    codeClassification: '163WP0000X-REGISTERED NURSES PAIN MA',
    placeOfService: null,
  },
  {
    code: '163WP0200X',
    classification: 'REGISTERED NURSES PEDIATR',
    codeClassification: '163WP0200X-REGISTERED NURSES PEDIATR',
    placeOfService: null,
  },
  {
    code: '163WP0218X',
    classification: 'REGISTERED NURSES PEDIATR',
    codeClassification: '163WP0218X-REGISTERED NURSES PEDIATR',
    placeOfService: null,
  },
  {
    code: '163WP0808X',
    classification: 'REGISTERED NURSES PSYCHIA',
    codeClassification: '163WP0808X-REGISTERED NURSES PSYCHIA',
    placeOfService: null,
  },
  {
    code: '283X00000X',
    classification: 'REHABILIATATION HOSPITAL',
    codeClassification: '283X00000X-REHABILIATATION HOSPITAL',
    placeOfService: null,
  },
  {
    code: '225C00000X',
    classification: 'REHABILITATION COUNSELOR',
    codeClassification: '225C00000X-REHABILITATION COUNSELOR',
    placeOfService: null,
  },
  {
    code: '282J00000X',
    classification: 'RELIGIOUS NONMEDICAL HEAL',
    codeClassification: '282J00000X-RELIGIOUS NONMEDICAL HEAL',
    placeOfService: null,
  },
  {
    code: '374K00000X',
    classification: 'RELIGIOUS NONMEDICAL PRACTITIONER',
    codeClassification: '374K00000X-RELIGIOUS NONMEDICAL PRACTITIONER',
    placeOfService: null,
  },
  {
    code: '207VE0102X',
    classification: 'REPRODUCTIVE ENDOCRINOLOG',
    codeClassification: '207VE0102X-REPRODUCTIVE ENDOCRINOLOG',
    placeOfService: null,
  },
  {
    code: '320600000X',
    classification: 'RESIDENTIAL TREATMENT FAC',
    codeClassification: '320600000X-RESIDENTIAL TREATMENT FAC',
    placeOfService: null,
  },
  {
    code: '2278C0205X',
    classification: 'RESP CERT CRITICAL CARE',
    codeClassification: '2278C0205X-RESP CERT CRITICAL CARE',
    placeOfService: null,
  },
  {
    code: '2278E1000X',
    classification: 'RESP CERT EDUCATIONAL',
    codeClassification: '2278E1000X-RESP CERT EDUCATIONAL',
    placeOfService: null,
  },
  {
    code: '2278E0002X',
    classification: 'RESP CERT EMERGENCY CARE',
    codeClassification: '2278E0002X-RESP CERT EMERGENCY CARE',
    placeOfService: null,
  },
  {
    code: '2278G1100X',
    classification: 'RESP CERT GENERAL CARE',
    codeClassification: '2278G1100X-RESP CERT GENERAL CARE',
    placeOfService: null,
  },
  {
    code: '2278G0305X',
    classification: 'RESP CERT GERIATRIC CARE',
    codeClassification: '2278G0305X-RESP CERT GERIATRIC CARE',
    placeOfService: null,
  },
  {
    code: '2278H0200X',
    classification: 'RESP CERT HOME HEALTH',
    codeClassification: '2278H0200X-RESP CERT HOME HEALTH',
    placeOfService: null,
  },
  {
    code: '2278P3900X',
    classification: 'RESP CERT NEONATAL PEDIAT',
    codeClassification: '2278P3900X-RESP CERT NEONATAL PEDIAT',
    placeOfService: null,
  },
  {
    code: '2278P3800X',
    classification: 'RESP CERT PALLIATIVE HOSP',
    codeClassification: '2278P3800X-RESP CERT PALLIATIVE HOSP',
    placeOfService: null,
  },
  {
    code: '2278P4000X',
    classification: 'RESP CERT PATIENT TRANSPO',
    codeClassification: '2278P4000X-RESP CERT PATIENT TRANSPO',
    placeOfService: null,
  },
  {
    code: '2278P1004X',
    classification: 'RESP CERT PULMONARY DIAGN',
    codeClassification: '2278P1004X-RESP CERT PULMONARY DIAGN',
    placeOfService: null,
  },
  {
    code: '2278P1006X',
    classification: 'RESP CERT PULMONARY FUNC',
    codeClassification: '2278P1006X-RESP CERT PULMONARY FUNC',
    placeOfService: null,
  },
  {
    code: '2278P1005X',
    classification: 'RESP CERT PULMONARY REHAB',
    codeClassification: '2278P1005X-RESP CERT PULMONARY REHAB',
    placeOfService: null,
  },
  {
    code: '2278S1500X',
    classification: 'RESP CERT SNF SUBACUTE CA',
    codeClassification: '2278S1500X-RESP CERT SNF SUBACUTE CA',
    placeOfService: null,
  },
  {
    code: '2279C0205X',
    classification: 'RESP REG CRITICAL CARE',
    codeClassification: '2279C0205X-RESP REG CRITICAL CARE',
    placeOfService: null,
  },
  {
    code: '2279E1000X',
    classification: 'RESP REG EDUCATIONAL',
    codeClassification: '2279E1000X-RESP REG EDUCATIONAL',
    placeOfService: null,
  },
  {
    code: '2279E0002X',
    classification: 'RESP REG EMERGENCY CARE',
    codeClassification: '2279E0002X-RESP REG EMERGENCY CARE',
    placeOfService: null,
  },
  {
    code: '2279G0305X',
    classification: 'RESP REG GERIATRIC CARE',
    codeClassification: '2279G0305X-RESP REG GERIATRIC CARE',
    placeOfService: null,
  },
  {
    code: '2279H0200X',
    classification: 'RESP REG HOME HEALTH',
    codeClassification: '2279H0200X-RESP REG HOME HEALTH',
    placeOfService: null,
  },
  {
    code: '2279P3900X',
    classification: 'RESP REG NEONATAL PEDIATR',
    codeClassification: '2279P3900X-RESP REG NEONATAL PEDIATR',
    placeOfService: null,
  },
  {
    code: '2279P3800X',
    classification: 'RESP REG PALLIATIVE HOSPI',
    codeClassification: '2279P3800X-RESP REG PALLIATIVE HOSPI',
    placeOfService: null,
  },
  {
    code: '2279P4000X',
    classification: 'RESP REG PATIENT TRANSPOR',
    codeClassification: '2279P4000X-RESP REG PATIENT TRANSPOR',
    placeOfService: null,
  },
  {
    code: '2279P1004X',
    classification: 'RESP REG PULMONARY DIAGNO',
    codeClassification: '2279P1004X-RESP REG PULMONARY DIAGNO',
    placeOfService: null,
  },
  {
    code: '2279P1006X',
    classification: 'RESP REG PULMONARY FUNC T',
    codeClassification: '2279P1006X-RESP REG PULMONARY FUNC T',
    placeOfService: null,
  },
  {
    code: '2279P1005X',
    classification: 'RESP REG PULMONARY REHAB',
    codeClassification: '2279P1005X-RESP REG PULMONARY REHAB',
    placeOfService: null,
  },
  {
    code: '2279S1500X',
    classification: 'RESP REG SNF SUBACUTE CAR',
    codeClassification: '2279S1500X-RESP REG SNF SUBACUTE CAR',
    placeOfService: null,
  },
  {
    code: '227800000X',
    classification: 'RESPIRATORY THERAPISTCE',
    codeClassification: '227800000X-RESPIRATORY THERAPISTCE',
    placeOfService: null,
  },
  {
    code: '227900000X',
    classification: 'RESPIRATORY THERAPISTRE',
    codeClassification: '227900000X-RESPIRATORY THERAPISTRE',
    placeOfService: null,
  },
  {
    code: '385H00000X',
    classification: 'RESPITE CARE FACILITY',
    codeClassification: '385H00000X-RESPITE CARE FACILITY',
    placeOfService: null,
  },
  {
    code: '385HR2055X',
    classification: 'RESPITE CARE FACILITY RES',
    codeClassification: '385HR2055X-RESPITE CARE FACILITY RES',
    placeOfService: null,
  },
  {
    code: '314000000X',
    classification: 'SKILLED NURSING FACILITY',
    codeClassification: '314000000X-SKILLED NURSING FACILITY',
    placeOfService: null,
  },
  {
    code: '104100000X',
    classification: 'SOCIAL WORKER',
    codeClassification: '104100000X-SOCIAL WORKER',
    placeOfService: null,
  },
  {
    code: '1041C0700X',
    classification: 'SOCIAL WORKER CLINICAL',
    codeClassification: '1041C0700X-SOCIAL WORKER CLINICAL',
    placeOfService: null,
  },
  {
    code: '1041S0200X',
    classification: 'SOCIAL WORKER SCHOOL',
    codeClassification: '1041S0200X-SOCIAL WORKER SCHOOL',
    placeOfService: null,
  },
  {
    code: '246ZA2600X',
    classification: 'SPECIALIST ARTMEDICAL',
    codeClassification: '246ZA2600X-SPECIALIST ARTMEDICAL',
    placeOfService: null,
  },
  {
    code: '246Z00000X',
    classification: 'SPECIALIST TECHNOLOGIST OTHER',
    codeClassification: '246Z00000X-SPECIALIST TECHNOLOGIST OTHER',
    placeOfService: null,
  },
  {
    code: '2355S0801X',
    classification: 'SPEECH LANG ASSIST',
    codeClassification: '2355S0801X-SPEECH LANG ASSIST',
    placeOfService: null,
  },
  {
    code: '235Z00000X',
    classification: 'SPEECH LANGUAGE PATHOLOGI',
    codeClassification: '235Z00000X-SPEECH LANGUAGE PATHOLOGI',
    placeOfService: null,
  },
  {
    code: '193400000X',
    classification: 'SSG NURSING SERVICE PROVI',
    codeClassification: '193400000X-SSG NURSING SERVICE PROVI',
    placeOfService: null,
  },
  {
    code: '324500000X',
    classification: 'SUBSTANCE ABUSE REHAB',
    codeClassification: '324500000X-SUBSTANCE ABUSE REHAB',
    placeOfService: null,
  },
  {
    code: '3245S0500X',
    classification: 'SUBSTANCE ABUSE TREATMENT',
    codeClassification: '3245S0500X-SUBSTANCE ABUSE TREATMENT',
    placeOfService: null,
  },
  {
    code: '331L00000X',
    classification: 'SUPPLIERSBLOOD BANK',
    codeClassification: '331L00000X-SUPPLIERSBLOOD BANK',
    placeOfService: null,
  },
  {
    code: '332100000X',
    classification: 'SUPPLIERS DEPARTMENT OF V',
    codeClassification: '332100000X-SUPPLIERS DEPARTMENT OF V',
    placeOfService: null,
  },
  {
    code: '332800000X',
    classification: 'SUPPLIERS INDIAN HEALTH S',
    codeClassification: '332800000X-SUPPLIERS INDIAN HEALTH S',
    placeOfService: null,
  },
  {
    code: '3336C0002X',
    classification: 'SUPPLIERS PHARMACY CLINIC',
    codeClassification: '3336C0002X-SUPPLIERS PHARMACY CLINIC',
    placeOfService: null,
  },
  {
    code: '251X00000X',
    classification: 'SUPPORTS BROKERAGE AGENCY',
    codeClassification: '251X00000X-SUPPORTS BROKERAGE AGENCY',
    placeOfService: null,
  },
  {
    code: '208600000X',
    classification: 'SURGERY',
    codeClassification: '208600000X-SURGERY',
    placeOfService: null,
  },
  {
    code: '208600000X',
    classification: 'SURGERY HEAD AND NECK',
    codeClassification: '208600000X-SURGERY HEAD AND NECK',
    placeOfService: null,
  },
  {
    code: '2086H0002X',
    classification: 'SURGERY HOSPPAL MED',
    codeClassification: '2086H0002X-SURGERY HOSPPAL MED',
    placeOfService: null,
  },
  {
    code: '2086S0120X',
    classification: 'SURGERY PEDIATRIC SURGERY',
    codeClassification: '2086S0120X-SURGERY PEDIATRIC SURGERY',
    placeOfService: null,
  },
  {
    code: '2086S0122X',
    classification: 'SURGERY PLASTIC AND RESCO',
    codeClassification: '2086S0122X-SURGERY PLASTIC AND RESCO',
    placeOfService: null,
  },
  {
    code: '2086S0105X',
    classification: 'SURGERY SURGERY OF THE HA',
    codeClassification: '2086S0105X-SURGERY SURGERY OF THE HA',
    placeOfService: null,
  },
  {
    code: '2086S0102X',
    classification: 'SURGERY SURGICAL CRITICAL',
    codeClassification: '2086S0102X-SURGERY SURGICAL CRITICAL',
    placeOfService: null,
  },
  {
    code: '2086X0206X',
    classification: 'SURGERY SURGICAL ONCOLOGY',
    codeClassification: '2086X0206X-SURGERY SURGICAL ONCOLOGY',
    placeOfService: null,
  },
  {
    code: '2086S0127X',
    classification: 'SURGERY TRAUMA SURGERY',
    codeClassification: '2086S0127X-SURGERY TRAUMA SURGERY',
    placeOfService: null,
  },
  {
    code: '246ZC0007X',
    classification: 'SURGICAL ASSISTANT',
    codeClassification: '246ZC0007X-SURGICAL ASSISTANT',
    placeOfService: null,
  },
  {
    code: '246ZS0410X',
    classification: 'SURGICAL TECH',
    codeClassification: '246ZS0410X-SURGICAL TECH',
    placeOfService: null,
  },
  {
    code: '246RP1900X',
    classification: 'TECHNICIAN PATH PHLEBOTOM',
    codeClassification: '246RP1900X-TECHNICIAN PATH PHLEBOTOM',
    placeOfService: null,
  },
  {
    code: '156FX1800X',
    classification: 'TECHNICIAN TECHNOLOGIST',
    codeClassification: '156FX1800X-TECHNICIAN TECHNOLOGIST',
    placeOfService: null,
  },
  {
    code: '208G00000X',
    classification: 'THORACIC SURGERY',
    codeClassification: '208G00000X-THORACIC SURGERY',
    placeOfService: null,
  },
  {
    code: '208G00000X',
    classification: 'THORACIC SURGERY CARDIOTH',
    codeClassification: '208G00000X-THORACIC SURGERY CARDIOTH',
    placeOfService: null,
  },
  {
    code: '207RT0003X',
    classification: 'TRANSPLANT HEPATOLOGY',
    codeClassification: '207RT0003X-TRANSPLANT HEPATOLOGY',
    placeOfService: null,
  },
  {
    code: '204F00000X',
    classification: 'TRANSPLANT SURGERY',
    codeClassification: '204F00000X-TRANSPLANT SURGERY',
    placeOfService: null,
  },
  {
    code: '207PE0005X',
    classification: 'UNDERSEA AND HYPERBARIC MEDICINE',
    codeClassification: '207PE0005X-UNDERSEA AND HYPERBARIC MEDICINE',
    placeOfService: null,
  },
  {
    code: '2083P0011X',
    classification: 'UNDERSEA AND HYPERBARIC MEDICINE',
    codeClassification: '2083P0011X-UNDERSEA AND HYPERBARIC MEDICINE',
    placeOfService: null,
  },
  {
    code: '261QU0200X',
    classification: 'URGENT CARE',
    codeClassification: '261QU0200X-URGENT CARE',
    placeOfService: null,
  },
  {
    code: '208800000X',
    classification: 'UROLOGY',
    codeClassification: '208800000X-UROLOGY',
    placeOfService: null,
  },
  {
    code: '2088P0231X',
    classification: 'UROLOGY PEDIATRIC UROLOGY',
    codeClassification: '2088P0231X-UROLOGY PEDIATRIC UROLOGY',
    placeOfService: null,
  },
  {
    code: '261QV0200X',
    classification: 'VA CLINIC',
    codeClassification: '261QV0200X-VA CLINIC',
    placeOfService: null,
  },
  {
    code: '2085R0204X',
    classification: 'VASCULAR AND INTERVENTIONAL RAD',
    codeClassification: '2085R0204X-VASCULAR AND INTERVENTIONAL RAD',
    placeOfService: null,
  },
  {
    code: '2086S0129X',
    classification: 'VASCULAR SURGERY',
    codeClassification: '2086S0129X-VASCULAR SURGERY',
    placeOfService: null,
  },
  {
    code: '156F00000X',
    classification: 'VISION TECHNICIAN TECHNOL',
    codeClassification: '156F00000X-VISION TECHNICIAN TECHNOL',
    placeOfService: null,
  },
  {
    code: '111S00000X',
    classification: 'SOS INTERNAL MEDICINE MAGNETI',
    codeClassification: '111S00000X-SOS INTERNAL MEDICINE MAGNETI',
    placeOfService: null,
  },
  {
    code: '335V00000X',
    classification: 'PORTABLE DIAG IMG SUPPLIER',
    codeClassification: '335V00000X-PORTABLE DIAG IMG SUPPLIER',
    placeOfService: null,
    isProcedureCodeRequired: true,
  },
];
