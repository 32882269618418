import React from 'react';
import classNames from 'classnames/bind';
import IconDocuments from 'terra-icon/lib/icon/IconDocuments';
import Text from 'terra-text';
import * as clipboard from 'clipboard-polyfill/text';
import Button from 'terra-button';
import styles from './ResultCard.module.scss';

const cx = classNames.bind(styles);

export const Details = ({ address1, address2, phoneNumber, distance }) => {
  return (
    <>
      {address1 && <div>{address1}</div>}
      <div>{address2}</div>
      {phoneNumber && <div>{`P: ${phoneNumber}`}</div>}
      {distance && <div>{`Est. member distance: ${distance}`}</div>}
    </>
  );
};

export const CopyButton = ({ copyText, copyDetails, onClick }) => {
  const { setCopiedKey, copyId, copiedKey } = copyDetails;
  const selected = copiedKey === copyId;
  return (
    <Button
      className={cx('full-height')}
      variant="de-emphasis"
      text=""
      icon={
        <>
          <div className={cx('button-width')}>
            <IconDocuments
              className={cx('icon', 'info')}
              height="1.4em"
              width="1.4em"
            />
            <Text fontSize={12} colorClass={cx('info')} weight={400}>
              COPY DETAILS
            </Text>
            <div>
              <Text fontSize={10} isVisuallyHidden={!selected}>
                copied
              </Text>
            </div>
          </div>
        </>
      }
      onClick={async () => {
        onClick();
        await clipboard.writeText(copyText);
        setCopiedKey(copyId);
      }}
    />
  );
};
