export const languages = [
  {
    code: 'aar',
    label: 'Afar',
  },
  {
    code: 'abk',
    label: 'Abkhazian',
  },
  {
    code: 'ace',
    label: 'Achinese',
  },
  {
    code: 'ach',
    label: 'Acoli',
  },
  {
    code: 'ada',
    label: 'Adangme',
  },
  {
    code: 'ady',
    label: 'Adyghe; Adygei',
  },
  {
    code: 'afa',
    label: 'Afro-Asiatic languages',
  },
  {
    code: 'afh',
    label: 'Afrihili',
  },
  {
    code: 'afr',
    label: 'Afrikaans',
  },
  {
    code: 'ain',
    label: 'Ainu',
  },
  {
    code: 'aka',
    label: 'Akan',
  },
  {
    code: 'akk',
    label: 'Akkadian',
  },
  {
    code: 'alb',
    label: 'Albanian',
  },
  {
    code: 'ale',
    label: 'Aleut',
  },
  {
    code: 'alg',
    label: 'Algonquian languages',
  },
  {
    code: 'alt',
    label: 'Southern Altai',
  },
  {
    code: 'amh',
    label: 'Amharic',
  },
  {
    code: 'anp',
    label: 'Angika',
  },
  {
    code: 'apa',
    label: 'Apache languages',
  },
  {
    code: 'ara',
    label: 'Arabic',
  },
  {
    code: 'arc',
    label: 'Official Aramaic (700-300 BCE); Imperial Aramaic (700-300 BCE)',
  },
  {
    code: 'arg',
    label: 'Aragonese',
  },
  {
    code: 'arm',
    label: 'Armenian',
  },
  {
    code: 'arn',
    label: 'Mapudungun; Mapuche',
  },
  {
    code: 'arp',
    label: 'Arapaho',
  },
  {
    code: 'art',
    label: 'Artificial languages',
  },
  {
    code: 'arw',
    label: 'Arawak',
  },
  {
    code: 'asm',
    label: 'Assamese',
  },
  {
    code: 'ast',
    label: 'Asturian; Bable; Leonese; Asturleonese',
  },
  {
    code: 'ath',
    label: 'Athapascan languages',
  },
  {
    code: 'aus',
    label: 'Australian languages',
  },
  {
    code: 'ava',
    label: 'Avaric',
  },
  {
    code: 'ave',
    label: 'Avestan',
  },
  {
    code: 'awa',
    label: 'Awadhi',
  },
  {
    code: 'aym',
    label: 'Aymara',
  },
  {
    code: 'aze',
    label: 'Azerbaijani',
  },
  {
    code: 'bad',
    label: 'Banda languages',
  },
  {
    code: 'bai',
    label: 'Bamileke languages',
  },
  {
    code: 'bak',
    label: 'Bashkir',
  },
  {
    code: 'bal',
    label: 'Baluchi',
  },
  {
    code: 'bam',
    label: 'Bambara',
  },
  {
    code: 'ban',
    label: 'Balinese',
  },
  {
    code: 'baq',
    label: 'Basque',
  },
  {
    code: 'bas',
    label: 'Basa',
  },
  {
    code: 'bat',
    label: 'Baltic languages',
  },
  {
    code: 'bej',
    label: 'Beja; Bedawiyet',
  },
  {
    code: 'bel',
    label: 'Belarusian',
  },
  {
    code: 'bem',
    label: 'Bemba',
  },
  {
    code: 'ben',
    label: 'Bengali',
  },
  {
    code: 'ber',
    label: 'Berber languages',
  },
  {
    code: 'bho',
    label: 'Bhojpuri',
  },
  {
    code: 'bih',
    label: 'Bihari languages',
  },
  {
    code: 'bik',
    label: 'Bikol',
  },
  {
    code: 'bin',
    label: 'Bini; Edo',
  },
  {
    code: 'bis',
    label: 'Bislama',
  },
  {
    code: 'bla',
    label: 'Siksika',
  },
  {
    code: 'bnt',
    label: 'Bantu (Other)',
  },
  {
    code: 'bos',
    label: 'Bosnian',
  },
  {
    code: 'bra',
    label: 'Braj',
  },
  {
    code: 'bre',
    label: 'Breton',
  },
  {
    code: 'btk',
    label: 'Batak languages',
  },
  {
    code: 'bua',
    label: 'Buriat',
  },
  {
    code: 'bug',
    label: 'Buginese',
  },
  {
    code: 'bul',
    label: 'Bulgarian',
  },
  {
    code: 'bur',
    label: 'Burmese',
  },
  {
    code: 'byn',
    label: 'Blin; Bilin',
  },
  {
    code: 'cad',
    label: 'Caddo',
  },
  {
    code: 'cai',
    label: 'Central American Indian languages',
  },
  {
    code: 'car',
    label: 'Galibi Carib',
  },
  {
    code: 'cat',
    label: 'Catalan; Valencian',
  },
  {
    code: 'cau',
    label: 'Caucasian languages',
  },
  {
    code: 'ceb',
    label: 'Cebuano',
  },
  {
    code: 'cel',
    label: 'Celtic languages',
  },
  {
    code: 'cha',
    label: 'Chamorro',
  },
  {
    code: 'chb',
    label: 'Chibcha',
  },
  {
    code: 'che',
    label: 'Chechen',
  },
  {
    code: 'chg',
    label: 'Chagatai',
  },
  {
    code: 'chi',
    label: 'Chinese',
  },
  {
    code: 'chk',
    label: 'Chuukese',
  },
  {
    code: 'chm',
    label: 'Mari',
  },
  {
    code: 'chn',
    label: 'Chinook jargon',
  },
  {
    code: 'cho',
    label: 'Choctaw',
  },
  {
    code: 'chp',
    label: 'Chipewyan; Dene Suline',
  },
  {
    code: 'chr',
    label: 'Cherokee',
  },
  {
    code: 'chu',
    label:
      'Church Slavic; Old Slavonic; Church Slavonic; Old Bulgarian; Old Church Slavonic',
  },
  {
    code: 'chv',
    label: 'Chuvash',
  },
  {
    code: 'chy',
    label: 'Cheyenne',
  },
  {
    code: 'cmc',
    label: 'Chamic languages',
  },
  {
    code: 'cop',
    label: 'Coptic',
  },
  {
    code: 'cor',
    label: 'Cornish',
  },
  {
    code: 'cos',
    label: 'Corsican',
  },
  {
    code: 'cpf',
    label: 'Creoles and pidgins, French-based ',
  },
  {
    code: 'cpp',
    label: 'Creoles and pidgins, Portuguese-based ',
  },
  {
    code: 'cre',
    label: 'Cree',
  },
  {
    code: 'crh',
    label: 'Crimean Tatar; Crimean Turkish',
  },
  {
    code: 'crp',
    label: 'Creoles and pidgins ',
  },
  {
    code: 'csb',
    label: 'Kashubian',
  },
  {
    code: 'cus',
    label: 'Cushitic languages',
  },
  {
    code: 'cze',
    label: 'Czech',
  },
  {
    code: 'dak',
    label: 'Dakota',
  },
  {
    code: 'dan',
    label: 'Danish',
  },
  {
    code: 'dar',
    label: 'Dargwa',
  },
  {
    code: 'day',
    label: 'Land Dayak languages',
  },
  {
    code: 'del',
    label: 'Delaware',
  },
  {
    code: 'den',
    label: 'Slave (Athapascan)',
  },
  {
    code: 'dgr',
    label: 'Dogrib',
  },
  {
    code: 'din',
    label: 'Dinka',
  },
  {
    code: 'div',
    label: 'Divehi; Dhivehi; Maldivian',
  },
  {
    code: 'doi',
    label: 'Dogri',
  },
  {
    code: 'dra',
    label: 'Dravidian languages',
  },
  {
    code: 'dsb',
    label: 'Lower Sorbian',
  },
  {
    code: 'dua',
    label: 'Duala',
  },
  {
    code: 'dum',
    label: 'Dutch, Middle (ca.1050-1350)',
  },
  {
    code: 'dut',
    label: 'Dutch; Flemish',
  },
  {
    code: 'dyu',
    label: 'Dyula',
  },
  {
    code: 'dzo',
    label: 'Dzongkha',
  },
  {
    code: 'efi',
    label: 'Efik',
  },
  {
    code: 'egy',
    label: 'Egyptian (Ancient)',
  },
  {
    code: 'eka',
    label: 'Ekajuk',
  },
  {
    code: 'elx',
    label: 'Elamite',
  },
  {
    code: 'eng',
    label: 'English',
  },
  {
    code: 'epo',
    label: 'Esperanto',
  },
  {
    code: 'est',
    label: 'Estonian',
  },
  {
    code: 'ewe',
    label: 'Ewe',
  },
  {
    code: 'ewo',
    label: 'Ewondo',
  },
  {
    code: 'fan',
    label: 'Fang',
  },
  {
    code: 'fao',
    label: 'Faroese',
  },
  {
    code: 'fat',
    label: 'Fanti',
  },
  {
    code: 'fij',
    label: 'Fijian',
  },
  {
    code: 'fil',
    label: 'Filipino; Pilipino',
  },
  {
    code: 'fin',
    label: 'Finnish',
  },
  {
    code: 'fiu',
    label: 'Finno-Ugrian languages',
  },
  {
    code: 'fon',
    label: 'Fon',
  },
  {
    code: 'fre',
    label: 'French',
  },
  {
    code: 'frm',
    label: 'French, Middle (ca.1400-1600)',
  },
  {
    code: 'fro',
    label: 'French, Old (842-ca.1400)',
  },
  {
    code: 'frr',
    label: 'Northern Frisian',
  },
  {
    code: 'frs',
    label: 'Eastern Frisian',
  },
  {
    code: 'fry',
    label: 'Western Frisian',
  },
  {
    code: 'ful',
    label: 'Fulah',
  },
  {
    code: 'fur',
    label: 'Friulian',
  },
  {
    code: 'gaa',
    label: 'Ga',
  },
  {
    code: 'gay',
    label: 'Gayo',
  },
  {
    code: 'gba',
    label: 'Gbaya',
  },
  {
    code: 'gem',
    label: 'Germanic languages',
  },
  {
    code: 'geo',
    label: 'Georgian',
  },
  {
    code: 'ger',
    label: 'German',
  },
  {
    code: 'gez',
    label: 'Geez',
  },
  {
    code: 'gil',
    label: 'Gilbertese',
  },
  {
    code: 'gla',
    label: 'Gaelic; Scottish Gaelic',
  },
  {
    code: 'gle',
    label: 'Irish',
  },
  {
    code: 'glg',
    label: 'Galician',
  },
  {
    code: 'glv',
    label: 'Manx',
  },
  {
    code: 'gmh',
    label: 'German, Middle High (ca.1050-1500)',
  },
  {
    code: 'goh',
    label: 'German, Old High (ca.750-1050)',
  },
  {
    code: 'gon',
    label: 'Gondi',
  },
  {
    code: 'gor',
    label: 'Gorontalo',
  },
  {
    code: 'got',
    label: 'Gothic',
  },
  {
    code: 'grb',
    label: 'Grebo',
  },
  {
    code: 'grc',
    label: 'Greek, Ancient (to 1453)',
  },
  {
    code: 'gre',
    label: 'Greek, Modern (1453-)',
  },
  {
    code: 'grn',
    label: 'Guarani',
  },
  {
    code: 'gsw',
    label: 'Swiss German; Alemannic; Alsatian',
  },
  {
    code: 'guj',
    label: 'Gujarati',
  },
  {
    code: 'gwi',
    label: "Gwich'in",
  },
  {
    code: 'hai',
    label: 'Haida',
  },
  {
    code: 'hat',
    label: 'Haitian; Haitian Creole',
  },
  {
    code: 'hau',
    label: 'Hausa',
  },
  {
    code: 'haw',
    label: 'Hawaiian',
  },
  {
    code: 'heb',
    label: 'Hebrew',
  },
  {
    code: 'her',
    label: 'Herero',
  },
  {
    code: 'hil',
    label: 'Hiligaynon',
  },
  {
    code: 'him',
    label: 'Himachali languages; Western Pahari languages',
  },
  {
    code: 'hin',
    label: 'Hindi',
  },
  {
    code: 'hit',
    label: 'Hittite',
  },
  {
    code: 'hmn',
    label: 'Hmong; Mong',
  },
  {
    code: 'hmo',
    label: 'Hiri Motu',
  },
  {
    code: 'hrv',
    label: 'Croatian',
  },
  {
    code: 'hsb',
    label: 'Upper Sorbian',
  },
  {
    code: 'hun',
    label: 'Hungarian',
  },
  {
    code: 'hup',
    label: 'Hupa',
  },
  {
    code: 'iba',
    label: 'Iban',
  },
  {
    code: 'ibo',
    label: 'Igbo',
  },
  {
    code: 'ice',
    label: 'Icelandic',
  },
  {
    code: 'ido',
    label: 'Ido',
  },
  {
    code: 'iii',
    label: 'Sichuan Yi; Nuosu',
  },
  {
    code: 'ijo',
    label: 'Ijo languages',
  },
  {
    code: 'iku',
    label: 'Inuktitut',
  },
  {
    code: 'ile',
    label: 'Interlingue; Occidental',
  },
  {
    code: 'ilo',
    label: 'Iloko',
  },
  {
    code: 'ina',
    label: 'Interlingua (International Auxiliary Language Association)',
  },
  {
    code: 'inc',
    label: 'Indic languages',
  },
  {
    code: 'ind',
    label: 'Indonesian',
  },
  {
    code: 'ine',
    label: 'Indo-European languages',
  },
  {
    code: 'inh',
    label: 'Ingush',
  },
  {
    code: 'ipk',
    label: 'Inupiaq',
  },
  {
    code: 'ira',
    label: 'Iranian languages',
  },
  {
    code: 'iro',
    label: 'Iroquoian languages',
  },
  {
    code: 'ita',
    label: 'Italian',
  },
  {
    code: 'jav',
    label: 'Javanese',
  },
  {
    code: 'jbo',
    label: 'Lojban',
  },
  {
    code: 'jpn',
    label: 'Japanese',
  },
  {
    code: 'jpr',
    label: 'Judeo-Persian',
  },
  {
    code: 'jrb',
    label: 'Judeo-Arabic',
  },
  {
    code: 'kaa',
    label: 'Kara-Kalpak',
  },
  {
    code: 'kab',
    label: 'Kabyle',
  },
  {
    code: 'kac',
    label: 'Kachin; Jingpho',
  },
  {
    code: 'kal',
    label: 'Kalaallisut; Greenlandic',
  },
  {
    code: 'kam',
    label: 'Kamba',
  },
  {
    code: 'kan',
    label: 'Kannada',
  },
  {
    code: 'kar',
    label: 'Karen languages',
  },
  {
    code: 'kas',
    label: 'Kashmiri',
  },
  {
    code: 'kau',
    label: 'Kanuri',
  },
  {
    code: 'kaw',
    label: 'Kawi',
  },
  {
    code: 'kaz',
    label: 'Kazakh',
  },
  {
    code: 'kbd',
    label: 'Kabardian',
  },
  {
    code: 'kha',
    label: 'Khasi',
  },
  {
    code: 'khi',
    label: 'Khoisan languages',
  },
  {
    code: 'khm',
    label: 'Central Khmer',
  },
  {
    code: 'kho',
    label: 'Khotanese; Sakan',
  },
  {
    code: 'kik',
    label: 'Kikuyu; Gikuyu',
  },
  {
    code: 'kin',
    label: 'Kinyarwanda',
  },
  {
    code: 'kir',
    label: 'Kirghiz; Kyrgyz',
  },
  {
    code: 'kmb',
    label: 'Kimbundu',
  },
  {
    code: 'kok',
    label: 'Konkani',
  },
  {
    code: 'kom',
    label: 'Komi',
  },
  {
    code: 'kon',
    label: 'Kongo',
  },
  {
    code: 'kor',
    label: 'Korean',
  },
  {
    code: 'kos',
    label: 'Kosraean',
  },
  {
    code: 'kpe',
    label: 'Kpelle',
  },
  {
    code: 'krc',
    label: 'Karachay-Balkar',
  },
  {
    code: 'krl',
    label: 'Karelian',
  },
  {
    code: 'kro',
    label: 'Kru languages',
  },
  {
    code: 'kru',
    label: 'Kurukh',
  },
  {
    code: 'kua',
    label: 'Kuanyama; Kwanyama',
  },
  {
    code: 'kum',
    label: 'Kumyk',
  },
  {
    code: 'kur',
    label: 'Kurdish',
  },
  {
    code: 'kut',
    label: 'Kutenai',
  },
  {
    code: 'lad',
    label: 'Ladino',
  },
  {
    code: 'lah',
    label: 'Lahnda',
  },
  {
    code: 'lam',
    label: 'Lamba',
  },
  {
    code: 'lao',
    label: 'Lao',
  },
  {
    code: 'lat',
    label: 'Latin',
  },
  {
    code: 'lav',
    label: 'Latvian',
  },
  {
    code: 'lez',
    label: 'Lezghian',
  },
  {
    code: 'lim',
    label: 'Limburgan; Limburger; Limburgish',
  },
  {
    code: 'lin',
    label: 'Lingala',
  },
  {
    code: 'lit',
    label: 'Lithuanian',
  },
  {
    code: 'lol',
    label: 'Mongo',
  },
  {
    code: 'loz',
    label: 'Lozi',
  },
  {
    code: 'ltz',
    label: 'Luxembourgish; Letzeburgesch',
  },
  {
    code: 'lua',
    label: 'Luba-Lulua',
  },
  {
    code: 'lub',
    label: 'Luba-Katanga',
  },
  {
    code: 'lug',
    label: 'Ganda',
  },
  {
    code: 'lui',
    label: 'Luiseno',
  },
  {
    code: 'lun',
    label: 'Lunda',
  },
  {
    code: 'luo',
    label: 'Luo (Kenya and Tanzania)',
  },
  {
    code: 'lus',
    label: 'Lushai',
  },
  {
    code: 'mac',
    label: 'Macedonian',
  },
  {
    code: 'mad',
    label: 'Madurese',
  },
  {
    code: 'mag',
    label: 'Magahi',
  },
  {
    code: 'mah',
    label: 'Marshallese',
  },
  {
    code: 'mai',
    label: 'Maithili',
  },
  {
    code: 'mak',
    label: 'Makasar',
  },
  {
    code: 'mal',
    label: 'Malayalam',
  },
  {
    code: 'man',
    label: 'Mandingo',
  },
  {
    code: 'mao',
    label: 'Maori',
  },
  {
    code: 'map',
    label: 'Austronesian languages',
  },
  {
    code: 'mar',
    label: 'Marathi',
  },
  {
    code: 'mas',
    label: 'Masai',
  },
  {
    code: 'may',
    label: 'Malay',
  },
  {
    code: 'mdf',
    label: 'Moksha',
  },
  {
    code: 'mdr',
    label: 'Mandar',
  },
  {
    code: 'men',
    label: 'Mende',
  },
  {
    code: 'mga',
    label: 'Irish, Middle (900-1200)',
  },
  {
    code: 'mic',
    label: "Mi'kmaq; Micmac",
  },
  {
    code: 'min',
    label: 'Minangkabau',
  },
  {
    code: 'mis',
    label: 'Uncoded languages',
  },
  {
    code: 'mkh',
    label: 'Mon-Khmer languages',
  },
  {
    code: 'mlg',
    label: 'Malagasy',
  },
  {
    code: 'mlt',
    label: 'Maltese',
  },
  {
    code: 'mnc',
    label: 'Manchu',
  },
  {
    code: 'mni',
    label: 'Manipuri',
  },
  {
    code: 'mno',
    label: 'Manobo languages',
  },
  {
    code: 'moh',
    label: 'Mohawk',
  },
  {
    code: 'mon',
    label: 'Mongolian',
  },
  {
    code: 'mos',
    label: 'Mossi',
  },
  {
    code: 'mul',
    label: 'Multiple languages',
  },
  {
    code: 'mun',
    label: 'Munda languages',
  },
  {
    code: 'mus',
    label: 'Creek',
  },
  {
    code: 'mwl',
    label: 'Mirandese',
  },
  {
    code: 'mwr',
    label: 'Marwari',
  },
  {
    code: 'myn',
    label: 'Mayan languages',
  },
  {
    code: 'myv',
    label: 'Erzya',
  },
  {
    code: 'nah',
    label: 'Nahuatl languages',
  },
  {
    code: 'nai',
    label: 'North American Indian languages',
  },
  {
    code: 'nap',
    label: 'Neapolitan',
  },
  {
    code: 'nau',
    label: 'Nauru',
  },
  {
    code: 'nav',
    label: 'Navajo; Navaho',
  },
  {
    code: 'nbl',
    label: 'Ndebele, South; South Ndebele',
  },
  {
    code: 'nde',
    label: 'Ndebele, North; North Ndebele',
  },
  {
    code: 'ndo',
    label: 'Ndonga',
  },
  {
    code: 'nds',
    label: 'Low German; Low Saxon; German, Low; Saxon, Low',
  },
  {
    code: 'nep',
    label: 'Nepali',
  },
  {
    code: 'new',
    label: 'Nepal Bhasa; Newari',
  },
  {
    code: 'nia',
    label: 'Nias',
  },
  {
    code: 'nic',
    label: 'Niger-Kordofanian languages',
  },
  {
    code: 'niu',
    label: 'Niuean',
  },
  {
    code: 'nno',
    label: 'Norwegian Nynorsk; Nynorsk, Norwegian',
  },
  {
    code: 'nob',
    label: 'Bokmål, Norwegian; Norwegian Bokmål',
  },
  {
    code: 'nog',
    label: 'Nogai',
  },
  {
    code: 'non',
    label: 'Norse, Old',
  },
  {
    code: 'nor',
    label: 'Norwegian',
  },
  {
    code: 'nqo',
    label: "N'Ko",
  },
  {
    code: 'nso',
    label: 'Pedi; Sepedi; Northern Sotho',
  },
  {
    code: 'nub',
    label: 'Nubian languages',
  },
  {
    code: 'nwc',
    label: 'Classical Newari; Old Newari; Classical Nepal Bhasa',
  },
  {
    code: 'nya',
    label: 'Chichewa; Chewa; Nyanja',
  },
  {
    code: 'nym',
    label: 'Nyamwezi',
  },
  {
    code: 'nyn',
    label: 'Nyankole',
  },
  {
    code: 'nyo',
    label: 'Nyoro',
  },
  {
    code: 'nzi',
    label: 'Nzima',
  },
  {
    code: 'oci',
    label: 'Occitan (post 1500); Provençal',
  },
  {
    code: 'oji',
    label: 'Ojibwa',
  },
  {
    code: 'ori',
    label: 'Oriya',
  },
  {
    code: 'orm',
    label: 'Oromo',
  },
  {
    code: 'osa',
    label: 'Osage',
  },
  {
    code: 'oss',
    label: 'Ossetian; Ossetic',
  },
  {
    code: 'ota',
    label: 'Turkish, Ottoman (1500-1928)',
  },
  {
    code: 'oto',
    label: 'Otomian languages',
  },
  {
    code: 'paa',
    label: 'Papuan languages',
  },
  {
    code: 'pag',
    label: 'Pangasinan',
  },
  {
    code: 'pal',
    label: 'Pahlavi',
  },
  {
    code: 'pam',
    label: 'Pampanga; Kapampangan',
  },
  {
    code: 'pan',
    label: 'Panjabi; Punjabi',
  },
  {
    code: 'pap',
    label: 'Papiamento',
  },
  {
    code: 'pau',
    label: 'Palauan',
  },
  {
    code: 'peo',
    label: 'Persian, Old (ca.600-400 B.C.)',
  },
  {
    code: 'per',
    label: 'Persian',
  },
  {
    code: 'phi',
    label: 'Philippine languages',
  },
  {
    code: 'phn',
    label: 'Phoenician',
  },
  {
    code: 'pli',
    label: 'Pali',
  },
  {
    code: 'pol',
    label: 'Polish',
  },
  {
    code: 'pon',
    label: 'Pohnpeian',
  },
  {
    code: 'por',
    label: 'Portuguese',
  },
  {
    code: 'pra',
    label: 'Prakrit languages',
  },
  {
    code: 'pro',
    label: 'Provençal, Old (to 1500)',
  },
  {
    code: 'pus',
    label: 'Pushto; Pashto',
  },
  {
    code: 'qaa-qtz',
    label: 'Reserved for local use',
  },
  {
    code: 'que',
    label: 'Quechua',
  },
  {
    code: 'raj',
    label: 'Rajasthani',
  },
  {
    code: 'rap',
    label: 'Rapanui',
  },
  {
    code: 'rar',
    label: 'Rarotongan; Cook Islands Maori',
  },
  {
    code: 'roa',
    label: 'Romance languages',
  },
  {
    code: 'roh',
    label: 'Romansh',
  },
  {
    code: 'rom',
    label: 'Romany',
  },
  {
    code: 'rum',
    label: 'Romanian; Moldavian; Moldovan',
  },
  {
    code: 'run',
    label: 'Rundi',
  },
  {
    code: 'rup',
    label: 'Aromanian; Arumanian; Macedo-Romanian',
  },
  {
    code: 'rus',
    label: 'Russian',
  },
  {
    code: 'sad',
    label: 'Sandawe',
  },
  {
    code: 'sag',
    label: 'Sango',
  },
  {
    code: 'sah',
    label: 'Yakut',
  },
  {
    code: 'sai',
    label: 'South American Indian (Other)',
  },
  {
    code: 'sal',
    label: 'Salishan languages',
  },
  {
    code: 'sam',
    label: 'Samaritan Aramaic',
  },
  {
    code: 'san',
    label: 'Sanskrit',
  },
  {
    code: 'sas',
    label: 'Sasak',
  },
  {
    code: 'sat',
    label: 'Santali',
  },
  {
    code: 'scn',
    label: 'Sicilian',
  },
  {
    code: 'sco',
    label: 'Scots',
  },
  {
    code: 'sel',
    label: 'Selkup',
  },
  {
    code: 'sem',
    label: 'Semitic languages',
  },
  {
    code: 'sga',
    label: 'Irish, Old (to 900)',
  },
  {
    code: 'sgn',
    label: 'Sign Languages',
  },
  {
    code: 'shn',
    label: 'Shan',
  },
  {
    code: 'sid',
    label: 'Sidamo',
  },
  {
    code: 'sin',
    label: 'Sinhala; Sinhalese',
  },
  {
    code: 'sio',
    label: 'Siouan languages',
  },
  {
    code: 'sit',
    label: 'Sino-Tibetan languages',
  },
  {
    code: 'sla',
    label: 'Slavic languages',
  },
  {
    code: 'slo',
    label: 'Slovak',
  },
  {
    code: 'slv',
    label: 'Slovenian',
  },
  {
    code: 'sma',
    label: 'Southern Sami',
  },
  {
    code: 'sme',
    label: 'Northern Sami',
  },
  {
    code: 'smi',
    label: 'Sami languages',
  },
  {
    code: 'smj',
    label: 'Lule Sami',
  },
  {
    code: 'smn',
    label: 'Inari Sami',
  },
  {
    code: 'smo',
    label: 'Samoan',
  },
  {
    code: 'sms',
    label: 'Skolt Sami',
  },
  {
    code: 'sna',
    label: 'Shona',
  },
  {
    code: 'snd',
    label: 'Sindhi',
  },
  {
    code: 'snk',
    label: 'Soninke',
  },
  {
    code: 'sog',
    label: 'Sogdian',
  },
  {
    code: 'som',
    label: 'Somali',
  },
  {
    code: 'son',
    label: 'Songhai languages',
  },
  {
    code: 'sot',
    label: 'Sotho, Southern',
  },
  {
    code: 'spa',
    label: 'Spanish; Castilian',
  },
  {
    code: 'srd',
    label: 'Sardinian',
  },
  {
    code: 'srn',
    label: 'Sranan Tongo',
  },
  {
    code: 'srp',
    label: 'Serbian',
  },
  {
    code: 'srr',
    label: 'Serer',
  },
  {
    code: 'ssa',
    label: 'Nilo-Saharan languages',
  },
  {
    code: 'ssw',
    label: 'Swati',
  },
  {
    code: 'suk',
    label: 'Sukuma',
  },
  {
    code: 'sun',
    label: 'Sundanese',
  },
  {
    code: 'sus',
    label: 'Susu',
  },
  {
    code: 'sux',
    label: 'Sumerian',
  },
  {
    code: 'swa',
    label: 'Swahili',
  },
  {
    code: 'swe',
    label: 'Swedish',
  },
  {
    code: 'syc',
    label: 'Classical Syriac',
  },
  {
    code: 'syr',
    label: 'Syriac',
  },
  {
    code: 'tah',
    label: 'Tahitian',
  },
  {
    code: 'tai',
    label: 'Tai languages',
  },
  {
    code: 'tam',
    label: 'Tamil',
  },
  {
    code: 'tat',
    label: 'Tatar',
  },
  {
    code: 'tel',
    label: 'Telugu',
  },
  {
    code: 'tem',
    label: 'Timne',
  },
  {
    code: 'ter',
    label: 'Tereno',
  },
  {
    code: 'tet',
    label: 'Tetum',
  },
  {
    code: 'tgk',
    label: 'Tajik',
  },
  {
    code: 'tgl',
    label: 'Tagalog',
  },
  {
    code: 'tha',
    label: 'Thai',
  },
  {
    code: 'tib',
    label: 'Tibetan',
  },
  {
    code: 'tig',
    label: 'Tigre',
  },
  {
    code: 'tir',
    label: 'Tigrinya',
  },
  {
    code: 'tiv',
    label: 'Tiv',
  },
  {
    code: 'tkl',
    label: 'Tokelau',
  },
  {
    code: 'tlh',
    label: 'Klingon; tlhIngan-Hol',
  },
  {
    code: 'tli',
    label: 'Tlingit',
  },
  {
    code: 'tmh',
    label: 'Tamashek',
  },
  {
    code: 'tog',
    label: 'Tonga (Nyasa)',
  },
  {
    code: 'ton',
    label: 'Tonga (Tonga Islands)',
  },
  {
    code: 'tpi',
    label: 'Tok Pisin',
  },
  {
    code: 'tsi',
    label: 'Tsimshian',
  },
  {
    code: 'tsn',
    label: 'Tswana',
  },
  {
    code: 'tso',
    label: 'Tsonga',
  },
  {
    code: 'tuk',
    label: 'Turkmen',
  },
  {
    code: 'tum',
    label: 'Tumbuka',
  },
  {
    code: 'tup',
    label: 'Tupi languages',
  },
  {
    code: 'tur',
    label: 'Turkish',
  },
  {
    code: 'tut',
    label: 'Altaic languages',
  },
  {
    code: 'tvl',
    label: 'Tuvalu',
  },
  {
    code: 'twi',
    label: 'Twi',
  },
  {
    code: 'tyv',
    label: 'Tuvinian',
  },
  {
    code: 'udm',
    label: 'Udmurt',
  },
  {
    code: 'uga',
    label: 'Ugaritic',
  },
  {
    code: 'uig',
    label: 'Uighur; Uyghur',
  },
  {
    code: 'ukr',
    label: 'Ukrainian',
  },
  {
    code: 'umb',
    label: 'Umbundu',
  },
  {
    code: 'und',
    label: 'Undetermined',
  },
  {
    code: 'urd',
    label: 'Urdu',
  },
  {
    code: 'uzb',
    label: 'Uzbek',
  },
  {
    code: 'vai',
    label: 'Vai',
  },
  {
    code: 'ven',
    label: 'Venda',
  },
  {
    code: 'vie',
    label: 'Vietnamese',
  },
  {
    code: 'vol',
    label: 'Volapük',
  },
  {
    code: 'vot',
    label: 'Votic',
  },
  {
    code: 'wak',
    label: 'Wakashan languages',
  },
  {
    code: 'wal',
    label: 'Walamo',
  },
  {
    code: 'war',
    label: 'Waray',
  },
  {
    code: 'was',
    label: 'Washo',
  },
  {
    code: 'wel',
    label: 'Welsh',
  },
  {
    code: 'wen',
    label: 'Sorbian languages',
  },
  {
    code: 'wln',
    label: 'Walloon',
  },
  {
    code: 'wol',
    label: 'Wolof',
  },
  {
    code: 'xal',
    label: 'Kalmyk; Oirat',
  },
  {
    code: 'xho',
    label: 'Xhosa',
  },
  {
    code: 'yao',
    label: 'Yao',
  },
  {
    code: 'yap',
    label: 'Yapese',
  },
  {
    code: 'yid',
    label: 'Yiddish',
  },
  {
    code: 'yor',
    label: 'Yoruba',
  },
  {
    code: 'ypk',
    label: 'Yupik languages',
  },
  {
    code: 'zap',
    label: 'Zapotec',
  },
  {
    code: 'zbl',
    label: 'Blissymbols; Blissymbolics; Bliss',
  },
  {
    code: 'zen',
    label: 'Zenaga',
  },
  {
    code: 'zgh',
    label: 'Standard Moroccan Tamazight',
  },
  {
    code: 'zha',
    label: 'Zhuang; Chuang',
  },
  {
    code: 'znd',
    label: 'Zande languages',
  },
  {
    code: 'zul',
    label: 'Zulu',
  },
  {
    code: 'zun',
    label: 'Zuni',
  },
  {
    code: 'zxx',
    label: 'No linguistic content; Not applicable',
  },
  {
    code: 'zza',
    label: 'Zaza; Dimili; Dimli; Kirdki; Kirmanjki; Zazaki',
  },
];
