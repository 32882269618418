import React from 'react';
import ContentContainer from 'terra-content-container';
import classNames from 'classnames/bind';
import styles from './ScrollableContainer.module.scss';

const cx = classNames.bind(styles);

const ScrollableContainer = ({ children }) => (
  <ContentContainer className={cx('scroll-container')}>
    {children}
  </ContentContainer>
);

export default ScrollableContainer;
