import React, { useState } from 'react';
import { Form } from 'react-final-form';
import { taxonomyCodes } from '../mock-data/taxonomyCodes';
import { placesOfService } from '../mock-data/placeOfService';
import ReferralForm from '../components/Referral/ReferralForm';
import ReferralResult from '../components/Referral/ReferralResult';
import useReferral from '../components/Referral/useReferral';
import useEligibility from '../components/Eligibility/useEligibility';

const Referral = () => {
  const {
    data: {
      coverageDetails: { lineOfBusiness },
    },
  } = useEligibility();
  const [submittedValues, setSubmittedValues] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [cityValue, setCityValue] = useState(null);
  const [stateValue, setStateValue] = useState(null);
  const { makeRequest, data: referralData, isLoading, isError } = useReferral();
  const [filteredPlacesOfService, setFilteredPlacesOfService] = useState(
    placesOfService
  );
  const [placeOfServiceValue, setPlaceOfServiceValue] = useState(null);
  const [specialtyTypeValue, setSpecialtyTypeValue] = useState(null);
  const submitForm = (values) => {
    setSubmittedValues(values);
    setSubmitted(true);

    const taxonomyCode =
      values.specialtyType && values.specialtyType.split('-')[0];

    let serviceCodes;
    if (values.procedureCodes) {
      if (typeof values.procedureCodes === 'string') {
        serviceCodes = [values.procedureCodes.split(' -')[0]];
      } else {
        serviceCodes = values.procedureCodes.map(
          (codeWithDesc) => codeWithDesc.split(' -')[0]
        );
      }
    }
    const {
      facilityName,
      firstName,
      lastName,
      npi,
      postalCode,
      city,
      state,
      language,
      referredToPlaceOfService,
    } = values;
    const search = {
      facilityName,
      provider: { firstName, lastName, npi },
      postalCode,
      city,
      state,
      languagePreference: language,
    };
    const modifiedValues = {
      taxonomyCode,
      serviceCodes,
      referredToPlaceOfService,
      search,
    };
    makeRequest(modifiedValues);
  };

  // When the specialty type has specific places of service, clear the input if necessary and show only valid places
  const onSpecialtyTypeChange = (value) => {
    const taxonomyCode = taxonomyCodes.find(
      (code) => code.codeClassification === value
    );
    if (!taxonomyCode) return;
    // set this so we can pass the value to the form as it's possible it may require a procedure code
    setSpecialtyTypeValue(taxonomyCode);
    let filteredPlaces = placesOfService;
    if (
      taxonomyCode.placeOfService !== null &&
      taxonomyCode.placeOfService !== ''
    ) {
      const places = taxonomyCode.placeOfService
        .split(',')
        .map((place) => place.trim());
      filteredPlaces = placesOfService.filter((placeOfService) =>
        places.includes(placeOfService.code)
      );
    }
    setFilteredPlacesOfService(filteredPlaces);
    if (!filteredPlaces.find((place) => place.code === placeOfServiceValue)) {
      setPlaceOfServiceValue(null);
    }
  };
  // When either a city or a state is selected, zip code should be required
  const onCityOrStateChange = (value, componentName) => {
    if (componentName === 'city') {
      setCityValue(value);
    } else {
      setStateValue(value);
    }
  };

  const renderForm = ({ handleSubmit }) => {
    return (
      <ReferralForm
        handleSubmit={handleSubmit}
        onSpecialtyTypeChange={onSpecialtyTypeChange}
        onCityOrStateChange={onCityOrStateChange}
        setPlaceOfServiceValue={setPlaceOfServiceValue}
        filteredPlacesOfService={filteredPlacesOfService}
        isProcedureCodeRequired={specialtyTypeValue?.isProcedureCodeRequired}
        isPlaceOfServiceRequired={specialtyTypeValue?.placeOfService}
        isZipCodeRequired={cityValue || stateValue}
      />
    );
  };

  return (
    <>
      {submitted ? (
        <ReferralResult
          isLoading={isLoading}
          isError={isError}
          {...submittedValues}
          referralData={referralData}
          newSearch={() => {
            setSubmitted(false);
            setSubmittedValues(null);
            setSpecialtyTypeValue(null);
            setPlaceOfServiceValue(null);
            setCityValue(null);
            setStateValue(null);
          }}
          previous={() => {
            setSubmitted(false);
          }}
        />
      ) : (
        <Form
          initialValues={submittedValues}
          onSubmit={submitForm}
          render={renderForm}
          validate={(values) => {
            const errors = {};
            // need to update this here before form is submitted since we can't access the input from outside the component
            const taxonomyCode = taxonomyCodes.find(
              (code) => code.codeClassification === values.specialtyType
            );
            if (
              Number(lineOfBusiness) === 1 &&
              taxonomyCode &&
              taxonomyCode.isProcedureCodeRequired &&
              !values.procedureCodes
            ) {
              errors.procedureCodes = 'Required for Specialty Type.';
            }
            if (
              Number(lineOfBusiness) === 1 &&
              taxonomyCode &&
              taxonomyCode?.placeOfService !== null &&
              taxonomyCode?.placeOfService !== ''
            ) {
              if (
                !taxonomyCode.placeOfService.includes(
                  values.referredToPlaceOfService
                )
              ) {
                errors.referredToPlaceOfService =
                  'Required for Specialty Type.';
              }
            }

            // check if the values is an array because it could be a single value coming from the single-select
            if (
              values.procedureCodes &&
              Array.isArray(values.procedureCodes) &&
              values.procedureCodes.length > 3
            ) {
              errors.procedureCodes =
                'You have exceeded the maximum of 3 procedure codes.';
            }
            if (!values.specialtyType) {
              errors.specialtyType = 'Specialty Type is Required.';
            }

            // check if a city or state is selected. if they are, then postal code is required
            if ((values.city || values.state) && !values.postalCode) {
              errors.postalCode =
                'ZIP Code is required if searching by city or state.';
            }
            return errors;
          }}
        />
      )}
    </>
  );
};

export default Referral;
