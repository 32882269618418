import React, { useState } from 'react';
import Grid from 'terra-grid';
import Button from 'terra-button';
import List, { Item } from 'terra-list';

import Divider from 'terra-divider';

import Spacer from 'terra-spacer';

import Alert from 'terra-alert';
import GapInCare from './GapInCare';
import useGapsInCare from './useGapsInCare';
import LoadingContainer from '../LoadingContainer';

const { Column, Row } = Grid;

const GapsInCare = ({ showDetailsTitle }) => {
  const { data, isLoading, isError } = useGapsInCare();
  const { gapsInCare, hasGapsInCare } = data || {};
  const [allExpanded, setAllExpanded] = useState(false);

  if (hasGapsInCare === false)
    return (
      <Alert type="success" title=" ">
        There are no gaps in care for this member
      </Alert>
    );

  return (
    <Grid>
      {hasGapsInCare === true && (
        <Alert type="warning" title=" ">
          Potential gaps in care found
        </Alert>
      )}

      {showDetailsTitle && (
        <Spacer margin="medium none">
          <Divider />
        </Spacer>
      )}
      <Row>
        <Column tiny={8}>
          {showDetailsTitle && <strong>Gaps in Care Details</strong>}
        </Column>
        <Column tiny={4}>
          {data && (
            <Button
              onClick={() => setAllExpanded(!allExpanded)}
              variant="de-emphasis"
              text={`${
                allExpanded ? 'Collapse All details' : 'Expand All Details'
              }`.toUpperCase()}
            />
          )}
        </Column>
      </Row>

      <Row>
        <Column tiny={12}>
          <List dividerStyle="standard" paddingStyle="standard">
            <LoadingContainer
              isData={!!data}
              isLoading={isLoading}
              isError={isError}
              errorMessage="Error Loading Gaps In Care"
            />
            {gapsInCare &&
              gapsInCare.map((gapInCareData) => (
                <Item key={gapInCareData.message}>
                  <GapInCare allExpanded={allExpanded} {...gapInCareData} />
                </Item>
              ))}
          </List>
        </Column>
      </Row>
    </Grid>
  );
};

export default GapsInCare;
