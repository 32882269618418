import { useContext } from 'react';
import useSWR from 'swr';
import fetcher from '../../Fetcher';
import { GlobalStore } from '../../ContextWrapper';
import { ELIGIBILITY_CHECK_ENDPOINT } from '../../routes';

const useEligibility = (config = {}) => {
  const { patient, setPatient, isAuthenticated, user } = useContext(
    GlobalStore
  );
  // when checking eligibility, we need to remove the subscriberId of the previous patient since that
  // gets written as the result of a successful eligibility
  const { subscriberId, ...patientWithoutSubscriberId } = patient;
  const { data, error } = useSWR(
    isAuthenticated
      ? () => [
          ELIGIBILITY_CHECK_ENDPOINT,
          JSON.stringify(patient),
          JSON.stringify(user),
        ]
      : null,
    (url) => fetcher(url, { patient: patientWithoutSubscriberId, user }),
    {
      revalidateOnMount: false,
      ...config,
      onErrorRetry: (err, key, cfg, revalidate, { retryCount }) => {
        // object keyed by retryCount with the value being the number of seconds to wait before retrying
        const retryTimes = {
          1: 5,
          2: 10,
          3: 30,
          4: 60,
          5: 120,
        };
        if (err.status === 400 || err.status === 422) {
          // 400 error status are specific errors returned by the API.
          // These are the validation errors where we do NOT want to retry at all...
          const errorStrings = [
            'your search returned no results.',
            'member was found, but there is no active coverage',
            'based upon the search type performed we are unable to find a unique match',
            'this plan is administered by a different payer',
            'the date of birth you entered does not match our records',
            'resource not found',
          ];
          const message = err?.info?.message?.toLowerCase();
          if (errorStrings.some((s) => message?.startsWith(s))) {
            return;
          }
        }
        // retry a max of 5 times
        if (retryCount > 5) return;

        // if we're here, wait a certain amount of seconds before retrying based on the retryCount
        const seconds = retryTimes[retryCount];
        setTimeout(() => revalidate({ retryCount }), seconds * 1000);
      },
    }
  );
  if (
    data?.coverageDetails?.subscriberId &&
    patient?.subscriberId !== data?.coverageDetails?.subscriberId
  ) {
    const { ...updatedPatient } = patient;
    updatedPatient.subscriberId = data.coverageDetails.subscriberId;
    setPatient(updatedPatient);
  }
  return {
    data,
    isLoading: !error && !data,
    isError: error,
  };
};

export default useEligibility;
