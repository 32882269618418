import React from 'react';
import Spacer from 'terra-spacer';
import ContentContainer from 'terra-content-container';
import Heading from 'terra-heading';
import Arrange from 'terra-arrange';
import classNames from 'classnames/bind';
import Image from 'terra-image';
import Grid from 'terra-grid';
import Hyperlink from 'terra-hyperlink';
import styles from './PremiumDesignationModal.module.scss';
import {
  renderDescription,
  renderHearts,
  premiumDesignationDescriptions,
} from '../Referral/PremiumDesignationUtils';
import PremiumDesignationCard from '../Referral/PremiumDesignationCard';

const { Column, Row } = Grid;
const cx = classNames.bind(styles);

const PremiumDesignationModal = (props) => {
  const { providerName, address1, address2, premiumDesignation } = props;

  const heartImage = renderHearts(premiumDesignation?.shortDescription);

  return (
    <ContentContainer
      fill
      header={
        <Arrange
          className={cx('align-text', 'full-height', 'variable-section')}
          fill={
            <Spacer margin="medium none">
              {providerName && <Heading level={3}>{providerName}</Heading>}
              <div>
                <span>
                  {address1} {address2}
                </span>
              </div>
              <strong>{premiumDesignation?.shortDescription}</strong>
              {heartImage && (
                <Spacer margin="small">
                  <Image
                    src={heartImage}
                    height="24"
                    alt={premiumDesignation?.shortDescription}
                  />
                </Spacer>
              )}
              <Spacer margin="small">
                <div>
                  {renderDescription(
                    premiumDesignation?.shortDescription,
                    premiumDesignation?.fullDescription
                  )}
                </div>
              </Spacer>
            </Spacer>
          }
        />
      }
      footer={
        <Spacer margin="medium">
          <Hyperlink
            href="https://myuhc.com/content/myuhc/Member/Assets/Pdfs/Geoaccess/UnitedHealth_Premium_Program_Overview.pdf"
            variant="external"
          >
            LEARN MORE ABOUT THE PREMIUM PROGRAM
          </Hyperlink>
        </Spacer>
      }
    >
      <Spacer margin="large">
        <strong>Rating Scale</strong>
        <Row>
          {premiumDesignationDescriptions?.map((description) => (
            <Column key={description} tiny={6}>
              <Spacer padding="medium">
                <PremiumDesignationCard
                  className={cx('align-text')}
                  shortDescription={description}
                />
              </Spacer>
            </Column>
          ))}
        </Row>
        <Spacer margin="medium none">
          <i>
            The UnitedHealth Premium® program evaluates physicians based on
            quality and cost-efficiency criteria to help members make more
            informed and appropriate choices for their health care.{' '}
            <strong>
              It&apos;s intended only as a guide and should not be the sole
              factor when selecting a physician. Designations have a risk of
              error and members should discuss designations with a physician
              before choosing one.
            </strong>{' '}
            The fact that a physician does not have a Premium Care Physician or
            Quality Care Physician designation does not mean that the physician
            does not provide quality health care services. All physicians in the
            UnitedHealthcare network have met certain minimum credentialing
            requirements (separate from the Premium program).
          </i>
        </Spacer>
      </Spacer>
    </ContentContainer>
  );
};

export default PremiumDesignationModal;
