import React, { useState, useContext } from 'react';
import Base from 'terra-base';
import ApplicationNavigation from 'terra-application/lib/application-navigation';
import { Router, navigate } from '@reach/router';
import classNames from 'classnames/bind';
import ApplicationLoadingOverlay, {
  ApplicationLoadingOverlayProvider,
} from 'terra-application/lib/application-loading-overlay';
import Cookies from 'js-cookie';
import Alert from 'terra-alert';
import StatusView from 'terra-status-view';
import ModalManager from 'terra-modal-manager';
import GapsInCare from './GapsInCare';
import PaCodeLookup from './PaCodeLookup';
import PatientOverview from './PatientOverview';
import UHCWidget from './UHCWidget';
import Referral from './Referral';
import LabOrder from './Labs';
import Settings from './Settings';
import useEligibility from '../components/Eligibility/useEligibility';
import { GlobalStore } from '../ContextWrapper';

import styles from './Layout.module.scss';
import useCerner from '../useCerner';

const cx = classNames.bind(styles);
const showPaSubmission = process.env.REACT_APP_SHOW_PA_WIDGET === '1';

const renderAlert = (isLoading, user) => {
  if (isLoading) return null;
  return (
    <Alert type="warning" title=" ">
      {user?.npi
        ? 'This patient is not eligible for Point of Care Assist services'
        : `Service unavailable. We could not determine the provider for the patient's current encounter`}
    </Alert>
  );
};

const getCookieBool = (cookieKey) => {
  const cookie = Cookies.get('tenant');
  if (cookie) {
    const tenant = JSON.parse(cookie);
    return tenant[cookieKey];
  }
  return false;
};

const getCookieString = (cookieKey, defaultValue) => {
  const cookie = Cookies.get('tenant');
  if (cookie) {
    const tenant = JSON.parse(cookie);
    const cookieValue = tenant[cookieKey];
    return cookieValue ?? defaultValue;
  }
  return defaultValue;
};

// currently, this turns off patientOverview for the state of Minnesota
const showPatientOverview = () => {
  const tenantZipCode = getCookieString('tenantZipCode', '')?.toString();
  if (
    tenantZipCode?.startsWith('55') ||
    (tenantZipCode?.startsWith('56') && !tenantZipCode?.startsWith('569'))
  ) {
    return false;
  }
  return true;
};

const titleConfig = {
  title: 'Dashboard',
};
const userConfig = {
  name: 'Example Patient',
  initials: 'EP',
};
const navigationItems = [
  {
    key: '/gaps-in-care',
    text: getCookieString('textGapsInCare', 'Gaps in Care'),
  },
  ...(showPatientOverview()
    ? [
        {
          key: '/patient-overview',
          text: getCookieString('textPatientOverview', 'Patient Overview'),
        },
      ]
    : []),
  {
    key: '/pa-code-lookup',
    text: getCookieString('textPaCodeLookup', 'PA Code Lookup'),
  },
  ...(showPaSubmission
    ? [
        {
          key: '/pa-submission',
          text: getCookieString('textPaSubmission', 'PA Submission'),
        },
      ]
    : []),
  ...(getCookieBool('showPaSearchUpdate')
    ? [
        {
          key: '/pa-casesearchupdate',
          text: getCookieString('textPaSearchUpdate', 'PA Case Search'),
        },
      ]
    : []),
  {
    key: '/labs',
    text: getCookieString('textLabs', 'Labs'),
  },
  {
    key: '/referral',
    text: getCookieString('textReferral', 'Referral'),
  },
];

const utilityItems = [
  {
    key: '/settings',
    text: 'Settings',
  },
];

function Layout() {
  const { data: eligible, isLoading: isEligibilityLoading } = useEligibility({
    revalidateOnMount: true,
  });
  const { isLoading: isCernerAuthLoading } = useCerner();
  const { isAuthenticated, user } = useContext(GlobalStore);

  if (isAuthenticated === false) {
    return (
      <TerraWrapper>
        <StatusView
          variant="error"
          title="401 Error"
          message="User is not authenticated. Please reload the page."
        />
      </TerraWrapper>
    );
  }

  return (
    <TerraWrapper isLoading={isCernerAuthLoading || isEligibilityLoading}>
      <div className={cx('layout')}>
        {eligible?.hasActiveCoverage === true ? (
          <Router>
            <GapsInCare path="/" />
            <GapsInCare path="gaps-in-care" />
            {showPatientOverview() && (
              <PatientOverview
                path="patient-overview"
                eligibilityState={eligible.state}
              />
            )}
            {showPaSubmission && (
              <UHCWidget
                widgetId="casesubmission"
                widgetName="PA Case Submission"
                path="pa-submission"
              />
            )}
            {getCookieBool('showPaSearchUpdate') && (
              <UHCWidget
                widgetId="casesearchandupdate"
                widgetName="PA Case Search"
                path="pa-casesearchupdate"
              />
            )}
            <PaCodeLookup path="pa-code-lookup" />
            <LabOrder path="labs" />
            <Referral path="referral" />
          </Router>
        ) : (
          renderAlert(isEligibilityLoading, user)
        )}
        <Router>
          <Settings path="settings" />
        </Router>
      </div>
    </TerraWrapper>
  );
}

const TerraWrapper = ({ children, isLoading }) => {
  const [activeKey, setActiveKey] = useState(
    (
      navigationItems.find((item) => item.key === window.location.pathname) ||
      navigationItems[0]
    ).key
  );

  const onSelectNavigationItem = (key) => {
    navigate(key);
    setActiveKey(key);
  };

  const onSelectUtilityItem = (key) => {
    navigate(key);
  };

  return (
    <Base locale="en">
      <ApplicationNavigation
        titleConfig={titleConfig}
        navigationItems={navigationItems}
        activeNavigationItemKey={activeKey}
        onSelectNavigationItem={onSelectNavigationItem}
        onSelectUtilityItem={onSelectUtilityItem}
        userConfig={getCookieBool('showSettings') ? userConfig : undefined}
        utilityItems={getCookieBool('showSettings') ? utilityItems : undefined}
      >
        <ModalManager>
          <ApplicationLoadingOverlayProvider>
            <ApplicationLoadingOverlay isOpen={isLoading} />
            {children}
          </ApplicationLoadingOverlayProvider>
        </ModalManager>
      </ApplicationNavigation>
    </Base>
  );
};
export default Layout;
