const API_ROUTE_PREFIX = '/api/poca/v1';
export const ROOT_URL = process.env.REACT_APP_API_URL;

export const TOKEN_EXCHANGE_ENDPOINT = '/oauth2/exchangeExternalToken';

// the following endpoints proxy through the server to the api that communicates with UHC
// these are the only endpoints that need the API_ROUTE_PREFIX prepended
export const ELIGIBILITY_CHECK_ENDPOINT = `${API_ROUTE_PREFIX}/eligibility/check`;

export const GAPS_IN_CARE_CHECK_ENDPOINT = `${API_ROUTE_PREFIX}/gapsInCare/check`;

export const PRIOR_AUTHORIZATION_INQUIRY_ENDPOINT = `${API_ROUTE_PREFIX}/pa/inquiry`;

export const PATIENT_OVERVIEW_ENDPOINT = `${API_ROUTE_PREFIX}/patientOverview`;

export const LABS_RANK_ENDPOINT = `${API_ROUTE_PREFIX}/labs/rank`;

export const REFERRAL_SEARCH_ENDPOINT = `${API_ROUTE_PREFIX}/referral/search`;

export const LAB_SELECTED_ENDPOINT = `${API_ROUTE_PREFIX}/outcome/lab-selected`;
export const CE_PROVIDER_SELECTED_ENDPOINT = `${API_ROUTE_PREFIX}/outcome/ce-provider-selected`;
export const CE_LAB_SELECTED_ENDPOINT = `${API_ROUTE_PREFIX}/outcome/ce-lab-selected`;
export const LOGGING_ENDPOINT = '/logging';
