import Cookies from 'js-cookie';

export const getTenantInfo = () => {
  const cookie = Cookies.get('tenant');
  const tenant = cookie
    ? JSON.parse(cookie)
    : {
        // default to Cerner's address if no tenant cookie is found
        tenantId: '00000000-0000-0000-0000-000000000000',
        tenantName: 'Test',
        tenantAddress: '8779 Hillcrest Rd',
        tenantZipCode: '64137',
        showSettings: false,
        showPaSearchUpdate: false,
      };
  const {
    tenantName: healthSystemName,
    tenantAddress: healthSystemAddress,
    tenantZipCode: healthSystemZip,
    tenantId,
  } = tenant;
  const locationId = `cerner code:${healthSystemName}|${healthSystemZip}|${healthSystemAddress}`;
  return {
    locationId,
    tenantId,
    healthSystemName,
    healthSystemAddress,
    healthSystemZip,
  };
};
