import { useContext, useState } from 'react';
import useSWR from 'swr';
import { isEqual } from 'lodash';
import fetcher from '../Fetcher';
import { GlobalStore } from '../ContextWrapper';
import useEligibility from '../components/Eligibility/useEligibility';
import { PRIOR_AUTHORIZATION_INQUIRY_ENDPOINT } from '../routes';

const useGapsInCare = () => {
  const { patient, user } = useContext(GlobalStore);
  const [paData, setPaData] = useState();
  const { data: eligibility } = useEligibility();
  const { state, hasActiveCoverage, coverageDetails, transactionId } =
    eligibility || {};
  const { lineOfBusiness } = coverageDetails || {};

  const tempHardcoded = {
    planIssueState: state,
    clientID: 'ClientID',
    clientGroup: 'EMR',
    clientBusiness: 'RxRevu',
  };

  const { data, error, mutate } = useSWR(
    state && paData && hasActiveCoverage
      ? () => [
          PRIOR_AUTHORIZATION_INQUIRY_ENDPOINT,
          JSON.stringify(patient),
          JSON.stringify(user),
          JSON.stringify(tempHardcoded),
          JSON.stringify(paData),
        ]
      : null,
    (url) =>
      fetcher(url, {
        patient,
        user,
        ...tempHardcoded,
        ...paData,
        lineOfBusiness,
        transactionId,
      })
  );

  const makeRequest = (requestData) => {
    if (isEqual(paData, requestData)) {
      mutate();
    } else {
      setPaData(requestData);
    }
  };

  return {
    makeRequest,
    data,
    isLoading: !error && !data && paData != null,
    isError: error,
  };
};

export default useGapsInCare;
