/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import classNames from 'classnames/bind';
import { v4 as uuidv4 } from 'uuid';
import styles from './UHCWidget.module.scss';
import useUHCWidget from './useUHCWidget';
import LoadingContainer from '../components/LoadingContainer';

const cx = classNames.bind(styles);

function UHCWidget(props) {
  const [launchId, setLaunchId] = useState(uuidv4());
  const { widgetName, widgetId } = props;
  const { launchUrl, isLoading, isError } = useUHCWidget(launchId, widgetId);

  const handleUHCMessage = (event) => {
    if (event.origin === process.env.REACT_APP_PA_WIDGET_URL && event.data) {
      const data = JSON.parse(event.data);
      if (data.Outcome === '0') {
        // re-load the current route to re-initialize everything
        window.location.reload();
      }
    }
  };

  useEffect(() => {
    window.addEventListener('message', handleUHCMessage);

    // clean up when unloading
    return () => {
      window.removeEventListener('message', handleUHCMessage);
    };
  }, []);

  if (isLoading || isError) {
    return (
      <LoadingContainer
        isLoading={isLoading}
        isError={isError}
        errorMessage={`Error initializing ${widgetName}`}
      />
    );
  }

  return (
    <iframe className={cx('widget')} title={`${widgetName}`} src={launchUrl} />
  );
}

export default UHCWidget;
