import React from 'react';
import Card from 'terra-card';
import Divider from 'terra-divider';
import Heading from 'terra-heading';
import IconClipboard from 'terra-icon/lib/icon/IconClipboard';
import IconPaperFolded from 'terra-icon/lib/icon/IconPaperFolded';
import List, { Item } from 'terra-list';

import HealthRecord from '../components/IHR/HealthRecord';
import Eligibility from '../components/Eligibility';

const PatientOverview = ({ eligibilityState }) => {
  return (
    <>
      <Heading level={2}>Patient Overview</Heading>
      <Divider />
      <List paddingStyle="standard">
        <Item>
          <IconPaperFolded /> <strong>Coverage</strong>
          <Card>
            <Card.Body>
              <Eligibility />
            </Card.Body>
          </Card>
        </Item>
        {eligibilityState !== 'MN' && (
          <Item>
            <IconClipboard /> <strong>Health Record</strong>
            <Card>
              <Card.Body>
                <HealthRecord />
              </Card.Body>
            </Card>
          </Item>
        )}
      </List>
    </>
  );
};

export default PatientOverview;
