import React, { useContext } from 'react';
import Heading from 'terra-heading';
import Grid from 'terra-grid';
import Card from 'terra-card';
import Spacer from 'terra-spacer';
import Arrange from 'terra-arrange';
import IconSuccess from 'terra-icon/lib/icon/IconSuccess';
import classNames from 'classnames/bind';
import { cloneDeep } from 'lodash';
import ResultCardImage from '../ResultCardImage';
import styles from './LabsResultCard.module.scss';
import PreferredLab from '../../images/PreferredLab.png';
import { Details, CopyButton } from '../ResultCard';
import { sendLabSelected } from '../../services/outcomes';
import { GlobalStore } from '../../ContextWrapper';
import useEligibility from '../Eligibility/useEligibility';

const cx = classNames.bind(styles);

const { Column, Row } = Grid;

const sendOutcome = (patient, user, data, transactionId) => {
  const { result, procedureCode, diagnosisCode } = data;
  // pull properties from a deep clone of result since we're deleting some keys
  // and we don't want to modify the original result object
  const { lab, ...rest } = cloneDeep(result);
  lab.postalCode = lab.zipCode;
  delete lab.zipCode;
  lab.address = lab.addressLine;
  delete lab.addressLine;
  const outcomeData = {
    ...lab,
    serviceCode: procedureCode,
    diagnosisCode,
    transactionId,
    ...rest,
  };
  sendLabSelected(patient, user, outcomeData);
};

const LabsResultCard = (props) => {
  const { copyDetails, result } = props;
  const { user, patient } = useContext(GlobalStore);
  const { data: eligibility } = useEligibility();
  const { transactionId } = eligibility || {};

  const {
    costScale,
    distance,
    preferred,
    lab: {
      addressLine: address1,
      city,
      name,
      phoneNumber,
      state,
      zipCode: postalCode,
    },
  } = result;

  const address2 = [city, state, postalCode].filter(Boolean).join(', ');
  const copyButtonText = [name, address1, address2].filter(Boolean).join('\n');

  return (
    <Card>
      <Card.Body>
        <Row>
          <Column tiny={3}>
            <Arrange
              className={cx('align-text', 'full-height')}
              align="center"
              fill={
                <>
                  <Heading level={3} className={cx('overflow')}>
                    {costScale}
                  </Heading>
                  {costScale && costScale !== 'Unable to determine cost' && (
                    <span>Estimated Out of Pocket Cost</span>
                  )}
                </>
              }
            />
          </Column>
          <Column tiny={4}>
            <Arrange
              className={cx('full-height')}
              align="center"
              fill={
                <>
                  <div>{name}</div>
                  <Details
                    address1={address1}
                    address2={address2}
                    phoneNumber={phoneNumber}
                    distance={distance}
                  />
                </>
              }
            />
          </Column>
          <Column tiny={3}>
            <Arrange
              className={cx('full-height')}
              align="center"
              fill={
                <>
                  <div>
                    <IconSuccess
                      height="16"
                      width="16"
                      ariaLabel="In Network"
                    />
                    <Spacer isInlineBlock padding="small">
                      In Network
                    </Spacer>
                  </div>

                  {preferred && (
                    <ResultCardImage
                      image={PreferredLab}
                      text="Preferred Lab"
                    />
                  )}
                </>
              }
            />
          </Column>
          <Column tiny={2}>
            <Arrange
              className={cx('full-height')}
              fill={
                <CopyButton
                  copyText={copyButtonText}
                  copyDetails={copyDetails}
                  onClick={() => {
                    // only send outcome once
                    if (copyDetails.copiedKey !== copyDetails.copyId) {
                      sendOutcome(patient, user, props, transactionId);
                    }
                  }}
                />
              }
            />
          </Column>
        </Row>
      </Card.Body>
    </Card>
  );
};
export default LabsResultCard;
