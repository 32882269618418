/* eslint-disable no-console */
import { useEffect, useContext, useState } from 'react';
import FHIR from 'fhirclient';
import Cookies from 'js-cookie';
import { navigate } from '@reach/router';
import { GlobalStore } from './ContextWrapper';
import { ROOT_URL, TOKEN_EXCHANGE_ENDPOINT } from './routes';
import { logToServer } from './services/serverLogger';

export const POCA_AUTH_OBJECT_IDENTIFIER = 'poca-auth-storage';

// This login function should ONLY be used in development.
const login = () => {
  FHIR.oauth2.authorize({
    iss:
      'https://launch.smarthealthit.org/v/r4/sim/eyJoIjoiMSIsImIiOiJkMTkzNGRhZi00Nzc1LTRiNTUtOTk1Yi00YmUyYWEwZTM5NWIiLCJqIjoiMSIsImFsZyI6IkhTMjU2In0/fhir',
    client_id: 'example-id',
    scope:
      'patient/Patient.read user/Patient.read user/Practitioner.read patient/AllergyIntolerance.read patient/Observation.read patient/Encounter.read launch online_access openid profile',
  });
};

const getNpi = (user) => {
  // attempt to get the npi 2 different ways. Get both and npi1 takes precedence
  // Method 1 came from Cerner's engineers and it's how it appears in the Citrix environment
  const npi1 = user.identifier?.find(
    (id) => id.type?.text === 'National Provider Identifier'
  )?.value;
  if (npi1) return npi1;

  // Method 2 comes from how it appears when running in development using the login method above
  return user.identifier?.find(
    (id) => id.system === 'http://hl7.org/fhir/sid/us-npi'
  )?.value;
};

// returns a practitioner if they have an npi on their record
const getPractitionerWithNpi = async (uri, client, type) => {
  try {
    const drType = type?.length ? type[0].text : 'N/A';
    const practitioner = await client.request(uri);
    if (practitioner) {
      await logToServer(
        JSON.stringify(practitioner),
        'CHECKING_NPI_ON_THIS_PRACTITIONER'
      );
      await logToServer(drType, 'PRACTITIONER_TYPE');
      const practitionerNpi = getNpi(practitioner);
      if (practitionerNpi) {
        await logToServer(JSON.stringify(practitioner), 'FOUND_PRACTITIONER');
      } else {
        await logToServer(
          'could not get NPI from the practitioner',
          'NO_NPI_FOUND'
        );
      }
      return practitioner;
    }
  } catch (err) {
    await logToServer(JSON.stringify(err), 'ERROR_GETTING_PRACTITIONER');
    console.error(err);
  }
  return null;
};

function foundPhysician(participant) {
  // check the physician's period start and end date to make sure they are active on this encounter.
  // active is signified by no period end date or a period end date in the future
  const periodEnd = participant.period?.end
    ? new Date(participant.period.end)
    : null;
  const currentDate = new Date();
  return (
    (!periodEnd || currentDate <= periodEnd) &&
    participant?.type?.length &&
    participant?.type[0].coding.find((code) => code.code === this)
  );
}

const getUserDataToStore = async (user, client) => {
  try {
    // try to get npiOverride
    const cookie = Cookies.get('tenant');
    let npi = cookie ? JSON.parse(cookie)?.npiOverride : '';
    if (!npi) {
      npi = getNpi(user);
    }
    // default user name object to the signed in user
    let userName = user?.name;

    // if we don't have an npi, try to get it from the encounter.participant array
    if (!npi) {
      await logToServer(
        'No npi found. need to search the participants...',
        'SEARCHING'
      );
      const {
        body: encounter,
        response: encounterResponse,
      } = await client.encounter.read({ includeResponse: true });
      await logToServer(
        encounterResponse.headers.get('X-Request-Id'),
        'Encounter X-Request-Id'
      );
      await logToServer(
        JSON.stringify(encounter.participant),
        'ALL_PARTICIPANTS'
      );
      if (encounter.participant?.length === 1) {
        // only 1 participant so pick it
        const participant = encounter.participant[0];
        const practitioner = await client.request(
          `/${participant.individual.reference}`
        );
        await logToServer(JSON.stringify(practitioner), 'FOUND_PRACTITIONER');
        npi = getNpi(practitioner);
        if (npi) {
          // we have a valid npi for this practitioner so set the userName to their name
          userName = practitioner.name;
        }
      } else if (encounter.participant?.length > 1) {
        // multiple participants. need to see which one
        // look for attending physician, then admitting, then primary performer, then consulting physicians, then referring...
        const codes = ['ATND', 'ADM', 'PPRF', 'CON', 'REF'];
        for (let index = 0; index < codes.length; index += 1) {
          const physician = encounter.participant.find(
            foundPhysician,
            codes[index]
          );
          if (physician) {
            /* eslint-disable no-await-in-loop */
            const docWithNpi = await getPractitionerWithNpi(
              `/${physician.individual.reference}`,
              client,
              physician.type
            );
            if (docWithNpi) {
              // this doctor has an npi so get it and set their userName
              npi = getNpi(docWithNpi);
              userName = docWithNpi.name;
              break;
            }
          }
        }
      }
    }

    if (!npi) {
      await logToServer('No npi available for this encounter', 'NO_NPI_FOUND');
    }
    const userData = {
      id: user.id,
      firstName: userName[0].given[0],
      lastName: userName[0].family,
      npi,
      encounterId: client.encounter.id,
    };
    return userData;
  } catch (err) {
    await logToServer(JSON.stringify(err), 'ERROR_IN_GET_USER_DATA_TO_STORE');
    return {};
  }
};

const useCerner = () => {
  const { setPatient, setClient, setUser, setIsAuthenticated } = useContext(
    GlobalStore
  );

  const [isLoading, setLoading] = useState(false);
  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const client = await FHIR.oauth2.ready();
        setClient(client);
        const {
          body: patient,
          response: patientResponse,
        } = await client.patient.read({ includeResponse: true });
        const { body: user, response: userResponse } = await client.user.read({
          includeResponse: true,
        });
        await logToServer(
          patientResponse.headers.get('X-Request-Id'),
          'Patient X-Request-Id'
        );
        await logToServer(
          userResponse.headers.get('X-Request-Id'),
          'User X-Request-Id'
        );
        const userData = await getUserDataToStore(user, client);
        setUser(userData);

        try {
          // Get OAuth token
          const auth = await fetch(`${ROOT_URL}${TOKEN_EXCHANGE_ENDPOINT}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              service_url: client.state.serverUrl,
              token: client.state.tokenResponse.access_token,
            }),
          }).then((response) => {
            if (response.ok) {
              return response.json();
            }
            return Promise.reject(response);
          });

          localStorage.setItem(
            POCA_AUTH_OBJECT_IDENTIFIER,
            JSON.stringify(auth)
          );
        } catch (err) {
          console.error('Error setting auth', err);
          await logToServer(JSON.stringify(err), 'ERROR_SETTING_AUTH');
          throw err;
        }

        const officialPatientName = patient.name.find(
          (name) => name.use === 'official'
        );
        const patientName = officialPatientName || patient.name[0];

        // http://hl7.org/fhir/patient-examples.html to look at the structure of the Patient object
        const patientData = {
          dob: patient.birthDate,
          firstName: patientName.given[0],
          lastName: patientName.family,
          patientId: patient.id,
        };
        setPatient(patientData);
        setIsAuthenticated(true);
        if (process.env.NODE_ENV === 'development') {
          // navigate to /settings when running locally in development mode
          navigate('/settings', { replace: true });
        }
      } catch (err) {
        setIsAuthenticated(false);

        if (process.env.NODE_ENV === 'development') {
          login();
        }
      }

      setLoading(false);
    })();
  }, [setPatient, setClient, setIsAuthenticated, setUser]);

  return { isLoading };
};

export default useCerner;
