import React from 'react';
import 'cerner-smart-embeddable-lib';
import { SWRConfig } from 'swr';

import Layout from './views/Layout';

// set the height of the mPage to a fixed height
window.CernerSmartEmbeddableLib.calcFrameHeight = function () {
  return 700;
};

function App() {
  return (
    <SWRConfig
      value={{
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
      }}
    >
      <Layout />
    </SWRConfig>
  );
}

export default App;
