/* eslint-disable no-unused-expressions */
import React from 'react';
import classNames from 'classnames/bind';
import Tier1 from '../../images/tier1.png';
import singleHeart from '../../images/1heart.png';
import doubleHearts from '../../images/2hearts.png';
import greyHearts from '../../images/greyhearts.png';
import transparentHearts from '../../images/transparenthearts.png';
import styles from './PremiumDesignationUtils.module.scss';

const cx = classNames.bind(styles);

const premiumDesignations = {
  'Premium Care Physician': {
    hearts: doubleHearts,
    primaryDescriptionMatches: ['quality', 'cost-efficient care'],
    fullDescription:
      'The physician meets the UnitedHealth Premium program quality and cost-efficient care criteria.',
    renderedDescription: (
      <span>
        The physician meets the UnitedHealth Premium program{' '}
        <span className={cx('primary-text')}>quality</span> and{' '}
        <span className={cx('primary-text')}>cost-efficient care</span>{' '}
        criteria.
      </span>
    ),
  },
  'Quality Care Physician': {
    hearts: singleHeart,
    primaryDescriptionMatches: ['quality care'],
    secondaryDescriptionMatches: ['cost-efficient care', 'cost-efficiency'],
    fullDescription:
      "The physician meets the UnitedHealth Premium program quality care criteria but does not meet the program's cost-efficient care criteria or is not evaluated for cost-efficient care.",
    renderedDescription: (
      <span>
        The physician meets the UnitedHealth Premium program{' '}
        <span className={cx('primary-text')}>quality care</span> criteria but
        does not meet the program&apos;s{' '}
        <span className={cx('secondary-text')}>cost-efficient care</span>{' '}
        criteria or is not evaluated for{' '}
        <span className={cx('secondary-text')}>cost-efficient care</span>.
      </span>
    ),
  },
  'Does Not Meet Premium Quality Criteria': {
    hearts: greyHearts,
    secondaryDescriptionMatches: ['quality'],
    fullDescription:
      'The physician does not meet the UnitedHealth Premium program quality criteria so the physician is not eligible for a Premium designation.',
    renderedDescription: (
      <span>
        The physician does not meet the UnitedHealth Premium program{' '}
        <span className={cx('secondary-text')}>quality</span> criteria so the
        physician is not eligible for a Premium designation.
      </span>
    ),
  },
  'Not Evaluated For Premium Care': {
    fullDescription:
      "The physician's specialty is not evaluated in the UnitedHealth Premium program, the physician does not have enough claims data for program evaluation or the physician's program evaluation is in process.",
    renderedDescription: (
      <span>
        The physician&apos;s specialty is not evaluated in the UnitedHealth
        Premium program, the physician does not have enough claims data for
        program evaluation or the physician&apos;s program evaluation is in
        process.
      </span>
    ),
    hearts: transparentHearts,
  },
};

export const premiumDesignationDescriptions = Object.keys(premiumDesignations);

export const renderHearts = (shortDescription) => {
  return premiumDesignations[shortDescription]?.hearts || null;
};

export const renderDescription = (shortDescription, fullDescription) => {
  const { primaryDescriptionMatches, secondaryDescriptionMatches } =
    premiumDesignations[shortDescription] || {};

  const withClassName = (match, className) =>
    `<span class="${className}">${match}</span>`;

  // If a description is passed in, use that for the text, otherwise use hardcoded text
  let descriptionWithColor = fullDescription;
  if (!descriptionWithColor) {
    return premiumDesignations[shortDescription]?.renderedDescription;
  }

  primaryDescriptionMatches?.forEach((match) => {
    descriptionWithColor = descriptionWithColor.replace(
      match,
      withClassName(match, cx('primary-text'))
    );
  });

  secondaryDescriptionMatches?.forEach((match) => {
    descriptionWithColor = descriptionWithColor.replace(
      match,
      withClassName(match, cx('secondary-text'))
    );
  });

  // eslint-disable-next-line react/no-danger
  return <span dangerouslySetInnerHTML={{ __html: descriptionWithColor }} />;
};

export const renderTier = (tier) => {
  switch (tier) {
    case 'Tier 1':
      return Tier1;
    default:
      return null;
  }
};
