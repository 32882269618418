import React, { useMemo, useState } from 'react';
import Heading from 'terra-heading';
import Divider from 'terra-divider';
import Grid from 'terra-grid';
import Button from 'terra-button';
import Spacer from 'terra-spacer';
import List, { Item } from 'terra-list';
import ActionHeader from 'terra-action-header';
import ScrollableContainer from '../ScrollableContainer';
import ReferralResultCard from './ReferralResultCard';
import PACodeLookupResultCard from '../PACodeLookupResultCard';
import Disclaimer from '../Disclaimer';
import LoadingContainer from '../LoadingContainer';

const { Column, Row } = Grid;

const seperateResults = (results) => {
  const desiredResults = [];
  const otherResults = [];
  if (results) {
    results.forEach((result) => {
      if (result.desired) desiredResults.push(result);
      else otherResults.push(result);
    });
  }
  return [desiredResults, otherResults];
};

const ReferralResult = ({
  specialtyType,
  newSearch,
  referralData,
  procedureCodes,
  isLoading,
  isError,
}) => {
  const [copiedKey, setCopiedKey] = useState(null);

  const [desiredResults, otherResults] = useMemo(
    () => seperateResults(referralData?.results),
    // eslint-disable-next-line
    [referralData?.results]
  );

  const modifiedProcedureCodes =
    typeof procedureCodes === 'string' ? [procedureCodes] : procedureCodes;
  const renderDesiredCostCol = desiredResults.some((v) => v.cost?.length > 0);
  const renderOtherCostCol = otherResults.some((v) => v.cost?.length > 0);
  return (
    <>
      <Heading level={2}>Referral Service Results</Heading>
      <strong>Searched Specialty</strong>
      <ActionHeader title={specialtyType}>
        <Button text="New Search" variant="emphasis" onClick={newSearch} />
      </ActionHeader>

      <Row>
        <Column tiny={12}>
          <List>
            {referralData?.paDetermination &&
              modifiedProcedureCodes &&
              modifiedProcedureCodes.map((codeDescription) => {
                return (
                  <Item key={codeDescription}>
                    <PACodeLookupResultCard
                      codeDescription={codeDescription}
                      lookupResult={referralData.paDetermination.find(
                        (element) =>
                          element.serviceCodes ===
                          codeDescription.split(' -')[0]
                      )}
                    />
                  </Item>
                );
              })}
          </List>
        </Column>
      </Row>
      <Spacer margin="large none">
        <Divider />
      </Spacer>
      {(isLoading || desiredResults.length > 0) && (
        <>
          <strong>Search Result{desiredResults.length > 1 && 's'}</strong>
          <ScrollableContainer>
            <List paddingStyle="compact">
              <LoadingContainer isLoading={isLoading} />
              {desiredResults.map((result) => (
                <Item key={result.npi}>
                  <ReferralResultCard
                    key={result.npi}
                    copyDetails={{
                      copyId: result.npi,
                      copiedKey,
                      setCopiedKey,
                    }}
                    result={result}
                    renderCostColumn={renderDesiredCostCol}
                  />
                </Item>
              ))}
            </List>
          </ScrollableContainer>
          <Spacer margin="medium none">
            <Divider />
          </Spacer>
        </>
      )}
      <strong>
        Alternative Result
        {otherResults.length > 1 && 's'}
      </strong>
      <ScrollableContainer>
        <List paddingStyle="compact">
          <LoadingContainer
            isLoading={isLoading}
            isError={isError}
            isEmpty={otherResults.length === 0}
            errorMessage="Error Loading Referral Results"
          />
          {otherResults.map((result) => (
            <Item key={result.npi}>
              <ReferralResultCard
                key={result.npi}
                specialtyType={specialtyType}
                procedureCodes={procedureCodes}
                copyDetails={{ copyId: result.npi, copiedKey, setCopiedKey }}
                result={result}
                renderCostColumn={renderOtherCostCol}
              />
            </Item>
          ))}
        </List>
      </ScrollableContainer>
      {/* only need the first disclaimer from paDetermination because the disclaimer is the same for all. It may change in the future which is why it comes from the api */}
      <Disclaimer
        title="PRIOR AUTHORIZATION DISCLAIMERS"
        disclaimers={referralData?.paDetermination
          ?.slice(0, 1)
          .map((determination) => determination.disclaimerText)}
      />
      <Disclaimer
        title="REFERRAL SERVICE DISCLAIMERS"
        disclaimers={referralData?.disclaimers?.map(
          (disclaimer) => disclaimer.disclaimerMessage
        )}
      />
    </>
  );
};

export default ReferralResult;
