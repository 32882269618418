import React from 'react';
import { Field, useForm } from 'react-final-form';
import ActionFooter from 'terra-action-footer';
import Button from 'terra-button';
import DatePickerField from 'terra-date-picker/lib/DatePickerField';
import Divider from 'terra-divider';
import InputField from 'terra-form-input/lib/InputField';
import SearchSelectField from 'terra-form-select/lib/SearchSelectField';
import SelectField from 'terra-form-select/lib/SelectField';
import Grid from 'terra-grid';
import Heading from 'terra-heading';
import Spacer from 'terra-spacer';
import { languages } from '../../mock-data/languages';
import { states } from '../../mock-data/states';
import { taxonomyCodes } from '../../mock-data/taxonomyCodes';
import CodeLookupField from '../CodeLookupField';

const { Column, Row } = Grid;

const ReferralForm = ({
  handleSubmit,
  onSpecialtyTypeChange,
  onCityOrStateChange,
  setPlaceOfServiceValue,
  filteredPlacesOfService,
  isProcedureCodeRequired,
  isPlaceOfServiceRequired,
  isZipCodeRequired,
}) => {
  const formRef = useForm();
  const resetForm = () => {
    formRef.restart();
  };
  return (
    <form noValidate onSubmit={handleSubmit}>
      <Heading level={2}>Referral Service Search</Heading>
      <Spacer margin="none none medium none">
        <Divider />
      </Spacer>
      <Heading level={3}>Specialty Request</Heading>
      <Row>
        <Column tiny={12}>
          <Field name="specialtyType">
            {({ input, meta }) => (
              <SearchSelectField
                value={input.value}
                onChange={(value) => {
                  onSpecialtyTypeChange(value);
                  input.onChange(value);
                }}
                label="Specialty Type"
                error={meta.error}
                isInvalid={meta.submitFailed && !meta.valid}
                placeholder="Select Item"
                selectId="specialtyType"
                required
                maxHeight={250}
              >
                {taxonomyCodes.map(({ codeClassification }) => (
                  <SearchSelectField.Option
                    key={codeClassification}
                    value={codeClassification}
                    display={codeClassification}
                  />
                ))}
              </SearchSelectField>
            )}
          </Field>
        </Column>
      </Row>
      <Spacer margin="medium none">
        <Heading level={3}>Service Information</Heading>
        <Row>
          <Column tiny={12}>
            <Field name="procedureCodes">
              {(field) => (
                <CodeLookupField
                  multiple={!isProcedureCodeRequired}
                  isRequired={isProcedureCodeRequired}
                  dropdownType="cpt"
                  label="Procedure Code"
                  labelExtra={
                    !isProcedureCodeRequired && '(Enter up to three codes)'
                  }
                  placeholder="Start typing and select an entry"
                  {...field}
                />
              )}
            </Field>
          </Column>
        </Row>
      </Spacer>
      <Row>
        <Column tiny={4}>
          <Field name="dateOfService">
            {({ input }) => (
              <DatePickerField
                value={input.value}
                onChange={(_, value) => input.onChange(value)}
                label="Date of Service"
                name="dateOfService"
                datePickerId="dateOfService"
              />
            )}
          </Field>
        </Column>
        <Column tiny={8}>
          <Field name="referredToPlaceOfService">
            {({ input, meta }) => (
              <SearchSelectField
                allowClear
                required={isPlaceOfServiceRequired}
                value={input.value}
                onChange={(value) => {
                  setPlaceOfServiceValue(value);
                  input.onChange(value);
                }}
                maxHeight={250}
                label="Place of Service"
                placeholder="Select Item"
                error={meta.error}
                isInvalid={meta.submitFailed && !meta.valid}
                selectId="referredToPlaceOfService"
              >
                {filteredPlacesOfService
                  .sort((a, b) => (a.location > b.location ? 1 : -1))
                  .map(({ code, location }) => (
                    <SearchSelectField.Option
                      key={code}
                      value={code}
                      display={location}
                    />
                  ))}
              </SearchSelectField>
            )}
          </Field>
        </Column>
      </Row>
      <Heading level={3}>Member</Heading>
      <Row>
        <Column tiny={12}>
          <Field name="language">
            {({ input, meta }) => (
              <SearchSelectField
                value={input.value}
                allowClear
                onChange={(value) => input.onChange(value)}
                label="Spoken Language"
                placeholder="Select Item"
                selectId="language"
                maxHeight={250}
                error={meta.error}
                isInvalid={meta.submitFailed && !meta.valid}
              >
                {languages.map(({ code, label }) => (
                  <SearchSelectField.Option
                    key={code}
                    value={code}
                    display={label}
                  />
                ))}
              </SearchSelectField>
            )}
          </Field>
        </Column>
      </Row>
      <Heading level={3}>Referred Provider</Heading>
      <Row>
        <Column tiny={4}>
          <Field name="lastName">
            {({ input, meta }) => (
              <InputField
                value={input.value}
                onChange={(event) => input.onChange(event.target.value)}
                inputId="lastName"
                label="Last Name"
                type="text"
                error={meta.error}
                isInvalid={meta.submitFailed && !meta.valid}
              />
            )}
          </Field>
        </Column>
        <Column tiny={4}>
          <Field name="firstName">
            {({ input, meta }) => (
              <InputField
                value={input.value}
                onChange={(event) => input.onChange(event.target.value)}
                inputId="firstName"
                label="First Name"
                type="text"
                error={meta.error}
                isInvalid={meta.submitFailed && !meta.valid}
              />
            )}
          </Field>
        </Column>
        <Column tiny={4}>
          <Field name="facilityName">
            {({ input, meta }) => (
              <InputField
                value={input.value}
                onChange={(event) => input.onChange(event.target.value)}
                inputId="facilityName"
                label="Facility Name"
                type="text"
                error={meta.error}
                isInvalid={meta.submitFailed && !meta.valid}
              />
            )}
          </Field>
        </Column>
      </Row>
      <Row>
        <Column tiny={4}>
          <Field name="npi">
            {({ input, meta }) => (
              <InputField
                value={input.value}
                onChange={(event) => input.onChange(event.target.value)}
                inputId="npi"
                label="NPI"
                placeholder="e.g. 123456789"
                type="text"
                error={meta.error}
                isInvalid={meta.submitFailed && !meta.valid}
              />
            )}
          </Field>
        </Column>
      </Row>
      <Row>
        <Column tiny={4}>
          <Field name="city">
            {({ input, meta }) => (
              <InputField
                value={input.value}
                onChange={(event) => {
                  onCityOrStateChange(event.target.value, 'city');
                  input.onChange(event.target.value);
                }}
                inputId="city"
                label="City"
                type="text"
                error={meta.error}
                isInvalid={meta.submitFailed && !meta.valid}
              />
            )}
          </Field>
        </Column>
        <Column tiny={4}>
          <Field name="state">
            {({ input, meta }) => (
              <SearchSelectField
                allowClear
                value={input.value}
                onChange={(value) => {
                  onCityOrStateChange(value, 'state');
                  input.onChange(value);
                }}
                maxHeight={250}
                label="State"
                placeholder="Select Item"
                selectId="state"
                error={meta.error}
                isInvalid={meta.submitFailed && !meta.valid}
              >
                {states.map(({ name, abbreviation }) => (
                  <SearchSelectField.Option
                    key={abbreviation}
                    value={abbreviation}
                    display={name}
                  />
                ))}
              </SearchSelectField>
            )}
          </Field>
        </Column>
        <Column tiny={4}>
          <Field name="postalCode">
            {({ input, meta }) => (
              <InputField
                value={input.value}
                onChange={(event) => input.onChange(event.target.value)}
                inputId="postalCode"
                label="ZIP Code"
                type="number"
                error={meta.error}
                isInvalid={meta.submitFailed && !meta.valid}
                required={isZipCodeRequired}
              />
            )}
          </Field>
        </Column>
      </Row>
      <Row>
        <Column tiny={4}>
          <Field name="radius">
            {({ input, meta }) => (
              <SelectField
                value={input.value}
                onChange={(value) => input.onChange(value)}
                label="Distance"
                selectId="distance"
                error={meta.error}
                isInvalid={meta.submitFailed && !meta.valid}
              >
                <SelectField.Option value="5" display="5 Miles" />
                <SelectField.Option value="10" display="10 Miles" />
                <SelectField.Option value="15" display="25 Miles" />
                <SelectField.Option value="25" display="50 Miles" />
                <SelectField.Option value="100" display="100 Miles" />
              </SelectField>
            )}
          </Field>
        </Column>
      </Row>
      <Row>
        <ActionFooter
          end={
            <>
              <Spacer isInlineBlock marginRight="medium">
                <Button
                  text="Cancel"
                  key="cancel"
                  variant="de-emphasis"
                  onClick={() => {
                    resetForm();
                  }}
                />
                <Button text="Submit" variant="emphasis" type="submit" />
              </Spacer>
            </>
          }
        />
      </Row>
    </form>
  );
};

export default ReferralForm;
