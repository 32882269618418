import React from 'react';
import Heading from 'terra-heading';
import Divider from 'terra-divider';
import Alert from 'terra-alert';
import Spacer from 'terra-spacer';
import List, { Item } from 'terra-list';
import ActionHeader from 'terra-action-header';
import Button from 'terra-button';
import PaCodeLookupResultCard from './PACodeLookupResultCard';
import useEligibility from './Eligibility/useEligibility';
import LoadingContainer from './LoadingContainer';
import Disclaimer from './Disclaimer';

const PaCodeLookupResult = ({
  procedureCodes,
  diagnosisCode,
  newSearch,
  lookupResultData,
  isLoading,
  isError,
}) => {
  const { data: eligibility } = useEligibility();
  const { state } = eligibility || {};

  return (
    <>
      <Heading level={2}>Prior Authorization Check</Heading>
      <Divider />
      <Alert type="info">Medical Services Only</Alert>
      <Spacer margin="medium none">
        <div>State: {state}</div>
        <div>Diagnosis Code: {diagnosisCode}</div>
      </Spacer>
      <Divider />
      <Spacer marginTop="medium">
        <ActionHeader>
          <Button text="New Search" variant="emphasis" onClick={newSearch} />
        </ActionHeader>
      </Spacer>

      <List>
        {procedureCodes?.map((codeDescription) => (
          <Item key={codeDescription}>
            <PaCodeLookupResultCard
              codeDescription={codeDescription}
              lookupResult={
                lookupResultData &&
                lookupResultData.find(
                  (element) =>
                    element.serviceCodes === codeDescription.split(' -')[0]
                )
              }
            />
          </Item>
        ))}
      </List>
      {/* only need the first disclaimer from paDeterminations because the disclaimer is the same for all. It may change in the future which is why it comes from the api */}
      <Disclaimer
        title="PRIOR AUTHORIZATION DISCLAIMERS"
        disclaimers={lookupResultData
          ?.slice(0, 1)
          .map((determination) => determination.disclaimerText)}
      />
      <LoadingContainer
        isData={!!lookupResultData}
        isLoading={isLoading}
        isError={isError}
        errorMessage="Error Loading PA Code Lookup"
      />
    </>
  );
};

export default PaCodeLookupResult;
