import React from 'react';
import { Field, useForm } from 'react-final-form';
import ActionFooter from 'terra-action-footer';
import Alert from 'terra-alert';
import Button from 'terra-button';
import Grid from 'terra-grid';
import Heading from 'terra-heading';
import IconInformation from 'terra-icon/lib/icon/IconInformation';
import Spacer from 'terra-spacer';
import CodeLookupField from './CodeLookupField';

const { Row, Column } = Grid;
const PACodeLookupForm = ({ handleSubmit }) => {
  const formRef = useForm();
  const resetForm = () => {
    formRef.restart();
  };
  return (
    <form noValidate onSubmit={handleSubmit}>
      <Heading level={2}>Prior Authorization Check</Heading>
      <Alert type="custom" customIcon={<IconInformation />}>
        Medical Services Only
      </Alert>
      <Spacer margin="medium none">
        <Row>
          <Column tiny={12}>
            <Spacer margin="medium none">
              <Field name="procedureCodes">
                {(field) => (
                  <CodeLookupField
                    dropdownType="cpt"
                    label="Procedure Code"
                    labelExtra="(Enter up to five codes)"
                    isRequired
                    multiple
                    {...field}
                  />
                )}
              </Field>
            </Spacer>
            <Spacer margin="medium none">
              <Field name="diagnosisCode">
                {(field) => (
                  <CodeLookupField
                    allowClear
                    dropdownType="icd"
                    label="Diagnosis Code"
                    {...field}
                  />
                )}
              </Field>
            </Spacer>
          </Column>
        </Row>
      </Spacer>
      <ActionFooter
        end={
          <Spacer isInlineBlock marginRight="medium">
            <Button
              text="Cancel"
              key="cancel"
              variant="de-emphasis"
              onClick={() => {
                resetForm();
              }}
            />
            <Button text="Submit" variant="emphasis" type="submit" />
          </Spacer>
        }
      />
    </form>
  );
};

export default PACodeLookupForm;
