import React, { useContext } from 'react';
import Heading from 'terra-heading';
import Grid from 'terra-grid';
import Card from 'terra-card';
import Arrange from 'terra-arrange';
import classNames from 'classnames/bind';
import Image from 'terra-image';
import Button from 'terra-button';
import { DisclosureManagerContext } from 'terra-disclosure-manager';
import ResultCardImage from '../ResultCardImage';
import labImage from '../../images/PreferredLab.png';
import freeStandingFacility from '../../images/FreestandingFacility.png';
import lowerCostImage from '../../images/low-cost.png';
import styles from './ReferralResultCard.module.scss';
import { renderHearts, renderTier } from './PremiumDesignationUtils';
import { Details, CopyButton } from '../ResultCard';
import useEligibility from '../Eligibility/useEligibility';

import {
  sendProviderSelected,
  sendFacilitySelected,
} from '../../services/outcomes';
import PremiumDesignationModal from '../Modal/PremiumDesignationModal';
import { GlobalStore } from '../../ContextWrapper';

const cx = classNames.bind(styles);

const { Column, Row } = Grid;

const sendOutcome = (patient, user, data, transactionId) => {
  const { result, procedureCodes, specialtyType } = data;
  const {
    type,
    location,
    provider,
    facility,
    premiumDesignation: {
      shortDescription: premiumDesignationText,
      fullDescription: premiumDesignationLong,
    },
    tier: { name: tier, type: tierType },
    ...rest
  } = result;

  let serviceCode = procedureCodes;
  if (Array.isArray(procedureCodes)) {
    serviceCode = procedureCodes.join(',');
  }

  const outcomeData = {
    ...location,
    ...provider,
    ...facility,
    ...rest,
    premiumDesignationText,
    premiumDesignationLong,
    tier,
    type,
    tierType,
    serviceCode: serviceCode || 'NOT SELECTED',
    specialtyType,
    address: location.address1,
    transactionId,
  };

  if (type === 'Facility') {
    sendFacilitySelected(patient, user, outcomeData);
  }
  if (type === 'Provider') {
    sendProviderSelected(patient, user, outcomeData);
  }
};

const ReferralResultCard = (props) => {
  const { copyDetails, result, renderCostColumn } = props;
  const { user, patient } = useContext(GlobalStore);
  const { data: eligibility } = useEligibility();
  const { transactionId } = eligibility || {};
  const disclosureManager = useContext(DisclosureManagerContext);
  const title = 'UnitedHealth Premium® Program';
  const openModal = (modalProps) => {
    disclosureManager.disclose({
      preferredType: 'modal',
      content: {
        key: title,
        title,
        component: <PremiumDesignationModal {...modalProps} />,
      },
    });
  };

  const {
    // desired,
    // type,
    // costMessage,
    // groupIndicator,
    // npi,
    // patientLanguageSpokenBy,
    tier,
    premiumDesignation,
    cost,
    specialties,
    location: { address1, city, state, postalCode, phoneNumber, distance },
    provider: { firstName, lastName, middleName, entityName },
    facility: {
      typeDescription,
      preferredLab,
      freeStandingFacilityIndicator,
      lowCostIndicator,
    },
  } = result;

  let providerName = [lastName, firstName].filter(Boolean).join(', ');

  if (middleName) {
    providerName += ` ${middleName[0]}.`;
  }
  const address2 = [city, state, postalCode].filter(Boolean).join(', ');
  const copyButtonText = [providerName, entityName, address1, address2]
    .filter(Boolean)
    .join('\n');

  return (
    <Card>
      <Card.Body>
        <Row>
          {renderCostColumn && (
            <Column tiny={3}>
              {cost && (
                <Arrange
                  className={cx('align-text', 'full-height')}
                  align="center"
                  fill={
                    <>
                      <Heading level={3} className={cx('overflow')}>
                        {cost}
                      </Heading>
                      {cost !== 'Unable to determine cost' && (
                        <span>Estimated Out of Pocket Cost</span>
                      )}
                    </>
                  }
                />
              )}
            </Column>
          )}
          <Column tiny={renderCostColumn ? 4 : 5}>
            {providerName && <div>{providerName}</div>}
            {entityName && <div>{entityName}</div>}
            {typeDescription && <div>{typeDescription}</div>}
            {specialties && specialties.primary && (
              <div>{specialties.primary}</div>
            )}
            <Details
              address1={address1}
              address2={address2}
              phoneNumber={phoneNumber}
              distance={distance}
            />
          </Column>
          <Column tiny={renderCostColumn ? 3 : 5}>
            <Arrange
              className={cx('full-height')}
              align="center"
              fill={
                <>
                  {tier?.name && (
                    <ResultCardImage
                      image={renderTier(tier.name)}
                      text={tier.name}
                    />
                  )}
                  {preferredLab && (
                    <ResultCardImage image={labImage} text="Preferred Lab" />
                  )}
                  {freeStandingFacilityIndicator === 'F' && (
                    <ResultCardImage
                      image={freeStandingFacility}
                      text="Free Standing Facility"
                    />
                  )}
                  {lowCostIndicator && (
                    <ResultCardImage image={lowerCostImage} text="Lower Cost" />
                  )}
                  {premiumDesignation?.shortDescription && (
                    <>
                      <Image
                        src={renderHearts(premiumDesignation.shortDescription)}
                        height="16"
                        alt="Premium"
                      />
                      <Button
                        title={premiumDesignation.fullDescription}
                        onClick={() => {
                          openModal({
                            providerName,
                            address1,
                            address2,
                            premiumDesignation,
                          });
                        }}
                        text={premiumDesignation.shortDescription}
                        variant="de-emphasis"
                      />
                    </>
                  )}
                </>
              }
            />
          </Column>
          <Column tiny={2}>
            <Arrange
              className={cx('full-height')}
              fill={
                <CopyButton
                  copyText={copyButtonText}
                  copyDetails={copyDetails}
                  onClick={() => {
                    // only send outcome once
                    if (copyDetails.copiedKey !== copyDetails.copyId) {
                      sendOutcome(patient, user, props, transactionId);
                    }
                  }}
                />
              }
            />
          </Column>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default ReferralResultCard;
