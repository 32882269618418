import React from 'react';
import classNames from 'classnames/bind';
import Card from 'terra-card';
import Image from 'terra-image';
import Spacer from 'terra-spacer';
import { renderHearts, renderDescription } from './PremiumDesignationUtils';

import styles from './PremiumDesignationCard.module.scss';

const cx = classNames.bind(styles);

const PremiumDesignationCard = ({ shortDescription, className }) => (
  <div className={className}>
    <Card>
      <Card.Body>
        <div className={cx('card-body')}>
          <Spacer padding="large">
            <strong>{shortDescription}</strong>
            <Spacer margin="medium none none none">
              <Image
                src={renderHearts(shortDescription)}
                height="18"
                alt={shortDescription}
              />
            </Spacer>
          </Spacer>
          {renderDescription(shortDescription)}
        </div>
      </Card.Body>
    </Card>
  </div>
);

export default PremiumDesignationCard;
