import React, { useState } from 'react';
import Toggle from 'terra-toggle';
import Grid from 'terra-grid';
import IconCaution from 'terra-icon/lib/icon/IconCaution';
import IconChevronUp from 'terra-icon/lib/icon/IconChevronUp';
import IconChevronDown from 'terra-icon/lib/icon/IconChevronDown';
import Button from 'terra-button';
import classNames from 'classnames/bind';
import styles from './GapInCare.module.scss';

const cx = classNames.bind(styles);

const { Column, Row } = Grid;

const GapInCare = ({
  message,
  providerNpi,
  notes,
  dateSubmitted,
  allExpanded,
  description,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const isExpanded = allExpanded || isOpen;
  const atLeast1Value = providerNpi || dateSubmitted || notes || description;

  return (
    <>
      <Row>
        <Column tiny={1}>
          <IconCaution className={cx('alert')} />
        </Column>
        <Column tiny={7}>
          <Row>
            <strong>{message}</strong>
          </Row>
        </Column>
        {!allExpanded && atLeast1Value && (
          <Column tiny={8} medium={4}>
            <Button
              icon={isExpanded ? <IconChevronUp /> : <IconChevronDown />}
              onClick={() => {
                setIsOpen(!isExpanded);
              }}
              variant="de-emphasis"
              text={`More Info`.toUpperCase()}
            />
          </Column>
        )}
      </Row>
      <Toggle id="animated-toggle" isOpen={isExpanded} isAnimated>
        {description && (
          <Row>
            <Column tiny={1} />
            <Column tiny={3}>
              <Row>
                <strong>Description:</strong>
              </Row>
            </Column>
            <Column tiny={8}>
              <span>{description}</span>
            </Column>
          </Row>
        )}
        {providerNpi && (
          <Row>
            <Column tiny={1} />
            <Column tiny={3}>
              <Row>
                <strong>Previously Submitting Provider NPI:</strong>
              </Row>
            </Column>
            <Column tiny={8}>
              <span>{providerNpi}</span>
            </Column>
          </Row>
        )}
        {dateSubmitted && (
          <Row>
            <Column tiny={1} />
            <Column tiny={3}>
              <Row>
                <strong>Date Submitted:</strong>
              </Row>
            </Column>
            <Column tiny={8}>
              <span>{dateSubmitted}</span>
            </Column>
          </Row>
        )}
        {notes && (
          <Row>
            <Column tiny={1} />
            <Column tiny={3}>
              <Row>
                <strong>Notes:</strong>
              </Row>
            </Column>
            <Column className={cx('wrap')} tiny={8}>
              <span>{notes}</span>
            </Column>
          </Row>
        )}
      </Toggle>
    </>
  );
};

export default GapInCare;
