import React, { useState } from 'react';
import IconChevronDown from 'terra-icon/lib/icon/IconChevronDown';
import IconChevronUp from 'terra-icon/lib/icon/IconChevronUp';
import Alert from 'terra-alert';
import Arrange from 'terra-arrange';
import Toggle from 'terra-toggle';
import Spacer from 'terra-spacer';
import Grid from 'terra-grid';
import classNames from 'classnames/bind';
import Image from 'terra-image';
import useEligibility from './useEligibility';
import UHGLogo from '../../images/UHGLogo.jpg';

import styles from './index.module.scss';

const cx = classNames.bind(styles);

const { Column, Row } = Grid;

const Eligibility = () => {
  const { data } = useEligibility();
  const { coverageDetails } = data;
  const [expandedPayorInformation, setExpandedPayorInformation] = useState(
    false
  );

  return (
    <>
      <Alert type="success" title=" ">
        <Arrange
          className={cx('blue')}
          fitStart={<span>Member is eligible</span>}
          fill={<span />}
          onClick={() => setExpandedPayorInformation(!expandedPayorInformation)}
          fitEnd={
            <>
              {expandedPayorInformation ? (
                <IconChevronUp />
              ) : (
                <IconChevronDown />
              )}
              <span className={cx('clickable')}>{` MORE INFO`}</span>
            </>
          }
        />
      </Alert>

      <Toggle id="animated-toggle" isOpen={expandedPayorInformation} isAnimated>
        <Spacer margin="medium none">
          <Row>
            <Column tiny={12}>
              <div>
                <strong>Payor Information</strong>
              </div>
              <Image src={UHGLogo} height="50" alt="UnitedHealthcare" />
              <div>Payor Name: UnitedHealthcare</div>
              <div>Plan Name: {coverageDetails.planName}</div>
              <div>Group Number: {coverageDetails.groupNumber}</div>
              <div>Coverage Start Date: {coverageDetails.coverageStart}</div>
              {coverageDetails.payorAddress?.length && (
                <div>
                  Medical Claims: {coverageDetails.payorAddress},
                  {coverageDetails.payorCity}, {coverageDetails.payorState}
                  {coverageDetails.payorZip}
                </div>
              )}
            </Column>
          </Row>
        </Spacer>
      </Toggle>
    </>
  );
};

export default Eligibility;
