import React from 'react';
import ApplicationLoadingOverlay, {
  ApplicationLoadingOverlayProvider,
} from 'terra-application/lib/application-loading-overlay';
import StatusView from 'terra-status-view';
import classNames from 'classnames/bind';

import styles from './LoadingContainer.module.scss';

const cx = classNames.bind(styles);

const RenderStatus = ({ isError, isLoading, isEmpty, errorMessage }) => {
  if (isLoading) {
    return null;
  }
  if (isError) {
    return <StatusView title={errorMessage} variant="error" />;
  }

  if (isEmpty) {
    return <StatusView title="No Results" variant="no-data" />;
  }
  return null;
};

const LoadingContainer = ({
  isData,
  isLoading,
  isError,
  isEmpty,
  errorMessage,
}) => {
  if (!isLoading && !isError && !isEmpty) return null;
  if (isData && isError) return null;

  return (
    <ApplicationLoadingOverlayProvider>
      <div className={cx('status-view')}>
        <RenderStatus
          isLoading={isLoading}
          isError={isError}
          isEmpty={isEmpty}
          errorMessage={errorMessage}
        />
        <ApplicationLoadingOverlay isOpen={isLoading} />
      </div>
    </ApplicationLoadingOverlayProvider>
  );
};

export default LoadingContainer;
