import React from 'react';
import Spacer from 'terra-spacer';
import Image from 'terra-image';

const ResultCardImage = ({ image, text }) => {
  if (text === 'Tier 2') return null;
  return (
    <div>
      <Image src={image} height="16" alt={text} />
      <Spacer isInlineBlock padding="small">
        {text}
      </Spacer>
    </div>
  );
};

export default ResultCardImage;
