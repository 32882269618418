import { useContext, useState } from 'react';
import useSWR from 'swr';
import { isEqual } from 'lodash';
import useEligibility from '../Eligibility/useEligibility';
import fetcher from '../../Fetcher';
import { GlobalStore } from '../../ContextWrapper';
import { LABS_RANK_ENDPOINT } from '../../routes';

const hardCodedData = {
  taxonomyCode: '291U00000X',
  radius: 50,
};

const useLabResults = () => {
  const [labData, setLabData] = useState(null);
  const { patient, user } = useContext(GlobalStore);
  const { data: eligibility } = useEligibility();
  const { transactionId, coverageDetails, postalCode } = eligibility || {};
  const { lineOfBusiness } = coverageDetails || {};
  hardCodedData.provider = { npi: user?.npi };

  const { data, error, mutate } = useSWR(
    labData
      ? () => [
          LABS_RANK_ENDPOINT,
          JSON.stringify(patient),
          JSON.stringify(user),
          JSON.stringify(labData),
        ]
      : null,
    (url) =>
      fetcher(
        url,
        {
          ...hardCodedData,
          patient,
          user,
          ...labData,
          transactionId,
          postalCode,
          lineOfBusiness,
        },
        { revalidateOnFocus: false, revalidateOnReconnect: false }
      )
  );

  const makeRequest = (requestData) => {
    if (isEqual(labData, requestData)) {
      mutate();
    } else {
      setLabData(requestData);
    }
  };

  return {
    makeRequest,
    data,
    isLoading: !error && !data && labData != null,
    isError: error,
  };
};

export default useLabResults;
