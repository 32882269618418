import React from 'react';
import { Field } from 'react-final-form';
import ActionFooter from 'terra-action-footer';
import Button from 'terra-button';
import InputField from 'terra-form-input/lib/InputField';
import Grid from 'terra-grid';

const { Column, Row } = Grid;

const SettingsForm = ({ handleSubmit, defaultValues }) => {
  return (
    <form noValidate onSubmit={handleSubmit}>
      <Row>
        <Column tiny={4}>
          <Field name="lastName">
            {({ input }) => (
              <InputField
                defaultValue={defaultValues.lastName}
                onChange={(event) => input.onChange(event.target.value)}
                inputId="lastName"
                label="Last Name"
                type="text"
              />
            )}
          </Field>
        </Column>
        <Column tiny={4}>
          <Field name="firstName">
            {({ input }) => (
              <InputField
                defaultValue={defaultValues.firstName}
                onChange={(event) => input.onChange(event.target.value)}
                inputId="firstName"
                label="First Name"
                type="text"
              />
            )}
          </Field>
        </Column>
        <Column tiny={4}>
          <Field name="dob">
            {({ input }) => (
              <InputField
                defaultValue={defaultValues.dob}
                onChange={(event) => input.onChange(event.target.value)}
                inputId="dob"
                label="Date of Birth"
                type="text"
              />
            )}
          </Field>
          <Field name="npi">
            {({ input }) => (
              <InputField
                defaultValue={defaultValues.npi}
                onChange={(event) => input.onChange(event.target.value)}
                inputId="npi"
                label="NPI"
                type="text"
              />
            )}
          </Field>
        </Column>
      </Row>
      <ActionFooter
        end={
          <>
            <Button text="Submit" variant="emphasis" type="submit" />
          </>
        }
      />
    </form>
  );
};

export default SettingsForm;
