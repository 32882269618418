import { useContext } from 'react';
import useSWR from 'swr';
import { getTenantInfo } from '../services/cookieReader';
import fetcher from '../Fetcher';
import { GlobalStore } from '../ContextWrapper';

const useUHCWidget = (launchId, widgetId) => {
  const { patient, user, client } = useContext(GlobalStore);
  const { locationId } = getTenantInfo();
  const launchUrl = `${process.env.REACT_APP_PA_WIDGET_URL}/launch?launch=${launchId}&iss=${process.env.REACT_APP_API_URL}&version=5.0&poca_widget=${widgetId}`;

  const values = {
    launchId,
    patientId: client.patient.id,
    encounterId: client.encounter.id,
    providerId: client.user.id,
    locationId,
    widgetId,
  };

  const { data, error } = useSWR(
    () => [
      '/OAuth2/RegisterEncounter',
      JSON.stringify(patient),
      JSON.stringify(user),
      client.encounter.id,
      client.user.id,
      client.patient.id,
      launchId,
      locationId,
      widgetId,
    ],
    (url) => fetcher(url, { patient, user, ...values })
  );

  return {
    launchUrl,
    data,
    isLoading: !error && !data,
    isError: error,
  };
};

export default useUHCWidget;
