import React, { useState } from 'react';
import Heading from 'terra-heading';
import Divider from 'terra-divider';
import { Form } from 'react-final-form';

import LabsForm from '../components/Labs/LabsForm';
import LabsResult from '../components/Labs/LabsResult';
import useLabResults from '../components/Labs/useLabResults';

const Labs = () => {
  const [submitted, setSubmitted] = useState(false);
  const [submittedValues, setSubmittedValues] = useState(null);
  const {
    data: labResultData,
    makeRequest,
    isLoading: isLabsLoading,
    isError: isLabsError,
  } = useLabResults();

  const submitForm = (values) => {
    const serviceCode =
      values.procedureCode && values.procedureCode.split(' -')[0];
    const diagnosisCode =
      values.diagnosisCode && values.diagnosisCode.split(' -')[0];
    setSubmittedValues(values);
    setSubmitted(true);
    const modifiedValues = { ...values, serviceCode, diagnosisCode };
    delete modifiedValues.procedureCode;
    makeRequest(modifiedValues);
  };
  const renderForm = ({ handleSubmit }) => (
    <LabsForm handleSubmit={handleSubmit} />
  );

  return (
    <>
      {submitted ? (
        <LabsResult
          isLoading={isLabsLoading}
          isError={isLabsError && !labResultData}
          {...submittedValues}
          labResultData={labResultData}
          newSearch={() => {
            setSubmitted(false);
            setSubmittedValues(null);
          }}
        />
      ) : (
        <>
          <Heading level={2}>Lab Order</Heading>
          <Divider />
          <strong>Test Name / CPT Code Search</strong>
          <Form
            initialValues={submittedValues}
            onSubmit={submitForm}
            render={renderForm}
            validate={(values) => {
              const errors = {};
              if (!values.procedureCode) {
                errors.procedureCode = 'You must enter a procedure code.';
              }
              return errors;
            }}
          />
        </>
      )}
    </>
  );
};

export default Labs;
