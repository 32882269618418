import { v4 as uuidv4 } from 'uuid';
import { getTenantInfo } from './services/cookieReader';
import { POCA_AUTH_OBJECT_IDENTIFIER } from './useCerner';

const root = process.env.REACT_APP_API_URL;

const fetcher = async (url, data) => {
  const { user, patient, ...rest } = data;
  const {
    healthSystemName,
    healthSystemAddress,
    healthSystemZip,
    locationId,
    tenantId,
  } = getTenantInfo();
  const { patientId, ...patientWithoutId } = patient;
  const fullData = {
    metadata: {
      testMessage: false,
      requestId: uuidv4(), // generate random guid
    },
    payor: 'UNITED', // leave these
    providerOrFacilityName: !user?.lastName ? 'not-available' : user?.lastName,
    npi: user?.npi,
    userId: user?.id,
    encounterId: user?.encounterId,
    providerFirstName: user?.firstName,
    locationId,
    clientLocation: locationId,
    healthSystemName,
    healthSystemAddress,
    healthSystemZip,
    tenantId,
    patient: patientWithoutId,
    patientId,
    ...rest,
  };

  const { access_token: accessToken } =
    JSON.parse(localStorage.getItem(POCA_AUTH_OBJECT_IDENTIFIER)) || {};

  const res = await fetch(`${root}${url}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(fullData),
  });

  if (!res.ok) {
    const error = new Error('An error occurred while fetching the data.');
    // Attach extra info to the error object.
    error.info = await res.json();
    error.status = res.status;
    throw error;
  }

  try {
    const text = await res.text();
    return JSON.parse(text);
  } catch (err) {
    return err;
  }
};

export default fetcher;
