import React, { useState } from 'react';

import { Form } from 'react-final-form';
import PACodeLookupForm from '../components/PACodeLookupForm';
import PACodeLookupResult from '../components/PACodeLookupResult';

import usePaLookup from './usePaLookup';

const PaCodelookup = () => {
  const [submitted, setSubmitted] = useState(false);
  const {
    makeRequest,
    data: lookupResultData,
    isLoading,
    isError,
  } = usePaLookup();

  const [submittedValues, setSubmittedValues] = useState(null);
  const submitForm = (values) => {
    setSubmittedValues(values);
    setSubmitted(true);
    const serviceCodes = values.procedureCodes.map(
      (codeString) => codeString.split(' -')[0]
    );

    const diagnosisCode =
      values.diagnosisCode && values.diagnosisCode.split(' -')[0];
    const modifiedValues = { ...values, serviceCodes, diagnosisCode };
    delete modifiedValues.procedureCodes;
    makeRequest(modifiedValues);
  };
  const renderForm = ({ handleSubmit }) => (
    <PACodeLookupForm handleSubmit={handleSubmit} />
  );

  return (
    <>
      {submitted ? (
        <PACodeLookupResult
          {...submittedValues}
          isLoading={isLoading}
          isError={isError}
          lookupResultData={lookupResultData}
          newSearch={() => {
            setSubmitted(false);
            setSubmittedValues(null);
          }}
        />
      ) : (
        <Form
          initialValues={submittedValues}
          onSubmit={submitForm}
          render={renderForm}
          validate={(values) => {
            const { procedureCodes } = values;

            const isEmptyArray =
              Array.isArray(procedureCodes) && !procedureCodes.length;
            const errors = {};
            if (!procedureCodes || isEmptyArray) {
              errors.procedureCodes =
                'You must enter at least one procedure code.';
            }
            if (procedureCodes && procedureCodes.length > 5) {
              errors.procedureCodes =
                'You have exceeded the maximum of 5 procedure codes.';
            }
            return errors;
          }}
        />
      )}
    </>
  );
};

export default PaCodelookup;
