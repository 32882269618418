import React from 'react';
import Grid from 'terra-grid';
import Spacer from 'terra-spacer';
import Alert from 'terra-alert';
import Card from 'terra-card';
import Text from 'terra-text';

import IconSuccess from 'terra-icon/lib/icon/IconSuccess';
import IconWarning from 'terra-icon/lib/icon/IconWarning';
import IconAlert from 'terra-icon/lib/icon/IconAlert';

const { Column, Row } = Grid;

const PRIOR_AUTH_IS_REQUIRED = 'Prior Auth is Required';
const PRIOR_AUTH_IS_NOT_REQUIRED = 'Prior Auth is Not Required';
const PRIOR_AUTH_REQUIRED_CONDITIONALLY =
  'Prior Auth may be Required - conditionally';
const BLOCKED = 'Blocked';
const BLOCKED_CONDITIONALLY = 'May be Blocked - conditionally';

const iconPicker = {
  [PRIOR_AUTH_IS_REQUIRED]: <IconAlert />,
  [BLOCKED]: <IconAlert />,
  [PRIOR_AUTH_IS_NOT_REQUIRED]: <IconSuccess />,
  [PRIOR_AUTH_REQUIRED_CONDITIONALLY]: <IconWarning />,
  [BLOCKED_CONDITIONALLY]: <IconWarning />,
};

const PriorAuthReq = () => (
  <Alert type="error" title=" ">
    {PRIOR_AUTH_IS_REQUIRED}
  </Alert>
);

const NoPriorAuthReq = () => (
  <Alert type="success" title=" ">
    {PRIOR_AUTH_IS_NOT_REQUIRED}
  </Alert>
);

const ConditionalAuthReq = ({ paDeterminations }) => {
  return (
    <>
      <Alert type="info" title={paDeterminations[0].paIndication}>
        <br />
        <div>{paDeterminations[0].paExplanation}</div>
      </Alert>
      {paDeterminations.map((data) => {
        const { paIndication, conditions, locationType } = data;
        return (
          <Spacer key={locationType} margin="medium none">
            <Spacer marginRight="small" isInlineBlock>
              {iconPicker[paIndication]}
            </Spacer>
            <span>
              <Text weight={400}>{locationType}</Text> - {paIndication}
            </span>
            {conditions.map((condition) => (
              <div key={condition.name}>
                {condition.name} - {condition.detail}
              </div>
            ))}
          </Spacer>
        );
      })}
    </>
  );
};

const content = (type, paDeterminations) => {
  switch (type) {
    case PRIOR_AUTH_IS_REQUIRED:
      return <PriorAuthReq />;
    case PRIOR_AUTH_IS_NOT_REQUIRED:
      return <NoPriorAuthReq />;
    default:
      return <ConditionalAuthReq paDeterminations={paDeterminations} />;
  }
};
const PACodeLookupResultCard = ({ lookupResult, codeDescription }) => {
  const { issues, paDeterminations } = lookupResult || {};
  let type = null;
  if (paDeterminations && paDeterminations.length === 1) {
    type = paDeterminations?.[0]?.paIndication;
  }

  return (
    <Spacer margin="medium none">
      <Card>
        <Card.Body>
          <Row>
            <Column>
              <Spacer marginBottom="small">
                <strong>{codeDescription}</strong>
              </Spacer>
              <Spacer margin="none none medium none">
                {issues}
                {paDeterminations &&
                  paDeterminations.length > 0 &&
                  content(type, paDeterminations)}
              </Spacer>
            </Column>
          </Row>
        </Card.Body>
      </Card>
    </Spacer>
  );
};

export default PACodeLookupResultCard;
