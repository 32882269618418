import React, { useState, useContext, useEffect } from 'react';
import Heading from 'terra-heading';
import Divider from 'terra-divider';
import { Form } from 'react-final-form';
import Spacer from 'terra-spacer';
import FHIR from 'fhirclient';
import SettingsForm from '../components/SettingsForm';
import { GlobalStore } from '../ContextWrapper';

const Settings = () => {
  const { patient, setPatient, user, setUser } = useContext(GlobalStore);
  const [submitted, setSubmitted] = useState(false);
  const [participant, setParticipant] = useState(null);
  useEffect(() => {
    (async () => {
      const client = await FHIR.oauth2.ready();
      const encounter = await client.encounter.read();
      setParticipant(encounter.participant);
    })();
  }, []);

  const submitForm = (values) => {
    const { npi, ...rest } = values;
    setPatient(rest);
    setUser((userData) => ({ ...userData, npi }));
    setSubmitted(true);
  };
  const renderForm = ({ handleSubmit }) => (
    <SettingsForm
      key={patient?.firstName}
      defaultValues={{ ...patient, npi: user?.npi }}
      handleSubmit={handleSubmit}
    />
  );
  return (
    <>
      <Heading level={2}>Settings</Heading>
      <Divider />
      <Spacer margin="medium none">
        <Form
          initialValues={{ ...patient, npi: user?.npi }}
          onSubmit={submitForm}
          render={renderForm}
        />
      </Spacer>
      {submitted && (
        <>
          <Heading level={3}>Changes Saved.</Heading>
          <pre>{JSON.stringify(patient, null, 2)}</pre>
          <pre>{JSON.stringify(user, null, 2)}</pre>
          {participant && <pre>{JSON.stringify(participant, null, 2)}</pre>}
        </>
      )}
    </>
  );
};

export default Settings;
