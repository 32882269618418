import React, { useState } from 'react';
import Heading from 'terra-heading';
import Divider from 'terra-divider';
import Button from 'terra-button';
import Spacer from 'terra-spacer';
import ActionHeader from 'terra-action-header';
import List, { Item } from 'terra-list';
import LoadingContainer from '../LoadingContainer';
import Eligibility from '../Eligibility';
import Disclaimer from '../Disclaimer';

import LabResultCard from './LabsResultCard';
import ScrollableContainer from '../ScrollableContainer';

const LabResult = ({
  procedureCode,
  diagnosisCode,
  newSearch,
  labResultData,
  isLoading,
  isError,
}) => {
  const [copiedKey, setCopiedKey] = useState(null);

  return (
    <>
      <Eligibility />

      {(labResultData?.labs?.length && (
        <>
          <Heading
            level={2}
          >{`Labs ${labResultData.labs.length} of ${labResultData.labs.length} Results`}</Heading>
        </>
      )) ||
        ''}

      <Spacer margin="large none">
        <Divider />
      </Spacer>

      <ActionHeader
        title={`Select Lab for ${procedureCode} ${diagnosisCode || ''}`}
      >
        <Button text="New Search" variant="emphasis" onClick={newSearch} />
      </ActionHeader>

      <LoadingContainer
        isData={!!labResultData}
        isEmpty={labResultData?.labs?.length === 0}
        isLoading={isLoading}
        isError={isError}
        errorMessage="Error Loading Lab Results"
      />
      {(labResultData?.labs?.length && (
        <>
          <ScrollableContainer>
            <List paddingStyle="compact">
              {labResultData.labs.map((result) => (
                <Item key={result?.lab?.facilityId}>
                  <LabResultCard
                    copyDetails={{
                      copyId: result?.lab?.facilityId,
                      copiedKey,
                      setCopiedKey,
                    }}
                    procedureCode={procedureCode}
                    diagnosisCode={diagnosisCode}
                    key={result?.lab?.facilityId}
                    result={result}
                  />
                </Item>
              ))}
            </List>
          </ScrollableContainer>
        </>
      )) ||
        ''}
      <Disclaimer
        title="REFERRAL SERVICE DISCLAIMERS"
        disclaimers={labResultData?.disclaimers?.map(
          (disclaimer) => disclaimer.disclaimerMessage
        )}
      />
    </>
  );
};

export default LabResult;
