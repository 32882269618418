import React from 'react';
import Heading from 'terra-heading';
import Eligibility from '../components/Eligibility';
import GapsInCare from '../components/GapsInCare';

const GapsInCarePage = () => {
  return (
    <>
      <Heading level={2}>Gaps in Care check</Heading>
      <Eligibility />
      <GapsInCare showDetailsTitle />
    </>
  );
};

export default GapsInCarePage;
