import React from 'react';
import { Field, useForm } from 'react-final-form';
import ActionFooter from 'terra-action-footer';
import Button from 'terra-button';
import Spacer from 'terra-spacer';
import CodeLookupField from '../CodeLookupField';

const LabsForm = ({ handleSubmit }) => {
  const formRef = useForm();
  const resetForm = () => {
    formRef.restart();
  };
  return (
    <form noValidate onSubmit={handleSubmit}>
      <Spacer margin="medium none">
        <Field name="procedureCode">
          {(field) => (
            <CodeLookupField
              dropdownType="cpt"
              isRequired
              label="Test Name / CPT Code"
              {...field}
            />
          )}
        </Field>
      </Spacer>
      <Spacer margin="medium none">
        <Field name="diagnosisCode">
          {(field) => (
            <CodeLookupField
              allowClear
              dropdownType="icd"
              label="Diagnosis Code"
              {...field}
            />
          )}
        </Field>
      </Spacer>
      <ActionFooter
        end={
          <>
            <Spacer isInlineBlock marginRight="medium">
              <Button
                text="Cancel"
                key="cancel"
                variant="de-emphasis"
                onClick={() => {
                  resetForm();
                }}
              />
              <Button text="Submit" variant="emphasis" type="submit" />
            </Spacer>
          </>
        }
      />
    </form>
  );
};

export default LabsForm;
