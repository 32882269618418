import React, { useState } from 'react';

const startingData = {
  firstName: 'Russell',
  lastName: 'Trusler',
  dob: '1938-09-14',
};
export const GlobalStore = React.createContext(startingData);

function ContextWrapper({ children }) {
  const [patient, setPatient] = useState(startingData);
  const [client, setClient] = useState(null);
  const [user, setUser] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(null);

  return (
    <GlobalStore.Provider
      value={{
        patient,
        setPatient,
        client,
        setClient,
        user,
        setUser,
        isAuthenticated,
        setIsAuthenticated,
      }}
    >
      {children}
    </GlobalStore.Provider>
  );
}

export default ContextWrapper;
